import React from 'react';
import useWindowDimensions from "../../../hoc/hooks/useWindowDimensions";

const lessonBookmark = (props) => {
  const { height, width } = useWindowDimensions();
  const lessonFavoriteHandler = (e) => {
    e.preventDefault();
    props.favorite(e.target.value);
  };
  const labelText = (width > 768) ? 'Sačuvaj lekciju' : 'omiljena';
  return (
    <div className="card-body__course__checkbox checkbox-wrapper checkbox-wrapper--big checkbox-wrapper--favorite">
      <input
        id="courseOptionsFavorite"
        name="courseOptionsFavorite"
        type="checkbox"
        value={props.lessonSlug}
        checked={props.checked}
        onChange={(event) => lessonFavoriteHandler(event)}
        disabled={props.checked} />
      <label htmlFor="courseOptionsFavorite">{labelText}</label>
    </div>
  );
};

export default lessonBookmark;