import React, { Fragment } from 'react';
import {Carousel} from "react-bootstrap";
import Spinner from "../UI/Spinner/Spinner";

const homepageTestimonialsWidget = (props) => {
  const prevIcon = (
    <Fragment>
      <span className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
            aria-hidden="true">
        <i className="icon icon-arrow-left"></i>
      </span>
      <span className="sr-only">Previous</span>
    </Fragment>
  );
  const nextIcon = (
    <Fragment>
      <span className="carousel-control-next-icon d-flex align-items-center justify-content-center"
            aria-hidden="true">
        <i className="icon icon-arrow-right"></i>
      </span>
      <span className="sr-only">Next</span>
    </Fragment>
  );

  let testimonials = <Spinner />;
  if (!props.loading) {
    const propsTestimonials = Object.keys(props.testimonials);
    testimonials = propsTestimonials.map(key => {
      return (
        <Carousel.Item className="testimonial" key={key}>
          <div className="testimonial__box d-flex flex-column align-items-center justify-content-center">
            <img className="testimonial__img" src={props.testimonials[key].image} alt=""/>
            <h2 className="testimonial__name">{props.testimonials[key].name}</h2>
            <h4 className="testimonial__title">{props.testimonials[key].title}</h4>
            <div className="testimonial__text">{props.testimonials[key].content}</div>
          </div>
        </Carousel.Item>
      )
    });
    if (testimonials.length === 0) {
      testimonials = null;
    }
  }


  return  (testimonials) ? (
    <section className="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <Carousel prevIcon={prevIcon} nextIcon={nextIcon} indicators={false} id="carouselTestimonials">
              {testimonials}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default homepageTestimonialsWidget;