import React, { Fragment } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import UserDropdown from "../UserDropdown/UserDropdown";

const navbarNav = (props) => {
  const location = useLocation();
  const onSelectHandler = () => {
    props.navSelect();
    props.getPrevPath(location.pathname);
  }

  return (
    <Navbar.Collapse id="navbarMainMenu">
      <Nav className="mr-auto">

        { props.isAuthenticated ?
          <div className="nav-item nav-item--border navbar-button d-lg-none">
            <NavLink to="/logout" exact className="nav-link btn-link" onClick={onSelectHandler}>Odjavi se</NavLink>
          </div> :
          <Fragment>
            <div className="nav-item navbar-button d-lg-none">
              <NavLink to="/login" exact className="nav-link btn-link" onClick={onSelectHandler}>Uloguj se</NavLink>
            </div>
            <div className="nav-item nav-item--border navbar-button d-lg-none">
              <NavLink to="/register" className="nav-link btn-link" onClick={onSelectHandler}>Registruj se</NavLink>
            </div>
          </Fragment>
        }

        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/o-nama" onClick={onSelectHandler}>O nama</NavLink>
        </div>
        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/kursevi" onClick={onSelectHandler}>Kursevi</NavLink>
        </div>
        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/paketi-kurseva" onClick={onSelectHandler}>Paketi</NavLink>
        </div>
        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/predavaci" onClick={onSelectHandler}>Predavači</NavLink>
        </div>
        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/kurs-na-zahtev" onClick={onSelectHandler}>Kurs na zahtev</NavLink>
        </div>
        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/novosti" onClick={onSelectHandler}>Novosti</NavLink>
        </div>
{/*        <div className="nav-item navbar-text">
          <NavLink className="nav-link" to="/svi-podkasti" onClick={onSelectHandler}>Podkast</NavLink>
        </div>*/}
        <div className="nav-item navbar-text nav-item--border">
          <NavLink className="nav-link" to="/kontakt" onClick={onSelectHandler}>Kontakt</NavLink>
        </div>
        <div className="nav-item navbar-text nav-item--border">
          <a className="nav-link" href="https://b2b.homepageacademy.rs" target="_blank">B2B ponuda</a>
        </div>
      </Nav>

      <Nav className="ml-md-auto">
        { props.isAuthenticated ?
          <UserDropdown user={props.user}/>  :
          <Fragment>
            <div className="nav-item navbar-button d-none d-lg-block d-xl-block">
              <NavLink className="nav-btn btn btn-link btn-sm" to="/login" onClick={onSelectHandler}>Uloguj se</NavLink>
            </div>
            <div className="nav-item navbar-button d-none d-lg-block d-xl-block">
              <NavLink className="nav-btn btn btn-outline-dark btn-sm" to="/register" onClick={onSelectHandler}>Registruj se</NavLink>
            </div>
          </Fragment>
        }
      </Nav>
    </Navbar.Collapse>
  );
}

export default navbarNav;