import React, {Fragment} from 'react';
import TestCertFail from "../../../../src/assets/images/test-calendar.svg";
import TestCertSuccess from "../../../../src/assets/images/test-certificate.svg";

const testResultsCert = (props) => {
  const testCertImg = props.passed ? TestCertSuccess : TestCertFail;
  return (
    <div className={"test-results__cert d-flex flex-column align-items-center"}>
      <img className="test-results__cert__img" src={testCertImg} alt={props.title} />
      {props.passed ? <a href={props.cert} className={"test-results__cert__link"} target="_blank"><i className="icon icon-download"></i></a> : null}
    </div>
  );
}

export default testResultsCert;