import React from 'react';
import FooterLogo from "../../../../assets/images/favicon-homepage-academy.svg";

const footerLogo = () => (
  <div className="col-md-4 footer__copyright d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start order-md-1">
    <img className="copyright_logo" src={FooterLogo} alt="Homepage Academy"/>
    <span>Copyright &copy; 2022 <a href="/">Homepage Academy</a></span>
  </div>
);

export default footerLogo;