import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

import Header from "../../components/layout/Header/Header";
import HeaderDark from "../../components/layout/Header/HeaderDark";
import HeaderTest from "../../components/layout/Header/HeaderTest";
import Footer from "../../components/layout/Footer/Footer";
import FooterBank from "../../components/layout/Footer/FooterBank";
import GDPR from "../../components/layout/GDPR/GDPR";
import SiteInfoBanner from "../../components/layout/SiteInfoBanner/SiteInfoBanner";
import * as actions from "../../store/actions";
import {Helmet} from "react-helmet";

import FbShareImage from "../../assets/images/hompageacademy-fb-share-image.png";
import PartnersSlider from "../../components/layout/PartnersSlider";

class Layout extends Component {

  siteInfoBannerCloseHandler = () => {
    this.props.onSiteInfoClose();
  }

  setPreviousPath = (prevPath) => {
    this.props.onSetAuthRedirectPath(prevPath);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    let platformHeader = <Header
                          isAuth={this.props.isAuthenticated}
                          classes={"header"}
                          user={this.props.user}
                          setPrevPath={this.setPreviousPath}
                        />;
    
    if (this.props.location.pathname.indexOf('/test-intro') > -1) {
      platformHeader = null;
    } else if (this.props.location.pathname.indexOf('/test-result') > -1) {
      platformHeader = null;
    } else if (this.props.location.pathname.indexOf('/test') > -1) {
      platformHeader = null;
      if (!this.props.testLoading && this.props.test.testAttempt && this.props.test.duration) {
        platformHeader = (isNaN(parseInt(this.props.test.testAttempt.scorePercent))) ? <HeaderTest
                            duration={this.props.test.duration}
                            startTime={this.props.test.testAttempt.startTime}
                          /> : null;
      }
    } else if (!this.props.lightHeader) {
      platformHeader = <HeaderDark
        classes={"header-dark"}
        user={this.props.user}
        singleCourse={this.props.singleCourse}
        courseAccess={this.props.courseAccess}
      />;
    }
    
    const isHomepage = this.props.location.pathname === '/';
    
    return (
      <Fragment>
        <Helmet>
          <title>Homepage Academy</title>
          <meta name="description" content="Postani deo zajednice koja deli znanje!" />
          <meta property="og:title" content="Postani deo zajednice koja deli znanje!" />
          <meta property="og:description" content="Registruj se!" />
          <meta property="og:image" content={FbShareImage} />
          <meta name="twitter:title" content="Registruj se!"/>
          <meta name="twitter:description" content="Postani deo zajednice koja deli znanje!" />
          <meta name="twitter:image" content={FbShareImage} />
          <meta name="twitter:image:alt" content="Registruj se!" />
          <meta name="twitter:card" content="summary"/>
        </Helmet>
        { platformHeader }
        <main className={"content"}>
          {this.props.children}
        </main>
        {!isHomepage && <PartnersSlider />}
        <Footer/>
        <FooterBank/>
        <GDPR/>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null,
    user: {
      name: state.auth.user.name,
      email: state.auth.user.email
    },
    singleCourse: state.course.singleCourse,
    courseAccess: state.course.courseAccess,
    lightHeader: (state.course.lesson.length === 0),
    siteInfo: state.siteInfo.siteInfo,
    test: state.test.test,
    testLoading: state.test.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSiteInfoClose: () => dispatch(actions.siteInfoClose()),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));