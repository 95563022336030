/**********************/
/* Reset Password ACTIONS */
/**********************/
import * as actionTypes from "../actionTypes";
import axios from "../../../axios";

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START
  };
};
export const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  }
};
export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error
  }
};
export const resetPasswordUnmount = () => {
  return {
    type: actionTypes.RESET_PASSWORD_UNMOUNT
  }
};

export const resetPassword = (email) => {
  return dispatch => {
    dispatch(resetPasswordStart());
    const data = {
      email: email
    };
    axios.post('/users/reset-password-request', data)
      .then(response => {
        //console.log('resetPassword AXIOS response ', response.data);
        dispatch(resetPasswordSuccess());
      })
      .catch(err => {
        //console.log('resetPassword AXIOS catch err ', err.response);
        dispatch(resetPasswordFail(err.response));
      });
  }
};