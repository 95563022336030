import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import { Link, Redirect } from "react-router-dom";

import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";

import * as actions from "../../store/actions";
import {formatStateForForm, inputChangedFunction, checkAxiosErrorFields, updateObject} from "../../shared/utility";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import ReCaptcha from "../../hoc/Layouts/ReCaptchaHoc";

const tagManagerArgs = {
  dataLayer: {
    page: 'Registracija'
  },
  dataLayerName: 'PageDataLayer'
};

class Registration extends Component {

  state = {
    form: {
      name: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Ime i prezime *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true,
          minLength: 3
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      email: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email *',
          autoComplete: 'username'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      phone: {
        order: 2,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Broj telefona *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      country: {
        order: 3,
        elementType: 'select',
        elementClasses: 'form-control',
        elementConfig: {
          options: [
            {value: '1', displayValue: 'Serbia'},
            {value: '2', displayValue: 'Other'}
          ]
        },
        value: '1',
        validation: {},
        valid: true,
        errorMessage: null,
        touched: false
      },
      city: {
        order: 4,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Grad *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      postCode: {
        order: 5,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Poštanski broj *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true,
          minLength: 5,
          maxLength: 5
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      address: {
        order: 6,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Adresa *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      organization: {
        order: 7,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Organizacija'
        },
        value: '',
        validation: {
          required: false
        },
        valid: true,
        errorMessage: null,
        touched: false
      },
      plainPassword: {
        order: 8,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'password',
          placeholder: 'Lozinka *',
          autoComplete: 'on'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false,
    newsletterSubscribe: true
  }

  registrationHandler = (event) => {
    event.preventDefault();

    const registrationData = {};
    for (let formElementIdentified in this.state.form) {
      registrationData[formElementIdentified] = this.state.form[formElementIdentified].value;
    }
    
    // Subscribe on Mailchimp
    if (this.state.newsletterSubscribe) {
      this.props.onUserRegistrationMailchimpSubscribe(
        this.state.form.email.value
      );
    }
    this.props.onUserRegistration(registrationData);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  handleNewsletterSubscribeCheckbox = () => {
    const newsletterSubscribeUpdate = !this.state.newsletterSubscribe;
    this.setState(updateObject(this.state, {newsletterSubscribe: newsletterSubscribeUpdate}));
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('register');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error !== null) {
      const errorFields = this.props.error.data.error.data.fields;
      //console.log('componentDidUpdate REGISTRATION Props', errorFields);

      this.setState(checkAxiosErrorFields(errorFields, this.state));
    }
  }

  componentWillUnmount() {
    this.props.onUserRegistrationUnmount();
  }

  render() {
    let registrationSuccess = null;
    if (this.props.userStatus === 0) {
      registrationSuccess = <Redirect to="/registration-success"/>;
    }

    const formElementsArray = formatStateForForm(this.state.form);
    
    let form = (
      <Fragment>
        <h2 className="user-form__title">Dobro došli!</h2>
        <form onSubmit={this.registrationHandler}>
          <div className="row">
            {
              formElementsArray.map((el) => {
                return (
                  <div className="col-md-6" key={el.id}>
                    <Input
                      class={el.config.elementClasses}
                      elementType={el.config.elementType}
                      elementConfig={el.config.elementConfig}
                      value={el.config.value}
                      invalid={!el.config.valid}
                      shouldValidate={el.config.validation}
                      touched={el.config.touched}
                      errorMessage={el.config.errorMessage}
                      changed={(event) => this.inputChangedHandler(event, el.id)}/>
                  </div>
                )
              })
            }
            <div className="col-12">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="registrationNewsletter"
                  defaultChecked={this.state.newsletterSubscribe}
                  onChange={this.handleNewsletterSubscribeCheckbox}
                />
                <label className="custom-control-label user-form__newsletter-label" htmlFor="registrationNewsletter">prijava za newsletter</label>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <Button
                disabled={!this.state.formIsValid}
                btnType="btn-primary user-form__btn-submit"
                clicked={this.registrationHandler}>Registruj se</Button>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center flex-column">
              <Link className="user-form__link--reset" to="/login">Nazad na login</Link>
            </div>
          </div>
        </form>
      </Fragment>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    return (
      <ReCaptcha>
        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex align-items-center justify-content-center user-form">
                <div className="user-form__box">
                  { registrationSuccess }
                  { form }
                </div>
              </div>
            </div>
          </div>
        </section>
      </ReCaptcha>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    userStatus: state.auth.user.status
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserRegistration: (registrationData) => dispatch(actions.userRegistration(registrationData)),
    onUserRegistrationUnmount: () => dispatch(actions.userRegistrationUnmount()),
    onUserRegistrationMailchimpSubscribe: (email) => dispatch(actions.userRegistrationMailchimpSubscribe(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);