/***************************/
/* Change Profile ACTIONS */
/***************************/

import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const changeProfileStart = () => {
  return {
    type: actionTypes.CHANGE_PROFILE_START
  };
};
export const changeProfileSuccess = (user) => {
  return {
    type: actionTypes.CHANGE_PROFILE_SUCCESS,
    user: user
  }
};
export const changeProfileFail = (error) => {
  return {
    type: actionTypes.CHANGE_PROFILE_FAIL,
    error: error
  }
};
export const changeProfile = (user) => {
  return dispatch => {
    dispatch(changeProfileStart());
    const headers = getAxiosHeader();
    axios.put('/users/me', user, headers)
      .then(response => {
        //console.log('changeProfile AXIOS response: ', response.data.data);
        dispatch(changeProfileSuccess(response.data.data));
      })
      .catch(err => {
        //console.log('changeProfile AXIOS catch err ', err.response);
        dispatch(changeProfileFail(err.response));
      });
  }
};

export const changeProfileUnmount = () => {
  return {
    type: actionTypes.CHANGE_PROFILE_UNMOUNT
  };
};