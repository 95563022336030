import React from 'react';
import LogoHomepage from "../../../../assets/images/logo-homepage-academy.svg";
import { Link } from "react-router-dom";

const logo = () => (
  <Link to="/" className="navbar-brand" >
      <img src={LogoHomepage} alt="Homepage Academy" />
  </Link>
);

export default logo;