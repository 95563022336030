import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchTestimonialsStart = () => {
  return {
    type: actionTypes.FETCH_TESTIMONIALS_START
  };
};
export const fetchTestimonialsSuccess = (testimonials) => {
  return {
    type: actionTypes.FETCH_TESTIMONIALS_SUCCESS,
    testimonials: testimonials
  }
};
export const fetchTestimonialsFail = (error) => {
  return {
    type: actionTypes.FETCH_TESTIMONIALS_FAIL,
    error: error
  }
};
export const fetchTestimonials = (slug) => {
  return dispatch => {
    dispatch(fetchTestimonialsStart());
    //console.log('fetchTestimonials', slug);
    const url = slug ? '/courses/' + slug + '/testimonials' : '/testimonials';
    const headers = getAxiosHeader();
    axios.get(url, headers)
      .then(res => {
        //console.log('fetchTestimonials res', res.data.data);
        dispatch(fetchTestimonialsSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('fetchTestimonials error', err);
        dispatch(fetchTestimonialsFail(err));
      });
  }
};

export const fetchTestimonialsUnmount = () => {
  return {
    type: actionTypes.FETCH_TESTIMONIALS_UNMOUNT
  };
};