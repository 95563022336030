import React, {Component, Fragment} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import PageHeader from '../layout/SingleBlog/PageHeader';
import SocialShare from '../layout/UI/SocialShare/SocialShare';
import RelatedBlog from '../layout/SingleBlog/RelatedBlog';
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/hompageacademy-fb-share-image.png";
import FbShareImageBlog12 from "../../assets/images/komunikaciona-strategija-lazar-dzamic-homepageacademy-1200x628.jpg";

const tagManagerArgs = {
  dataLayer: {
    page: 'Pojedinacni blog'
  },
  dataLayerName: 'PageDataLayer'
};

class SingleBlog extends Component {

  componentWillMount() {
    this.props.onFetchSingleBlog(this.props.match.params.slug);
    this.props.onFetchBlog();
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.props.onFetchSingleBlog(this.props.match.params.slug);
      this.props.onFetchBlog();
    }
    if ((this.props.singleBlog !== prevProps.singleBlog) && this.props.singleBlog.slug) {
      ReactGA.pageview(this.props.singleBlog.slug);
    }
  }
  
  componentWillUnmount() {
    this.props.onSingleBlogUnmount();
  }

  render() {
    let blog = <Spinner />;
    let pageHeader = null;
    let relatedBlog = null;
    let blogLink = null;

    if (!this.props.loading) {
      relatedBlog = <RelatedBlog blog={this.props.blog} singleBlogId={this.props.singleBlog.id} />;
      blogLink = process.env.PUBLIC_URL + "/novosti/" + this.props.singleBlog.slug;
      blog = (
        <section className="news-single">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 news-single__content">
                {ReactHtmlParser(this.props.singleBlog.content)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="news-single__social">
                  <SocialShare
                    url={blogLink}
                    title={this.props.singleBlog.title}
                    showLabel={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {relatedBlog}
              </div>
            </div>
          </div>
        </section>
      );
      pageHeader = <PageHeader singleBlog={this.props.singleBlog}/>;
    }
    const ogImage = (this.props.singleBlog.id === 12) ? FbShareImageBlog12 : (this.props.singleBlog.image ? this.props.singleBlog.image : FbShareImage);
    const ogTitle = this.props.singleBlog.name ? this.props.singleBlog.name : 'Postani deo zajednice koja deli znanje!';
    const ogDescription = this.props.singleBlog.excerpt ? this.props.singleBlog.excerpt : 'Registruj se!';

    return (
      <Fragment>
        <Helmet>
          <title>{ogTitle} - Homepage Academy</title>
          <meta name="description" content={ogDescription} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={ogDescription} />
        </Helmet>
        {pageHeader}
        {blog}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.blog.loading,
    singleBlog: state.blog.singleBlog,
    blog: state.blog.blog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBlog: () => dispatch(actions.fetchBlog()),
    onFetchSingleBlog: (slug) => dispatch(actions.fetchSingleBlog(slug)),
    onSingleBlogUnmount: () => dispatch(actions.singleBlogUnmount())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleBlog));