import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const introBox = (props) => {
    const title = props.title ?? 'Uči kroz praksu!';
    const content = props.content ?? '<p>Homepage akademija je mesto za sve one koji žele da uče i napreduju, te da se u profesionalnom smislu usavršavaju, razvijaju i prate trendove.</p>\n' +
        '            <p>Homepage akademija je mesto za tebe ukoliko:</p>\n' +
        '            <ul class="list-check">\n' +
        '                <li>Želiš da unaprediš svoje veštine i usavršavaš se</li>\n' +
        '                <li>Želiš da unaprediš svoj biznis i poslovanje</li>\n' +
        '                <li>Želiš da napreduješ u karijeri</li>\n' +
        '                <li>Želiš da se prekvalifikuješ</li>\n' +
        '            </ul>';
    return (
        <div className="course--intro">
            <h2 className="title">{title}</h2>
            <div>
                {ReactHtmlParser(content)}
            </div>
        </div>
    );
};

export default introBox;