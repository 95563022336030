import {updateObject} from "../../../shared/utility";

export const fetchSingleBlogStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleBlogSuccess = (state, action) => {
  return updateObject(state, {
    singleBlog: action.singleBlog,
    error: null,
    loading: false
  });
};
export const fetchSingleBlogFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const singleBlogUnmount = (state, action) => {
  return updateObject(state, {
    singleBlog: [],
    error: null,
    loading: false
  });
};