import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";


/***************************
 FETCH TEST
 **************************/
export const fetchTestStart = () => {
  return {
    type: actionTypes.FETCH_TEST_START
  };
};
export const fetchTestSuccess = (test) => {
  return {
    type: actionTypes.FETCH_TEST_SUCCESS,
    test: test
  }
};
export const fetchTestFail = (error) => {
  return {
    type: actionTypes.FETCH_TEST_FAIL,
    error: error
  }
};
export const fetchTest = (slug) => {
  return dispatch => {
    dispatch(fetchTestStart());
    const headers = getAxiosHeader();
    axios.get('/courses/' + slug + '/test', headers)
      .then(res => {
        //console.log('fetchTestSuccess', res.data.data);
        dispatch(fetchTestSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchTestFail(err));
      });
  }
};
export const fetchTestUnmount = () => {
  return {
    type: actionTypes.FETCH_TEST_UNMOUNT
  };
};

/***************************
 SUBMIT TEST
 **************************/
export const submitTestStart = () => {
  return {
    type: actionTypes.SUBMIT_TEST_START
  };
};
export const submitTestSuccess = (testAttempt) => {
  return {
    type: actionTypes.SUBMIT_TEST_SUCCESS,
    testAttempt: testAttempt
  }
};
export const submitTestFail = (error) => {
  return {
    type: actionTypes.SUBMIT_TEST_FAIL,
    error: error
  }
};
export const submitTest = (attemptId, answers) => {
  return dispatch => {
    dispatch(submitTestStart());
    const headers = getAxiosHeader();
    axios.put('/test-attempts/' + attemptId, answers, headers)
      .then(res => {
        //console.log('submitTestSuccess', res.data.data);
        dispatch(submitTestSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('submitTestFail!', err);
        dispatch(submitTestFail(err));
      });
  }
};