import React, {Fragment} from 'react';

const mailchimpCustomForm = ({ status, message, className, style, onSubmitted }) => {
  let input;
  const submit = () =>
    input &&
    input.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: input.value
    });

  return (
    <Fragment>
      <div className="row">
        <div className="col-8 offset-2 col-lg-4 offset-lg-4">
          {status === "sending" && <div>Slanje u toku...</div>}
          {status === "error" && (
            <div
              className="error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="success"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4 offset-lg-4 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <input
            className="form-control"
            ref={node => (input = node)}
            type="email"
            placeholder="Email"
          />
          <button onClick={submit} className="btn btn-primary">Pošalji</button>
        </div>
      </div>
    </Fragment>
  );
  
};

export default mailchimpCustomForm;