import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailchimpCustomForm from "./MailchimpCustomForm";

const url = "https://homepage.us17.list-manage.com/subscribe/post?u=786fe09e1bb45968511438f03&amp;id=35c4ee4e7c";

const homepageMailchimpSubscribe = (props) => {
  return(
    <section className="homepage__subscribe">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="title">Prijavi se na naš newsletter</h2>
          </div>
        </div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <MailchimpCustomForm
              status={status}
              message={message}
              onSubmitted={formData => subscribe(formData)}
            />
          )}
        />
      </div>
    </section>
  );
};

export default homepageMailchimpSubscribe;