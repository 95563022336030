import React from 'react';
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  event: 'PageView',
  dataLayer: {
    page: 'registration-success'
  },
  dataLayerName: 'PageDataLayer'
};

const registrationSuccess = (props) => {
  TagManager.dataLayer(tagManagerArgs);
  ReactGA.pageview('registration-success');
  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 d-flex align-items-center user-form">
            <div className="user-form__box">
              <h2 className="user-form__title">Registracija je u toku</h2>
              <p className="user-form__message">Zdravo!</p>
              <p className="user-form__message">Potrebno je da potvrdiš svoju registaciju klikom na konfirmacioni link koji smo ti poslali na adresu elektronske pošte.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default registrationSuccess;