/**********************/
/* User login ACTIONS */
/**********************/
import * as actionTypes from "../actionTypes";

export const siteInfoCloseSuccess = () => {
  return {
    type: actionTypes.SITE_INFO_CLOSE_SUCCESS
  };
};

export const siteInfoClose = () => {
  return dispatch => {
    localStorage.setItem('hpa_site_info--early-bird14', true);
    dispatch(siteInfoCloseSuccess());

  }
};

export const checkSiteInfoCloseOnLoad = () => {
  return dispatch => {
    const siteInfoCloseExists = localStorage.getItem('hpa_site_info--early-bird14');
    if (siteInfoCloseExists) {
      dispatch(siteInfoCloseSuccess());
    }
  }
};