import $ from 'jquery';

$(document).ready(function() {

  // Back to top button
  $('.js--back-to-top').on('click', function(e){
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  });


  $('#navbarMainMenu').on('show.bs.collapse', function () {
    $('.js--navbar').addClass('navbar-opened');
  });
  $('#navbarMainMenu').on('hide.bs.collapse', function () {
    $('.js--navbar').removeClass('navbar-opened');
  });

});
