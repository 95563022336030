import React, {useRef, useState, useLayoutEffect} from 'react';
import {Link} from 'react-router-dom';

import CoursePackageStatistics from "./CousePackageStatistics";
import ReactHtmlParser from "react-html-parser";
import SocialShare from "../../UI/SocialShare/SocialShare";
import iconApproved from "../../../../assets/images/icon-approved-big.png";
import iconPurchased from "../../../../assets/images/icon-purchased-big.png";

const coursePackageCard = (props) => {
  const secondsForCountdown = parseInt(props.secondsForCountdown);
  let courseStatistics = null;
  if (props.singleCoursePackage.instructors) {
    courseStatistics = (
      <CoursePackageStatistics
        singleCoursePackage={props.singleCoursePackage}/>
    );
  }
  const courseLink = process.env.PUBLIC_URL + "/kurs/" + props.singleCoursePackage.slug;
  const courseCardFixedClass = props.courseCardFixed ? " fixed" : "";

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 50;

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  const test_dimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  };

  const handleResize = () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    //console.log('courseCard handleResize');
  };

  const setCoursePackagePath = () => {
    const coursePackagePath = "/checkout/" + props.singleCoursePackage.slug + "/package";
    props.setCoursePackagePath(coursePackagePath);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={"course-card"} ref={targetRef}>
      <div className={"course-card__inner" + courseCardFixedClass} style={{maxWidth: ((window.innerWidth < 992) ? 'none' : (dimensions.width - 16))}}>
        <div className="course-card__thumb">
          { (props.singleCoursePackage.approved) ? (
              <img src={iconApproved} className="course__icon-approved" />
          ): (
              (props.singleCoursePackage.purchased) ? (
                  <img src={iconPurchased} className="course__icon-approved" />
              ) : null
          ) }
          <img className="img-fluid" src={props.singleCoursePackage.image} alt={props.singleCoursePackage.title}/>
        </div>
        <div className="course-card__details">
          <h4 className="course-card__price">{props.singleCoursePackage.price} {ReactHtmlParser('&euro;')}</h4>
          <div className="course-card__price-info">(cena je izražena sa PDV-om)</div>
          <div className="course-card__buttons">
            {
              (!props.singleCoursePackage.purchased && !props.singleCoursePackage.approved) ? (
                <Link to={"/checkout/" + props.singleCoursePackage.slug + "/package"} className="btn btn-primary" onClick={setCoursePackagePath}>Kupi odmah</Link>
              ) : (
                  (props.singleCoursePackage.purchased) ? <p><strong>Za ovaj paket postoji aktivna porudžbina.</strong></p> : null
              )
            }
          </div>

          {courseStatistics}
          <div className="course-card__share d-flex align-items-center justify-content-center">
            <SocialShare
              url={courseLink}
              title={props.singleCoursePackage.title}
              showLabel={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default coursePackageCard;