import React, {Component, Fragment} from 'react';
import PageHeader from "../layout/PageHeader/PageHeader";
import IntroBox from '../layout/Courses/IntroBox';
import CourseBox from "../layout/CourseBox/CourseBox";
import Spinner from "../layout/UI/Spinner/Spinner";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {differenceFromToday} from "../../shared/utility";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-kursevi.png";
import {Helmet} from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    page: 'Svi kursevi'
  },
  dataLayerName: 'PageDataLayer'
};

class Courses extends Component {

  componentDidMount() {
    this.props.onFetchCourses();
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('kursevi');
  }

  render() {
    let courses = <Spinner />;
    if (!this.props.loading) {
      let counter = 0;
      courses = Object.values(this.props.courses).map(course => {
        counter ++;
        const secondsForCountdown = differenceFromToday(course.startDate);
        return (
          <div className={"col-md-6 col-xl-3 align-self-md-stretch align-self-auto pb-md-0 pb-2 mb-3" + ((counter % 3 === 1) && (counter > 1) ? ' offset-xl-3' : '')} key={course.id}>
            <CourseBox
              course={course}
              secondsForCountdown={secondsForCountdown} />
          </div>
        )
      });
      if (courses.length === 0) {
        courses = (
          <div className="col-md-6 col-xl-9 d-flex align-items-center justify-content-center">
            <h3 className="text-center">Nema aktivnih kurseva</h3>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>Kursevi - Homepage Academy</title>
          <meta name="description" content="Odaberi kurs po svojoj meri" />
          <meta property="og:title" content="Prijavi se" />
          <meta property="og:description" content="Odaberi kurs po svojoj meri" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>
        <PageHeader subtitle={"Biraj znanje"} class={"courses"}>
          Homepage Academy
        </PageHeader>
        <section className="courses">
          <div className="container">
            <div className="row courses__list">
              <div className="col-md-6 col-xl-3 align-self-md-stretch align-self-auto pb-md-0 mb-3 pb-2">
                <IntroBox/>
              </div>
              {courses}
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    courses: state.course.courses,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCourses: () => dispatch(actions.fetchCourses())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);