import {updateObject} from "../../../shared/utility";

export const lessonFavoriteStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};
export const lessonFavoriteSuccess = (state, action) => {
  const updateMeta = action.lessonMeta;
  const updateLesson = updateObject(state.lesson, {meta: updateMeta});
  return updateObject(state, {
    lesson: updateLesson,
    error: null,
  });
};
export const lessonFavoriteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

export const fetchFavoriteLessonsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchFavoriteLessonsSuccess = (state, action) => {
  return updateObject(state, {
    favoriteLessons: action.favoriteLessons,
    error: null,
    loading: false
  });
};
export const fetchFavoriteLessonsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

