import React, {Fragment} from 'react';

const lessonInstructors = (props) => {
  const instructorKeys = Object.keys(props.instructors);
  const instructors = instructorKeys.map(key => {
    return (
      <div className="col-md-6 mb-3" key={key}>
        <div className="lesson-details__instructor d-flex align-items-center justify-content-start">
          <img className="lesson-details__instructor__img" src={props.instructors[key].image}
               alt={props.instructors[key].name}/>
          <div
            className="lesson-details__instructor__details d-flex align-items-center flex-column justify-content-start">
            <h3 className="lesson-details__instructor__name">{props.instructors[key].name}</h3>
            <h4 className="lesson-details__instructor__position">
              {props.instructors[key].title}
              <span className="lesson-details__instructor__company">{props.instructors[key].company}</span>
            </h4>
          </div>
        </div>
      </div>
    )
  });

  let content = null;
  if (instructorKeys.length > 0) {
    content = (
      <div className="lesson-details__instructors">
        <h2 className="title">Predavači</h2>
        <div className="row d-flex align-self-stretch">
          {instructors}
        </div>
      </div>
    );
  }

  return content;
};

export default lessonInstructors;