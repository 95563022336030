import React from 'react';
import {Form} from "react-bootstrap";

const testQuestion = (props) => {
  
  const checkboxHandler = (e) => {
    const regex = /\d+/g;
    props.replied((e.target.name).match(regex)[0], e.target.value, e.target.checked, props.multiple);
  };
  
  const answers = props.answers.map(answer => {
    return (
      <Form.Check
        key={'answer-' + answer.id}
        id={'answer-' + answer.id}
        value={answer.id}
        type={props.multiple ? 'checkbox' : 'radio'}
        name={'question-' + props.id}
        className={"test__question__answer answer--radio " + (props.multiple ? 'checkbox' : 'radio') + "-wrapper" + (props.readOnly ? ' readonly' : '')}
        label={answer.text}
        title={answer.text}
        onChange={checkboxHandler}
      />
    );
  });
  
  return(
    <div className={"test__question"}>
      <h4 className={"test__question__text" + ((props.correct !== null) ? (props.correct ? ' correct' : ' incorrect') : null)}>
        {props.ordinal}. {props.question}
      </h4>
      {props.hint ? (<span className="test__question__hint">* {props.hint}</span>) : null}
      <div className={"test__question__answers"}>
        {answers}
      </div>
    </div>
  );
};

export default testQuestion;