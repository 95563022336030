import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from "../store/actions/index";
// import withErrorHandler from "../hoc/withErrorHandler/withErrorHandler";
// import axios from "../axios";
import { loadReCaptcha } from 'react-recaptcha-google'

import Layout from '../hoc/Layouts/Layout';
import Homepage from "./pages/Homepage";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ResetPassword from "./pages/ResetPassword";
import RegistrationSuccess from "./pages/RegistrationSuccess";
import RegistrationConfirm from "./pages/RegistrationConfirm";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import ChangePassword from "./pages/ChangePassword";
import ChangeProfile from "./pages/ChangeProfile";
import AboutUs from "./pages/AboutUs";
import Courses from "./pages/Courses";
import SingleCourse from "./pages/SingleCourse";
import Lesson from "./pages/Lesson";
import Checkout from "./pages/Checkout";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import MyCourses from "./pages/MyCourses";
import FavoriteLessons from "./pages/FavoriteLessons";
import Instructors from "./pages/Instructors";
import SingleInstructor from "./pages/SingleInstructor";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import Contact from "./pages/Contact";
import RequestCourse from "./pages/RequestCourse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ToS from "./pages/ToS";
import Podcasts from "./pages/Podcasts";
import SinglePodcast from "./pages/SinglePodcast";
import TestIntro from "./pages/TestIntro";
import Test from "./pages/Test";
import CheckoutCard from "./pages/CheckoutCard";
import CoursePackages from "./pages/CoursePackages";
import SingleCoursePackage from "./pages/SingleCoursePackage";

import '../assets/style/style.scss';


class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    this.props.onCheckCookieOnLoad();
    this.props.onCheckSiteInfoCloseOnLoad();
    loadReCaptcha();
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path="/checkout/:slug/:type?" component={Checkout}/>
        <Route exact path="/checkout-success" component={CheckoutSuccess}/>
        <Route exact path="/checkout-card" component={CheckoutCard}/>
        <Route exact path="/my-courses" component={MyCourses}/>
        <Route exact path="/favorite-lessons" component={FavoriteLessons}/>
        <Route exact path="/change-password" component={ChangePassword}/>
        <Route exact path="/change-profile" component={ChangeProfile}/>
        <Route exact path="/register" component={Registration}/>
        <Route exact path="/registration-success" component={RegistrationSuccess}/>
        <Route exact path="/registration/confirm/:hash" component={RegistrationConfirm}/>
        <Route exact path="/reset-password" component={ResetPassword}/>
        <Route exact path="/reset-password/confirm/:hash" component={ResetPasswordConfirm}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/o-nama" component={AboutUs}/>
        <Route exact path="/kursevi" component={Courses}/>
        <Route exact path="/kurs/:kurs/lekcija/:slug" component={Lesson}/>
        <Route exact path="/kurs/:slug" component={SingleCourse}/>
        <Route exact path="/predavaci" component={Instructors}/>
        <Route exact path="/predavac/:slug" component={SingleInstructor}/>
        <Route exact path="/novosti/:slug" component={SingleBlog}/>
        <Route exact path="/novosti" component={Blog}/>
        <Route exact path="/kontakt" component={Contact}/>
        <Route exact path="/kurs-na-zahtev" component={RequestCourse}/>
        <Route exact path="/uslovi-koriscenja" component={ToS}/>
        <Route exact path="/politika-privatnosti" component={PrivacyPolicy}/>
        <Route exact path="/svi-podkasti" component={Podcasts}/>
        <Route exact path="/podkast/:slug" component={SinglePodcast}/>
        <Route exact path="/test-intro/:course/:lesson" component={TestIntro}/>
        <Route exact path="/test/:course/:lesson" component={Test}/>
        <Route exact path="/paketi-kurseva" component={CoursePackages}/>
        <Route exact path="/paket-kurseva/:slug" component={SingleCoursePackage}/>
        <Route exact path="/" component={Homepage}/>
        <Redirect to="/" />
      </Switch>
      );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route exact path="/checkout/:slug/:type?" component={Checkout}/>
          <Route exact path="/checkout-success" component={CheckoutSuccess}/>
          <Route exact path="/checkout-card" component={CheckoutCard}/>
          <Route exact path="/my-courses" component={MyCourses}/>
          <Route exact path="/favorite-lessons" component={FavoriteLessons}/>
          <Route exact path="/change-password" component={ChangePassword}/>
          <Route exact path="/change-profile" component={ChangeProfile}/>
          <Route exact path="/logout" component={Logout}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/o-nama" component={AboutUs}/>
          <Route exact path="/kursevi" component={Courses}/>
          <Route exact path="/kurs/:kurs/lekcija/:slug" component={Lesson}/>
          <Route exact path="/kurs/:slug" component={SingleCourse}/>
          <Route exact path="/predavaci" component={Instructors}/>
          <Route exact path="/predavac/:slug" component={SingleInstructor}/>
          <Route exact path="/novosti/:slug" component={SingleBlog}/>
          <Route exact path="/novosti" component={Blog}/>
          <Route exact path="/kontakt" component={Contact}/>
          <Route exact path="/kurs-na-zahtev" component={RequestCourse}/>
          <Route exact path="/uslovi-koriscenja" component={ToS}/>
          <Route exact path="/politika-privatnosti" component={PrivacyPolicy}/>
          <Route exact path="/svi-podkasti" component={Podcasts}/>
          <Route exact path="/podkast/:slug" component={SinglePodcast}/>
          <Route exact path="/test-intro/:course/:lesson" component={TestIntro}/>
          <Route exact path="/test/:course/:lesson" component={Test}/>
          <Route exact path="/paketi-kurseva" component={CoursePackages}/>
          <Route exact path="/paket-kurseva/:slug" component={SingleCoursePackage}/>
          <Route exact path="/" component={Homepage}/>
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
        <Layout>
          { routes }
        </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onCheckCookieOnLoad: () => dispatch(actions.checkCookieOnLoad()),
    onCheckSiteInfoCloseOnLoad: () => dispatch(actions.checkSiteInfoCloseOnLoad())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));