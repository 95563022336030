/*************************************/
/* Change password REDUCER functions */
/*************************************/
import { updateObject } from '../../../shared/utility';

export const changePasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    passwordChanged: false,
  });
};
export const changePasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    passwordChanged: true,
  });
};
export const changePasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};