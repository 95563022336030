import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchCoursePackagesStart = () => {
    return {
        type: actionTypes.FETCH_COURSE_PACKAGES_START
    };
};
export const fetchCoursePackagesSuccess = (coursePackages) => {
    return {
        type: actionTypes.FETCH_COURSE_PACKAGES_SUCCESS,
        coursePackages: coursePackages
    }
};
export const fetchCoursePackagesFail = (error) => {
    return {
        type: actionTypes.FETCH_COURSE_PACKAGES_FAIL,
        error: error
    }
};
export const fetchCoursePackages = () => {
    return dispatch => {
        dispatch(fetchCoursePackagesStart());

        const headers = getAxiosHeader();
        axios.get('/packages', headers)
            .then(res => {
                //console.log('fetchCoursePackages res', res.data.data);
                dispatch(fetchCoursePackagesSuccess(res.data.data));
            })
            .catch(err => {
                //console.log('fetchCoursePackages error', err);
                dispatch(fetchCoursePackagesFail(err));
            });
    }
};