import React from 'react';
import {getDayString, getCourseString} from '../../../../shared/utility';
import ReactHtmlParser from "react-html-parser";

const coursePackageStatistics = (props) => {

    const instructorsCount = props.singleCoursePackage.instructors.length;
    const instructorsSpell = (((instructorsCount % 10) === 1) && (instructorsCount !== 11)) ? "predavač" : "predavača";

    const coursesCount = props.singleCoursePackage.courses.length;
    const coursesCountSpell = getCourseString(coursesCount);

    const availableDaysSpell = getDayString(props.singleCoursePackage.availableDays);
    
    let discountPrice = 0;
    if (props.singleCoursePackage.courses) {
        const sumOfOriginalCoursePrices = props.singleCoursePackage.courses.reduce((accumulator, item) => {
            return accumulator + item.course.price;
        }, 0);
        const pricesDifference = sumOfOriginalCoursePrices - props.singleCoursePackage.price;
        discountPrice = pricesDifference > 0 ? pricesDifference : 0;
    }

    return (
        <div className="course-card__statistics">
            <div className="course-card__statistics__row"><i className="icon icon-module"></i>
                <strong>{coursesCount}</strong> {coursesCountSpell}
            </div>
            <div className="course-card__statistics__row"><i className="icon icon-circle-user"></i>
                <strong>{instructorsCount}</strong> {instructorsSpell}
            </div>
            
            <div className="course-card__statistics__row"><i className="icon icon-certificate"></i>
                potvrda o odslušanom kursu
            </div>
            <div className="course-card__statistics__row"><i className="icon icon-calendar"></i>
                <strong>{props.singleCoursePackage.availableDays}</strong> {availableDaysSpell} dostupnost paketa
            </div>
            
            { discountPrice > 0 ? (
                <div className="course-card__statistics__row highlighted"><i className="icon icon-piggy"></i>
                    <strong>{discountPrice}{ReactHtmlParser('&euro;')}</strong> <strong>ušteda</strong>
                </div>
            ) : null }
        </div>
    );
};

export default coursePackageStatistics;