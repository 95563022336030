import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

import { loginStart, loginSuccess, loginFail} from './login';
import { registrationStart, registrationSuccess, registrationFail, registrationUnmount} from './registration';
import { userRegistrationConfirmStart, userRegistrationConfirmSuccess, userRegistrationConfirmFail} from './registrationConfirm';
import { resetPasswordStart, resetPasswordSuccess, resetPasswordFail, resetPasswordUnmount} from './resetPassword';
import { resetPasswordConfirmStart, resetPasswordConfirmSuccess, resetPasswordConfirmFail} from './resetPasswordConfirm';
import { changePasswordStart, changePasswordSuccess, changePasswordFail} from './changePassword';
import { changeProfileStart, changeProfileSuccess, changeProfileFail, changeProfileUnmount} from './changeProfile';
import { getLoggedInUserSuccess, getLoggedInUserFail } from './userMe';

const initialState = {
  user: {},
  token: null,
  error: null,
  confirmedRegistration: false,
  loading: false,
  authRedirectPath: '/',
  resetPasswordSent: false,
  resetPasswordConfirmed: false,
  passwordChanged: false,
  profileChanged: false
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user: {}
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {authRedirectPath: action.path});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);

    case actionTypes.REGISTRATION_START:
      return registrationStart(state, action);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state, action);
    case actionTypes.REGISTRATION_FAIL:
      return registrationFail(state, action);
    case actionTypes.REGISTRATION_UNMOUNT:
      return registrationUnmount(state, action);

    case actionTypes.REGISTRATION_CONFIRM_START:
      return userRegistrationConfirmStart(state, action);
    case actionTypes.REGISTRATION_CONFIRM_SUCCESS:
      return userRegistrationConfirmSuccess(state, action);
    case actionTypes.REGISTRATION_CONFIRM_FAIL:
      return userRegistrationConfirmFail(state, action);

    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_UNMOUNT:
      return resetPasswordUnmount(state, action);

    case actionTypes.RESET_PASSWORD_CONFIRM_START:
      return resetPasswordConfirmStart(state, action);
    case actionTypes.RESET_PASSWORD_CONFIRM_SUCCESS:
      return resetPasswordConfirmSuccess(state, action);
    case actionTypes.RESET_PASSWORD_CONFIRM_FAIL:
      return resetPasswordConfirmFail(state, action);

    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);

    case actionTypes.CHANGE_PROFILE_START:
      return changeProfileStart(state, action);
    case actionTypes.CHANGE_PROFILE_SUCCESS:
      return changeProfileSuccess(state, action);
    case actionTypes.CHANGE_PROFILE_FAIL:
      return changeProfileFail(state, action);
    case actionTypes.CHANGE_PROFILE_UNMOUNT:
      return changeProfileUnmount(state, action);

    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);

    case actionTypes.GET_USER_ME_SUCCESS:
      return getLoggedInUserSuccess(state, action);
    case actionTypes.GET_USER_ME_FAIL:
      return getLoggedInUserFail(state, action);

    default:
      return state;
  }
};

export default reducer;