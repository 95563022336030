import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router-dom';
import {Modal} from "react-bootstrap";

import PageHeader from "../layout/SingleCourse/PageHeader/PageHeader";
import CourseDetailsCounter from "../layout/SingleCourse/CourseDetailsCounter";
import CourseModules from "../layout/SingleCourse/CourseModules";
import CourseTestimonials from "../layout/SingleCourse/CourseTestimonials";
import PaymentInfoModal from "../layout/PaymentInfoModal/PaymentInfoModal";

import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {differenceFromToday, formatDate, updateObject} from "../../shared/utility";
import Spinner from "../layout/UI/Spinner/Spinner";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/hompageacademy-fb-share-image.png";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Pojedinacni kurse'
  },
  dataLayerName: 'PageDataLayer'
};

const COURSE_CARD_OFFSET_Y = 50;

class SingleCourse extends Component {
  state = {
    courseCardFixed: false,
    paymentProcessorState: null,
    paymentProcessorMessage: null
  }

  scrollHandler = event => {
    if (window.scrollY > COURSE_CARD_OFFSET_Y && !this.state.courseCardFixed) {
      this.setState(updateObject(this.state, {
        courseCardFixed: true
      }));
    } else if (window.scrollY <= COURSE_CARD_OFFSET_Y && this.state.courseCardFixed) {
      this.setState(updateObject(this.state, {
        courseCardFixed: false
      }));
    }
  }

  componentWillMount() {
    const courseSlug = this.props.match.params.slug;
    this.props.onFetchSingleCourse(courseSlug);
    this.props.onFetchCourseModules(courseSlug);
    this.props.onFetchTestimonials(courseSlug);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollHandler);
    TagManager.dataLayer(tagManagerArgs);
    
    const paymentProcessorQuery = this.getPaymentProcessorQuerySearch(this.props.location.search);
    if (paymentProcessorQuery.payment && ['failed', 'success'].includes(paymentProcessorQuery.payment)) {
      this.setState(updateObject(this.state, {
        paymentProcessorState: paymentProcessorQuery.payment,
        paymentProcessorMessage: paymentProcessorQuery.message
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    this.props.onFetchTestimonialsUnmount();
    this.props.onFetchSingleCourseUnmount();
    this.props.onFetchCourseModulesUnmount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.courseModules !== prevProps.courseModules) || (this.props.singleCourse !== prevProps.singleCourse)) {
      if (this.props.jumpToLesson && this.props.singleCourse.slug && this.props.courseModules[0]) {
        const courseStarted = differenceFromToday(this.props.singleCourse.startDate) < 0;
        if (courseStarted) {
          const firstLessonLink = '/kurs/' + this.props.singleCourse.slug + '/lekcija/' + this.props.courseModules[0]['lessons'][0]['slug'];
          this.props.history.push(firstLessonLink);
        }
        this.props.onJumpToLessonRevert();
      }
    }
    if ((this.props.singleCourse !== prevProps.singleCourse) && this.props.singleCourse.slug) {
      ReactGA.pageview(this.props.singleCourse.slug);
    }
    
  }

  setCoursePath = (coursePath) => {
    this.props.onSetAuthRedirectPath(coursePath);
  }

  getPaymentProcessorQuerySearch = (querySearch) => {
    const query = new URLSearchParams(
        this.props.location.search
    );
    return {
      payment: query.get('payment'),
      message: query.get('message')
    };
  }

  paymentModalCloseHandler = () => {
    this.setState(updateObject(this.state, {
      paymentProcessorState: null
    }));
  }

  render() {
    let singleCourse = (
      <section className="course-details">
        <Spinner/>
      </section>
    );
    const singleCourseCount = Object.keys(this.props.singleCourse).length;
    let paymentInfo = null;

    if (!this.props.loading) {
      if (singleCourseCount > 0) {
        const secondsForCountdown = differenceFromToday(this.props.singleCourse.startDate);
        const courseStarted = secondsForCountdown < 0;

        let firstLessonSlug = null;
        if (this.props.courseModules.length > 0) {
          firstLessonSlug = this.props.courseModules[0]['lessons'][0]['slug'];
        }
        
        const goalsTitle = (this.props.singleCourse.id === 8) ? 'Koja znanja vam donosi kurs copywritinga?' : 'Šta ćeš naučiti?';
        const priorKnowledgeTitle = (this.props.singleCourse.id === 8) ? 'Kurs copywritinga za preduzetnike i buduće marketare nije za vas ako:' : 'Važno je znati:';

        singleCourse = (
          <Fragment>
            { this.props.singleCourse ? (
              <PageHeader
                firstLessonSlug={firstLessonSlug}
                singleCourse={this.props.singleCourse}
                secondsForCountdown={secondsForCountdown}
                courseCardFixed={this.state.courseCardFixed}
                courseStarted={courseStarted}
                isAuthenticated={this.props.isAuthenticated}
                setCoursePath={this.setCoursePath}
              />
            ) : null }
            <section className="course-details">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-1">

                    {this.props.singleCourse.goals ? (
                      <div className="course-details__intro">
                        <h2 className="title">{ goalsTitle }</h2>
                        { ReactHtmlParser(this.props.singleCourse.goals) }
                      </div>
                    ) : null }

                    {this.props.singleCourse.priorKnowledge ? (
                      <div className="course-details__foreknowledge">
                        <h2 className="title">{ priorKnowledgeTitle }</h2>
                        { ReactHtmlParser(this.props.singleCourse.priorKnowledge) }
                      </div>
                    ) : null }

                    {this.props.singleCourse.description ? (
                      <div className="course-details__about">
                        <h2 className="title">O kursu</h2>
                        { ReactHtmlParser(this.props.singleCourse.description) }
                      </div>
                    ) : null }

                    { this.props.singleCourse.statistics ? (
                      <CourseDetailsCounter data={this.props.singleCourse.statistics} />
                    ) : null }


                    { this.props.testimonials ? (
                      <CourseTestimonials
                        data={this.props.testimonials}
                        loading={this.props.testimonialsLoading}
                      />
                    ) : null }

                    { this.props.courseModules.length > 0 ? (
                      <CourseModules
                        data={this.props.courseModules}
                        courseSlug={this.props.singleCourse.slug}
                        courseApproved={this.props.singleCourse.approved}
                        courseStarted={courseStarted}
                        isAuthenticated = {this.props.isAuthenticated}
                      />
                    ) : null }

                  </div>
                </div>
              </div>
            </section>
          </Fragment>
        );
        
        if (this.state.paymentProcessorState) {
          paymentInfo = <PaymentInfoModal
              productType={'Kurs'}
              paymentProcessorState={this.state.paymentProcessorState}
              courseStarted={courseStarted ?? null}
              startDate={this.props.singleCourse.startDate ?? null}
              courseName={this.props.singleCourse.name}
              orderInfo={this.props.orderInfo}
              hide={this.paymentModalCloseHandler}
          />;
        }
        
      } else {
        singleCourse = (
          <section className="course-details">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="mt-5">Traženi kurs nije pronađen.</p>
                </div>
              </div>
            </div>
          </section>);
      }
    }
    
    
    
    const courseTitle = this.props.singleCourse.name ? this.props.singleCourse.name + ' - Homepage Academy' : 'Homepage Academy';
    const courseDescription= this.props.singleCourse.name ? this.props.singleCourse.name : '';
    const ogImage = this.props.singleCourse.ogImage ? this.props.singleCourse.ogImage : FbShareImage;
    const ogTitle = this.props.singleCourse.ogTitle ? this.props.singleCourse.ogTitle : 'Postani deo zajednice koja deli znanje!';
    const ogDescription = this.props.singleCourse.ogDescription ? this.props.singleCourse.ogDescription : 'Registruj se!';
    
    return (
      <Fragment>
        <Helmet>
          <title>{courseTitle}</title>
          <meta name="description" content={courseDescription} />
          { ogImage ? (
            <meta property="og:image" content={ogImage} />
          ) : null }
          { ogTitle ? (
              <meta property="og:title" content={ogTitle} />
          ) : null }
          { ogDescription ? (
            <meta property="og:description" content={ogDescription} />
          ) : null }
        </Helmet>
        { singleCourse }
        { paymentInfo }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    singleCourse: state.course.singleCourse,
    orderInfo: state.course.singleCourse.orderInfo,
    courseModules: state.course.courseModules,
    jumpToLesson: state.course.jumpToLesson,
    testimonials: state.testimonials.testimonials,
    testimonialsLoading: state.testimonials.loading,
    isAuthenticated: state.auth.token != null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleCourse: (slug) => dispatch(actions.fetchSingleCourse(slug)),
    onFetchSingleCourseUnmount: () => dispatch(actions.fetchSingleCourseUnmount()),
    onFetchCourseModules: (slug) => dispatch(actions.fetchCourseModules(slug)),
    onFetchCourseModulesUnmount: () => dispatch(actions.fetchCourseModulesUnmount()),
    onJumpToLessonRevert: () => dispatch(actions.jumpToLessonRevert()),
    onFetchTestimonials: (slug) => dispatch(actions.fetchTestimonials(slug)),
    onFetchTestimonialsUnmount: () => dispatch(actions.fetchTestimonialsUnmount()),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleCourse));