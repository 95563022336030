/**********************/
/* User login ACTIONS */
/**********************/
import * as actionTypes from "../actionTypes";
import axios from "../../../axios";

export const cookieConfirmSuccess = () => {
  return {
    type: actionTypes.COOKIE_CONFIRM_SUCCESS
  };
};

export const cookieConfirm = () => {
  return dispatch => {
    localStorage.setItem('hpa_cookies', true);
    dispatch(cookieConfirmSuccess());

  }
};

export const checkCookieOnLoad = () => {
  return dispatch => {
    const cookieExists = localStorage.getItem('hpa_cookies');
    if (cookieExists) {
      dispatch(cookieConfirmSuccess());
    }
  }
};