import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";

import Countdown from "../UI/Countdown/Countdown";
import ReactHtmlParser from "react-html-parser";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {formatSecondsToHours, getHourString, getLessonString} from "../../../shared/utility";
import iconApproved from "../../../assets/images/icon-approved-small.png";
import iconPurchased from "../../../assets/images/icon-purchased-small.png";

class CourseBox extends Component {

  state = {
    courseImageHeight: 0
  }

  handleResize = () => this.setState({
    courseImageHeight: this.courseImage.clientHeight
  });

  handleGoToLesson = (e) => {
    e.preventDefault();
    this.props.onJumpToLesson();
    this.props.history.push('/kurs/' + this.props.course.slug);
  }

  handleImageLoaded = () => {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const imageHeightWithMargin = this.state.courseImageHeight + 44;
    const courseDetailsBoxHeight = (this.state.courseImageHeight > 0) ? 'calc(100% - ' + imageHeightWithMargin + 'px)' : 'auto';

    const instructorsCount = this.props.course.instructors.length;
    const instructorsSpell = ((instructorsCount % 10) === 1) ? "predavač" : "predavača";

    const lessonCount = this.props.course.lessonCount;
    const lessonCountSpell = getLessonString(lessonCount);

    const totalCourseDurationInHours = formatSecondsToHours(this.props.course.totalDuration);
    const totalCourseDurationInHoursSpell = getHourString(totalCourseDurationInHours);

    const secondsForCountdown = parseInt(this.props.secondsForCountdown);

    let courseBoxButtons = null;
    if (this.props.course.approved) {
      courseBoxButtons = (
        <a href="#" onClick={(event) => this.handleGoToLesson(event)} className="btn btn-secondary btn-goto">Pristupi kursu</a>
      );
    } else if (this.props.course.purchased) {
      courseBoxButtons = (
        <Link to={'/kurs/' + this.props.course.slug} className="btn btn-link">Pogledaj</Link>
      );
    } else {
      courseBoxButtons = (
        <Fragment>
          <Link to={ "/checkout/" + this.props.course.slug } className="btn btn-primary">Kupi odmah</Link>
          <Link to={'/kurs/' + this.props.course.slug} className="btn btn-link">Pogledaj</Link>
        </Fragment>
      );
    }

    return(
      <div className="course">
        {(this.props.course.approved) ? (
          <img src={iconApproved} className="course__icon-approved" />
          ): (
            (this.props.course.purchased) ? (
                <img src={iconPurchased} className="course__icon-approved" />
            ) : null
        )}
        <Link to={'/kurs/' + this.props.course.slug}>
          <img className="course__img img-fluid"
             ref={ (courseImage) => { this.courseImage = courseImage } }
             onLoad={this.handleImageLoaded}
             src={this.props.course.image}
             alt={this.props.course.name} />
        </Link>

        {this.state.courseImageHeight ? (
          <div className="course__details d-flex flex-column justify-content-between" style={{minHeight: courseDetailsBoxHeight}}>
            <h3 className="course__details__title">
              <Link to={'/kurs/' + this.props.course.slug}>
                {this.props.course.name}
              </Link>
            </h3>
            <div className="course__details__rest">
              <div className="course__statistics d-flex">
                <div className="cell lectures">{ lessonCount } {lessonCountSpell}</div>
                {/*<div className="cell hours">{totalCourseDurationInHours} {totalCourseDurationInHoursSpell}</div>*/}
                <div className="cell instructors">{instructorsCount} {instructorsSpell}</div>
              </div>
              {(!this.props.hidePrice) ? (
                <Fragment>
                  <div className="course__prices">
                    <span className={this.props.course.discountPrice ? 'old-price' : 'regular-price'}>{this.props.course.price} { ReactHtmlParser('&euro;') }</span>
                    {this.props.course.discountPrice ? (<span className="discount-price"> {this.props.course.discountPrice}  { ReactHtmlParser('&euro;') }</span>) : null }
                  </div>
                  <div className="course__prices-info">(cena je izražena sa PDV-om)</div>
                </Fragment>
              ) : ''}

              <div className="course__counter">
              { (!Number.isNaN(secondsForCountdown) && secondsForCountdown > 0) ? (
                <Fragment>
                  <h4 className="countdown__title">Kurs će biti dostupan za:</h4>
                  <Countdown seconds={secondsForCountdown}/>
                </Fragment>
              ) : null }
              </div>

              <div className="course__buttons">
                { courseBoxButtons }
              </div>

            </div>
          </div>
        ) : null}

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onJumpToLesson: () => dispatch(actions.jumpToLesson()),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CourseBox));