/************************************/
/* Reset password REDUCER functions */
/************************************/
import { updateObject } from '../../../shared/utility';

export const resetPasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    resetPasswordSent: false
  });
};
export const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    resetPasswordSent: true
  });
};
export const resetPasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const resetPasswordUnmount = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    resetPasswordSent: false
  });
};