import React, {Component} from 'react';
import Page from "../../hoc/Layouts/Page";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
    dataLayer: {
        page: 'Opšti uslovi poslovanja'
    },
    dataLayerName: 'PageDataLayer'
};

class ToS extends Component {

    componentDidMount() {
        TagManager.dataLayer(tagManagerArgs);
        ReactGA.pageview('uslovi-koriscenja');
    }

    render() {
        return (
            <Page title="Opšti uslovi poslovanja">

                <p><em>Važe od 1. 10. 2020.</em></p>
                <p>Homepage akademija je e-learning platforma privrednog društva Homepage doo Novi Sad (u daljem tekstu:
                    „Homepage“, „naš“, „nas“ ili „mi“). Kursevi Homepage akademije koncipirani su tako da se svaki od
                    njih sastoji od modula kao tematskih celina koje objedinjuju digitalni sadržaj čija se isporuka ne
                    vrši na trajnom nosaču zapisa, uključujući, ali ne ograničavajući se na video lekcije, zadatke i
                    praktične primere.</p>
                <p>U interesu je Homepage akademije i korisnika naših usluga (u daljem tekstu: „Polaznik“, „Vi“, „Vaš“
                    ili „Vas“) da Homepage pruža sve usluge po jasnim uslovima. Ovi opšti uslovi našeg poslovanja (u
                    daljem tekstu: „Opšti uslovi poslovanja”) predstavljaju osnovu na kojoj pružamo usluge obuke iz
                    marketinga, digitala, preduzetništva i srodnih oblasti, u vidu kurseva koji su proizvedeni i
                    isporučeni u digitalnom obliku i koji zajedno sa našom <a href={'/politika-privatnosti'}
                                                                              target={'_blank'}>Politikom
                        privatnosti</a> čine ugovor koji se
                    primenjuje na Vas. Korišćenjem internet stranice <a href={'/'}
                                                                          target={'_blank'}>https://homepageacademy.rs</a> i
                    kupovinom nekog od
                    naših kurseva, Vi u celini dajete svoju saglasnost na Opšte uslove poslovanja. Takođe, kupovinom
                    naših usluga, Vi potvrđujujete da ste pre zaključenja ugovora o pružanju usluga na daljinu u
                    elektronskom obliku, na odgovarajući način obavešteni o Opštim uslovima poslovanja, odnosno svim
                    okolnostima od značaja za donošenje odluke o stupanju u ugovor.</p>
                <p>Zadržavamo pravo da bez prethodnog obaveštavanja ažuriramo ove Opšte uslove poslovanja. U tom
                    slučaju, izmene i dopune stupaju na snagu danom objavljivanja na sajtu
                    <a href={'/'} target={'_blank'}>https://homepageacademy.rs</a></p>

                <h2>1. Osnovni podaci i pravni status</h2>

                <table className="table">
                    <tbody>
                    <tr>
                        <th scope="row">Poslovno ime:</th>
                        <td>HOMEPAGE DOO NOVI SAD</td>
                    </tr>
                    <tr>
                        <th scope="row">Pravna forma:</th>
                        <td>Društvo sa ograničenom odgovornošću</td>
                    </tr>
                    <tr>
                        <th scope="row">Sedište:</th>
                        <td>Ulica Kisačka 47, 21000 Novi Sad, Srbija</td>
                    </tr>
                    <tr>
                        <th scope="row">Matični broj:</th>
                        <td>20835648</td>
                    </tr>
                    <tr>
                        <th scope="row">PIB:</th>
                        <td>107609128</td>
                    </tr>
                    <tr>
                        <th scope="row">Telefon:</th>
                        <td>+381 62 193 93 93</td>
                    </tr>
                    <tr>
                        <th scope="row">e-mail:</th>
                        <td>
                            <a href={'mailto:office@homepage.rs'} target={'_blank'}>office@homepage.rs</a><br/>
                            <a href={'mailto:office@homepageacademy.rs'} target={'_blank'}>office@homepageacademy.rs</a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Web:</th>
                        <td>
                            <a href={'https://homepage.rs'} target={'_blank'}>https://homepage.rs</a><br/>
                            <a href={'/'} target={'_blank'}>https://homepageacademy.rs</a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2>2. Postupak kupovine kursa</h2>
                <p>Homepage akademija na svojoj zvaničnoj <a href={'/'} target={'_blank'}>web stranici</a> opisuje
                    pojedinačno svaki kurs u ponudi. Kursevi su proizvedeni i isporučuju se u digitalnom obliku.
                    Isporuka se ne vrši na trajnom nosaču zapisa.</p>
                <p>Odabirom željenog kursa, Vi imate izbor:</p>
                <ul className="list-check">
                    <li>da kupite kurs, klikom na dugme “KUPI ODMAH” nakon čega sledi registracija ili prijava ako ste
                        ranije izvršili registraciju unošenjem Vašeg email-a i lozinke. Detaljnije o podacima o ličnosti
                        koje prikupljamo od Vas, pogledajte u <a href={'/politika-privatnosti'} target={'_blank'}>Politici
                            privatnosti</a> koja zajedno sa ovim Opštim uslovima poslovanja čini ugovor koji se
                        primenjuje na Vas.
                    </li>
                    <li>da se informišete o sledećim detaljima kursa pre kupovine, klikom na dugme “POGLEDAJ”: naziv,
                        sadržaj i kratak opis, cena sa PDV-om, imena i broj predavača, broj modula i nazivi lekcija,
                        broj dana dostupnosti kursa na e-learning platformi, informacija o datumu početka kursa i link
                        ka besplatnoj promo lekciji koja je dostupna nakon registracije.
                    </li>
                </ul>
                <p>Poželjno je da imate profil na Facebook-u, radi upotrebe zatvorene Facebook grupe. Za pristup
                    lekcijama potreban Vam je računar ili mobilni telefon i internet konekcija. Kako bi isprobali
                    preporučene aplikacije, potrebno je da imate pametni mobilni telefon (Smartphone).</p>
                <p>Ugovor je sklopljen kada ste Vi prihvatili tačno određenu ponudu Homepage-a, koju je Homepage
                    objavio na svom web sajtu, uplatom cene kursa. Takva uplata smatra se konkludentnom radnjom prihvata
                    ponude od strane ponuđenog.</p>
                <p>Ugovor obavezuje Homepage pod uslovom da je cena kursa blagovremeno uplaćena, od momenta uplate. Pod
                    momentom uplate se smatra momenat u kom se novčani iznos celovite uplaćene cene pokazuje na
                    poslovnom računu Homepage-a.</p>

                <h2>3. Prodajna cena i isporuka</h2>
                <p>Cene svih kurseva istaknutih na internet stranici Homepage akademije, kao i u ponudama Homepage-a
                    iskazane su sa pripadajućim PDV-om. Cene se zaokružuju na dva decimalna mesta.</p>
                <p>Mogućnost kupovine kursa uz posebne cenovne pogodnosti i/ili uz posebne prodajne podsticaje (akcijska
                    prodaja), obavlja se u skladu sa uslovima koji su objavljeni na internet stranici
                    <a href={'/'} target={'_blank'}>https://homepageacademy.rs</a>.</p>
                <p>Isporuka kursa kao digitalnog sadržaja koji nije isporučen na trajnom nosaču zapisa se ima smatrati
                    izvršenom u celosti momentom registracije na e-learning platformi Homepage akademije i plaćanjem
                    cene kursa na osnovu čega moduli sa lekcijama postaju dostupni Polazniku za pregled i upotrebu. Za
                    potrebe ovih Opštih uslova poslovanja, izraz “<em>trajni nosač zapisa</em>” ima sledeće značenje:
                    svaki
                    instrument koji omogućava potrošaču ili trgovcu da sačuva podatke koji su namenjeni njemu lično, na
                    način da podaci ostanu dostupni za buduću upotrebu tokom razdoblja primerenog svrsi informacije koji
                    omogućava nepromenjenu reprodukciju sačuvanih podataka, kao što su papir, elektronska pošta, CD -
                    ROM, DVD, memorijska kartica i čvrsti disk (hard disk) računara.</p>


                <h2>4. Način plaćanja</h2>
                <p>Sve kurseve koji su istaknuti na internet stranici Homepage akademije, kao i u ponudama Homepage-a,
                    možete da platite na sledeće načine:</p>
                <p><strong>Uplatom na račun (fizička lica):</strong> Na šalteru pošte ili bilo koje poslovne banke putem
                    generisane opšte uplatnice.</p>
                <p><strong>Uplatom na račun (pravna lica):</strong> Ovaj način plaćanja važi isključivo za pravna lica.
                    Uplata se vrši na naš tekući račun po izdavanju profakture.</p>
                <p><strong>Plaćanje platnim karticama:</strong> Prihvatamo sve vodeće platne kartice domaćih i
                    inostranih banaka (Visa, Mastercard, Maestro ili American Express) koje su odobrene od strane banke
                    izdavaoca za online (Internet) plaćanje.</p>
                <p>Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD). Kurs evra se obračunava
                    u iznosu 120 dinara za 1 evro. Za informativni prikaz cena u drugim valutama koristi se srednji kurs
                    Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj
                    lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u
                    trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne
                    razlike od originalne cene navedene na našem sajtu.</p>
                <p>Prilikom unošenja podataka o platnoj kartici, poverljive informacije se prenose putem javne mreže u
                    zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije
                    kriptografske tehnologije.</p>
                <p>Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Banca Intesa a.d. Beograd,
                    pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o
                    platnoj kartici nisu dostupni našem sistemu, a posebno broj kartice, datum isticanja, CVC2 i CVV
                    broj.</p>
                <p>U slučaju povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili
                    u celosti, a bez obzira na razlog vraćanja, Homepage je u obavezi da povraćaj vrši isključivo preko
                    VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj
                    sredstava na račun korisnika kartice.</p>
                <p><strong>Mogućnosti odloženog plaćanja:</strong> Kurseve Homepage akademije moguće je kupiti na
                    odloženo plaćanje izborom jedne od ponuđenih opcija:</p>
                <ul className="list-check">
                    <li><strong>Beskamatni kredit Credit Agricole banke – 12 rata</strong><br/>Credit Agricole Srbija
                        a.d Novi Sad i Homepage Vam omogućavaju da sve kurseve Homepage akademije čija cena premašuje
                        50.000,00 dinara otplatite dinarskim beskamatnim kreditom do 12 mesečnih rata, bez učešća i bez
                        obzira u kojoj banci posedujete tekući račun, uz jednostavnu proceduru. Maksimalan iznos ovog
                        namenskog kredita je u visini pune cene pojedinačnog kursa.
                    </li>
                    <li><strong>Administrativna zabrana – 3 rate</strong><br/>Kupovina putem administrativne zabrane do
                        3 mesečne rate, bez kamate i bez limitiranja visine rate. Ova pogodnost plaćanja bilo kog kursa
                        Homepage akademije se odnosi na sve zaposlene, bez obzira na vrstu radnog odnosa. Ako je
                        korisnik kredita zaposlen na određeno vreme, zadnja rata mora da bude isplaćena najkasnije mesec
                        dana pre isteka ugovora o radu.
                    </li>
                </ul>
                <p>Zainteresovani za neku od pogodnosti odloženog plaćanja mogu se obratiti na mejl: <a
                    href={'mailto:office@homepageacademy.rs'} target={'_blank'}>office@homepageacademy.rs</a></p>

                <h2>5. Odgovornost za nesaobraznost</h2>
                <p>Mi smo dužni da Vam pružimo uslugu koja je saobrazna ugovorenoj.</p>
                <p>Usluga nije saobrazna ugovorenoj ako:</p>
                <ul className="list-check">
                    <li>po sadržini, kvalitetu i svrsi ne odgovara opisu koji smo pre zaključenja ugovora dali u
                        detaljima pojedinačnog kursa, klikom na dugme “POGLEDAJ” na internet stranici <a href={'/'}
                                                                                                         target={'_blank'}>https://homepageacademy.rs</a>
                    </li>
                    <li>ne odgovara opisu kursa koji smo dali u toku pružanja usluge pod uslovom da je to moglo da utiče
                        na Vaše odluke
                    </li>
                    <li>nema posebna svojstva koja ste Vi zahtevali, a koja su nama bila ili su morala biti poznata u
                        trenutku zaključenja ugovora
                    </li>
                    <li>nema redovna svojstva usluga iste vrste</li>
                    <li>ne odgovara očekivanjima koja su osnovana s obzirom na prirodu usluge i javna obećanja Homepage
                        akademije u pogledu posebnih svojstava usluge, a naročito ako su učinjena oglasom
                    </li>
                    <li>o sadržini, kvalitetu i svrsi ne odgovara opisu koji je pre zaključenja ugovora, oglasom ili na
                        drugi sličan način dalo treće lice u ime nas, a odnosi se na detalje pojedinačnog kursa, koji su
                        vidljivi klikom na dugme “POGLEDAJ” na internet stranici <a href={'/'}
                                                                                    target={'_blank'}>https://homepageacademy.rs</a>
                    </li>
                </ul>
                <p>Homepage nije odgovoran za nesaobraznost usluge ako:</p>
                <ul className="list-check">
                    <li>nije znao ili nije morao da zna da je treće lice u njegovo ime dalo opis iz st. 2. t. f) ovog
                        člana
                    </li>
                    <li>je opis iz st. 2. t. f) ovog člana na odgovarajući način blagovremeno ispravljen.</li>
                </ul>
                <p>Ako usluga nije saobrazna ugovorenoj, Polaznik može da zahteva od Homepage-a da izvrši saobraznu
                    uslugu.</p>
                <p>Ako je izvršenje saobrazne usluge nemoguće ili protivpravno ili predstavlja nesrazmerno opterećenje
                    za Homepage, Polaznik može zahtevati umanjenje cene ili raskid ugovora.</p>
                <p>Ako usluga nije saobrazna ugovorenoj, na prava Polaznika i odgovornost Homepage-a shodno se
                    primenjuju odredbe Zakona o zaštiti potrošača.</p>

                <h2>6. Odustanak od ugovora</h2>
                <p>Imajući u vidu da se isporuka pojedinačnog kursa kao digitalnog sadržaja ne vrši na trajnom nosaču
                    zapisa i da je usluga u potpunosti izvršena momentom registracije na e-learning platformi Homepage
                    akademije i plaćanjem cene kursa na osnovu čega moduli sa lekcijama postaju dostupni Polazniku za
                    pregled i upotrebu, Polaznik nema pravo da odustane od ugovora, u skladu sa čl. 37. st. 1. t. 1) i
                    t. 13) Zakona o zaštiti potrošača ("Sl. glasnik RS", br. 62/2014, 6/2016 - dr. zakon i 44/2018 - dr.
                    zakon).</p>
                <p>Osnovi za gubitak prava na odustanak su postavljeni alternativno i ne zahteva se njihovo kumulativno
                    ispunjenje.</p>
                <p>Pristupom, korišćenjem internet stranice <a href={'/'} target={'_blank'}>https://homepageacademy.rs</a> i klikom na dugme „PRIHVATAM“
                    mi pribavljamo:</p>
                <ul className="list-check">
                    <li>Vašu izričitu saglasnost kao potrošača sa odredbama ovih Opštih uslova poslovanja</li>
                    <li>Vašu izričitu saglasnost kao potrošača sa načinom isporuke</li>
                    <li>Vašu izričitu saglasnost kao potrošača i potvrdu da znate da gubite pravo na odustanak od
                        ugovora kada mi u potpunosti izvršimo ugovor
                    </li>
                    <li>Vašu izričitu saglasnost kao potrošača i potvrdu da znate da isporukom digitalnog sadržaja koji
                        se ne vrši na trajnom nosaču zapisa gubite pravo na odustanak od ugovora
                    </li>
                </ul>
                <p>Nakon registracije na e-learning platformu Homepage akademije, Vi ćete primiti email sa obaveštenjem
                    o zaključenju ugovora na daljinu, uključujući primerak ovih Opštih uslova poslovanja i ponovljeno
                    obaveštenje o gubitku prava na odustanak od ugovora. Uplatom cene kursa Vi potvrđujete da
                    prihvatanjem ugovora gubite pravo na odustanak od istog.</p>

                <h2>7. Završne odredbe</h2>
                <p>Ovi Opšti uslovi poslovanja su sastavljeni i sprovešće se u skladu sa propisima Republike Srbije. Na sva pitanja koja nisu uređena ovim Opštim uslovima poslovanja, primenjivaće se odredbe zakona kojima se uređuju obligacioni odnosi, zaštita potrošača i pružanje usluga informacionog društva.</p>
                <p>Ukoliko bilo koja odredba ovih Opštih uslova poslovanja postane delimično ili potpuno ništava, ostale odredbe ovih Opštih uslova poslovanja i dalje ostaju na snazi, proizvode pravno dejstvo i obavezujuće su. Ništave odredbe imaju se u skladu sa smislom i svrhom ovih Opštih uslova poslovanja zameniti odredbama koje će se po svom ekonomskom učinku približiti ništavim odredbama, koliko je to pravno moguće.</p>
                <p>Svaki eventualni spor u vezi sa važenjem i primenom Opštih uslova poslovanja se ima rešavati pred stvarno nadležnim sudom u Novom Sadu.</p>
                <br/>
                <p><em>Prihvatam i u potpunosti se slažem s Opštim uslovima poslovanja, koji su gore navedeni.</em></p>


            </Page>
        );
    }
}

export default ToS;