/******************************************/
/* Registration Confirm REDUCER functions */
/******************************************/
import { updateObject } from '../../../shared/utility';

export const userRegistrationConfirmStart = (state, action) => {
  return updateObject(state, {
    confirmedRegistration: false,
    error: null,
    loading: true
  });
};
export const userRegistrationConfirmSuccess = (state, action) => {
  return updateObject(state, {
    confirmedRegistration: true,
    error: null,
    loading: false
  });
};
export const userRegistrationConfirmFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};