import {updateObject} from "../../../shared/utility";

export const fetchInstructorsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchInstructorsSuccess = (state, action) => {
  return updateObject(state, {
    instructors: action.instructors,
    error: null,
    loading: false
  });
};
export const fetchInstructorsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};