import React from 'react';

const pageHeader = (props) => (
  <div className="request-course__header">
    <div className="container">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <p className="request-course__header__text">Ukoliko ti je potreban specifičan trening, za tebe, tvoje zaposlene ili organizaciju, kontaktiraj nas. Naš tim će na tvoj zahtev odgovoriti u najkraćem mogućem roku sa ponudom edukacije baš po tvojoj meri.</p>
        </div>
      </div>
    </div>
  </div>
);

export default pageHeader;