import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-kursevi.png";
import PageHeader from "../layout/PageHeader/PageHeader";
import IntroBox from "../layout/Courses/IntroBox";
import Spinner from "../layout/UI/Spinner/Spinner";
import {differenceFromToday} from "../../shared/utility";
import CoursePackageBox from "../layout/CoursePackageBox/CoursePackageBox";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
    dataLayer: {
        page: 'Svi paketi kurseva'
    },
    dataLayerName: 'PageDataLayer'
};

class CoursePackages extends Component {
    componentDidMount() {
        this.props.onFetchCoursePackages();
        TagManager.dataLayer(tagManagerArgs);
        ReactGA.pageview('paketi-kurseva');
    }
    
    render() {
        let coursePackages = <Spinner />;
        if (!this.props.loading) {
            let counter = 0;
            coursePackages = Object.values(this.props.coursePackages).map(coursePackage => {
                counter++;
                return (
                    <div
                        className={"col-md-6 col-xl-3 align-self-md-stretch align-self-auto pb-md-0 pb-2 mb-3" + ((counter % 3 === 1) && (counter > 1) ? ' offset-xl-3' : '')}
                        key={coursePackage.id}>
                        <CoursePackageBox
                            coursePackage={coursePackage}
                        />
                    </div>
                )
            });
            if (coursePackages.length === 0) {
                coursePackages = (
                    <div className="col-md-6 col-xl-9 d-flex align-items-center justify-content-center">
                        <h3 className="text-center">Nema aktivnih paketa kurseva</h3>
                    </div>
                );
            }
        }
        
        return (
            <Fragment>
                <Helmet>
                    <title>Paketi kurseva - Homepage Academy</title>
                    <meta name="description" content="Odaberi paket kurseva po svojoj meri" />
                    <meta property="og:title" content="Prijavi se" />
                    <meta property="og:description" content="Odaberi paket kurseva po svojoj meri" />
                    <meta property="og:image" content={FbShareImage} />
                </Helmet>
                <PageHeader subtitle={"Biraj znanje"} class={"packages"}>
                    Paketi kurseva
                </PageHeader>
                <section className="courses">
                    <div className="container">
                        <div className="row courses__list">
                            <div className="col-md-6 col-xl-3 align-self-md-stretch align-self-auto pb-md-0 mb-3 pb-2">
                                <IntroBox
                                    title={"Paketi kurseva"}
                                />
                            </div>
                            {coursePackages}
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.course.loading,
        coursePackages: state.course.coursePackages,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCoursePackages: () => dispatch(actions.fetchCoursePackages())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursePackages);