import * as actionTypes from '../../actions/actionTypes';

import { fetchBlogStart, fetchBlogSuccess, fetchBlogFail} from './blog';
import { fetchSingleBlogStart, fetchSingleBlogSuccess, fetchSingleBlogFail, singleBlogUnmount} from './singleBlog';

const initialState = {
  blog: [],
  singleBlog: [],
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_BLOG_START:
      return fetchBlogStart(state, action);
    case actionTypes.FETCH_BLOG_SUCCESS:
      return fetchBlogSuccess(state, action);
    case actionTypes.FETCH_BLOG_FAIL:
      return fetchBlogFail(state, action);

    case actionTypes.FETCH_SINGLE_BLOG_START:
      return fetchSingleBlogStart(state, action);
    case actionTypes.FETCH_SINGLE_BLOG_SUCCESS:
      return fetchSingleBlogSuccess(state, action);
    case actionTypes.FETCH_SINGLE_BLOG_FAIL:
      return fetchSingleBlogFail(state, action);
    case actionTypes.SINGLE_BLOG_UNMOUNT:
      return singleBlogUnmount(state, action);

    default:
      return state;
  }
};

export default reducer;