import React, {useState} from 'react';
import { Carousel } from "react-bootstrap";

const ImageCarousel = (props) => {
    const [index, setIndex] = useState(0);
    
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    
    const courseItemImages = Object.values(props.courses).map(course => {
        return (
            <Carousel.Item key={course.id}>
                <img
                    className="d-block w-100"
                    src={course.course.packageImage ?? course.course.image}
                    alt={course.course.name}
                />
            </Carousel.Item>
        );
    });
    
    const subtitle = props.subtitle ? <h5>{props.subtitle}</h5> : null;
    
    return (
        <Carousel className={`course-package__carousel ${props.color}`} activeIndex={index} onSelect={handleSelect} controls={false}>
            <Carousel.Item>
                <div className={'course-package__carousel__item course-package__carousel__item--main'}>
                    <img
                        className="d-block w-100"
                        src={props.image}
                        alt={props.title}
                    />
                    <Carousel.Caption className={'d-flex flex-column align-items-center justify-content-center'}>
                        <h3>{props.title}</h3>
                        {subtitle}
                    </Carousel.Caption>  
                </div>
            </Carousel.Item>
            {courseItemImages}
        </Carousel>
    );
};

export default ImageCarousel;