/************************************/
/* Change Profile REDUCER functions */
/************************************/
import { updateObject } from '../../../shared/utility';

export const changeProfileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    profileChanged: false,
  });
};
export const changeProfileSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    error: null,
    loading: false,
    profileChanged: true,
  });
};
export const changeProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const changeProfileUnmount = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profileChanged: false
  });
};