import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchSingleInstructorStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_START
  };
};
export const fetchSingleInstructorSuccess = (singleInstructor) => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_SUCCESS,
    singleInstructor: singleInstructor
  }
};
export const fetchSingleInstructorFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_FAIL,
    error: error
  }
};
export const fetchSingleInstructor = (slug) => {
  return dispatch => {
    dispatch(fetchSingleInstructorStart());
    const headers = getAxiosHeader();
    axios.get('/instructors/' + slug, headers)
      .then(res => {
        //console.log('fetchSingleInstructor', res.data.data);
        dispatch(fetchSingleInstructorSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchSingleInstructorFail(err));
      });
  }
};
export const singleInstructorUnmount = () => {
  return {
    type: actionTypes.SINGLE_INSTRUCTOR_UNMOUNT
  };
};


export const fetchSingleInstructorCoursesStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_START
  };
};
export const fetchSingleInstructorCoursesSuccess = (singleInstructorCourses) => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_SUCCESS,
    singleInstructorCourses: singleInstructorCourses
  }
};
export const fetchSingleInstructorCoursesFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_FAIL,
    error: error
  }
};
export const fetchSingleInstructorCourses = (slug) => {
  return dispatch => {
    dispatch(fetchSingleInstructorCoursesStart());
    const headers = getAxiosHeader();
    axios.get('/instructors/' + slug + '/courses', headers)
      .then(res => {
        //console.log('fetchSingleInstructorCourses', res.data.data);
        dispatch(fetchSingleInstructorCoursesSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchSingleInstructorCoursesFail(err));
      });
  }
};
export const singleInstructorCoursesUnmount = () => {
  return {
    type: actionTypes.SINGLE_INSTRUCTOR_COURSES_UNMOUNT
  };
};