import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";


/***************************
 FETCH TEST INFO
 **************************/
export const fetchTestInfoStart = () => {
  return {
    type: actionTypes.FETCH_TEST_INFO_START
  };
};
export const fetchTestInfoSuccess = (testInfo) => {
  return {
    type: actionTypes.FETCH_TEST_INFO_SUCCESS,
    testInfo: testInfo
  }
};
export const fetchTestInfoFail = (error) => {
  return {
    type: actionTypes.FETCH_TEST_INFO_FAIL,
    error: error
  }
};
export const fetchTestInfo = (slug) => {
  return dispatch => {
    dispatch(fetchTestInfoStart());
    const headers = getAxiosHeader();
    axios.get('/courses/' + slug + '/test-info', headers)
      .then(res => {
        //console.log('fetchTestInfoSuccess', res.data.data);
        dispatch(fetchTestInfoSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchTestInfoFail(err));
      });
  }
};
export const fetchTestInfoUnmount = () => {
  return {
    type: actionTypes.FETCH_TEST_INFO_UNMOUNT
  };
};