import React, {Component, Fragment} from 'react';

import CheckoutTotal from "../layout/UI/CheckoutTotal/CheckoutTotal";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "../layout/UI/Spinner/Spinner";
import { Button } from "react-bootstrap";
import {updateObject} from "../../shared/utility";


class CheckoutCard extends Component {
  state = {
      formSubmitted: false
  }
  
  componentWillUnmount() {
      this.props.onOrderUnmount();
  }

    rejectOrderHandler = (event) => {
      event.preventDefault();
      this.props.onRejectOrder(this.props.order.id);
      if (this.props.order.course) {
          this.props.history.push('/checkout/' + this.props.order.course.slug);   
      } else {
          this.props.history.push('/checkout/' + this.props.order.package.slug + '/package');
      }
  }
  
  cardPaymentHandler = (event) => {
      event.preventDefault();
      this.setState(updateObject(this.state, {formSubmitted: true}));
      document.forms["cardPayment"].submit();
  }

  render() {
    let content = <Spinner/>;
    let companyAdditionalDetails = null;
    let bankForm = null;
    if (this.props.orderSuccess && this.props.user && !this.state.formSubmitted) {
        
        if (this.props.orderType === 'legal') {
            companyAdditionalDetails = (
                <Fragment>
                    <p className={"checkout--card__data"}>{ this.props.order.orderDetails.mb }</p>
                    <p className={"checkout--card__data"}>{ this.props.order.orderDetails.pib }</p>
                </Fragment>
            );
        }
        
        bankForm = Object.keys(this.props.order.paymentInfo.data).map((key) => {
            return <input
                key={key}
                type={'hidden'}
                value={ this.props.order.paymentInfo.data[key] ?? '' }
                name={key}
            />;
        });
        content = (
            <Fragment>
                <h4 className={"checkout--card__subtitle checkout--card__icon checkout--card__icon--cart"}>Vaša porudžbina</h4>
                <h2 className={"checkout--card__product-title"}>{ this.props.order.course ? this.props.order.course.name : this.props.order.package.name }</h2>
                <hr className={"checkout--card__hr"} />

                <h4 className={"checkout--card__subtitle checkout--card__icon checkout--card__icon--user"}>Vaši podaci</h4>
                <p className={"checkout--card__data"}>{ this.props.orderType === 'legal' ? this.props.order.orderDetails.organization : this.props.order.orderDetails.name }</p>
                <p className={"checkout--card__data"}>{ this.props.order.orderDetails.country.name }</p>
                <p className={"checkout--card__data"}>{ this.props.order.orderDetails.address }</p>
                <p className={"checkout--card__data"}>{ this.props.order.orderDetails.postCode } { this.props.order.orderDetails.city }</p>
                { companyAdditionalDetails }
                <hr className={"checkout--card__hr"} />

                <CheckoutTotal
                    class={'w-50 checkout--card__price'}
                    productPrice={this.props.order.paymentInfo ? this.props.order.paymentInfo.data.amount : 0}
                    currency={'RSD'}
                />

                <div className={"checkout--card__info checkout--card__icon checkout--card__icon--info"}>
                    Klikom na dugme PLATI bićete preusmereni na stranu banke za unos podataka sa platne  kartice.
                </div>
                
                <form id={"cardPayment"} action={this.props.order.paymentInfo.url} method={"POST"}>
                    { bankForm}
                </form>
                
                <div className={"checkout--card__buttons w-100 d-flex justify-content-center"}>
                    <Button variant={'link'} onClick={this.rejectOrderHandler}>Nazad</Button>
                    <Button variant={'primary'} type="submit" onClick={this.cardPaymentHandler}>Plati</Button>
                </div>
            </Fragment>
        );
    }
      
    return (
      <section className="checkout checkout--card">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex align-items-center justify-content-center user-form">
              <div className="user-form__box">
                  { content }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
    return {
        orderSuccess: state.order.orderSuccess,
        order: state.order.order,
        user: state.auth.user,
        orderType: state.order.orderType,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onOrderUnmount: () => dispatch(actions.orderUnmount()),
        onRejectOrder: (orderId) => dispatch(actions.rejectOrder(orderId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCard);