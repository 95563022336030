import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
  order: [],
  loading: false,
  errorLegal: null,
  errorNatural: null,
  orderSuccess: false,
  orderType: null
}

export const naturalPersonOrderStart = (state, action) => {
  return updateObject(state, {
    errorNatural: null,
    loading: true,
    orderType: null
  });
};
export const naturalPersonOrderSuccess = (state, action) => {
  return updateObject(state, {
    order: action.order,
    errorNatural: null,
    loading: false,
    orderSuccess: true,
    orderType: 'natural'
  });
};
export const naturalPersonOrderFail = (state, action) => {
  return updateObject(state, {
    errorNatural: action.error,
    loading: false
  });
};

export const legalEntityOrderStart = (state, action) => {
  return updateObject(state, {
    errorLegal: null,
    loading: true,
    orderType: null
  });
};
export const legalEntityOrderSuccess = (state, action) => {
  return updateObject(state, {
    order: action.order,
    errorLegal: null,
    loading: false,
    orderSuccess: true,
    orderType: 'legal'
  });
};
export const legalEntityOrderFail = (state, action) => {
  return updateObject(state, {
    errorLegal: action.error,
    loading: false
  });
};

export const orderUnmount = (state, action) => {
  return updateObject(state, {
    order: [],
    errorLegal: null,
    errorNatural: null,
    loading: false,
    orderSuccess: false,
    orderType: null
  });
};

export const rejectOrderStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};
export const rejectOrderSuccess = (state, action) => {
  return updateObject(state, {
    order: [],
    errorLegal: null,
    errorNatural: null,
    loading: false,
    orderSuccess: false,
    orderType: null
  });
};
export const rejectOrderFail = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NATURAL_PERSON_ORDER_START:
      return naturalPersonOrderStart(state, action);
    case actionTypes.NATURAL_PERSON_ORDER_SUCCESS:
      return naturalPersonOrderSuccess(state, action);
    case actionTypes.NATURAL_PERSON_ORDER_FAIL:
      return naturalPersonOrderFail(state, action);

    case actionTypes.LEGAL_ORDER_START:
      return legalEntityOrderStart(state, action);
    case actionTypes.LEGAL_ORDER_SUCCESS:
      return legalEntityOrderSuccess(state, action);
    case actionTypes.LEGAL_ORDER_FAIL:
      return legalEntityOrderFail(state, action);

    case actionTypes.ORDER_UNMOUNT:
      return orderUnmount(state, action);

    case actionTypes.REJECT_ORDER_START:
      return rejectOrderStart(state, action);
    case actionTypes.REJECT_ORDER_SUCCESS:
      return rejectOrderSuccess(state, action);
    case actionTypes.REJECT_ORDER_FAIL:
      return rejectOrderFail(state, action);

    default:
      return state;
  }
};

export default reducer;