import React from 'react';
import { Link } from "react-router-dom";
import { formatDate } from '../../../shared/utility';
import ReactHtmlParser from 'react-html-parser';

const pageHeader = (props) => {
  const blogDate = new Date(props.singleBlog.createdAt);
  return(
    <div className="news-single-header">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Link className="news-single-header__backlink" to="/novosti">{ReactHtmlParser('&lt nazad na novosti')}</Link>
            <h1 className="news-single-header__title">{props.singleBlog.name}</h1>
            <p className="news-single-header__author">{props.singleBlog.author}</p>
            <p className="news-single-header__date">{formatDate(blogDate)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageHeader;