import React from 'react';

const CheckboxTos = (props) => {
    const tosAcceptedHandler = () => {
        props.changed();
    };
    return (
        <div className="checkbox-wrapper checkbox-wrapper--checkout-tos">
            <input
                className="mobile-centered"
                id={props.client + 'TosAccepted'}
                name={props.client + 'TosAccepted'}
                type="checkbox"
                value={props.checked}
                onChange={tosAcceptedHandler}
            />
            <label htmlFor={props.client + 'TosAccepted'}></label>
            <div className="checkbox-wrapper__span"><a href={"/uslovi-koriscenja"} target={"_blank"}>Prihvatam Opšte uslove poslovanja</a></div>
        </div>
    );
};

export default CheckboxTos;