import React, {Fragment} from 'react';
import Spinner from "../UI/Spinner/Spinner";
import HomepageNewsWidgetItemSmall from "./HomepageNewsWidgetItemSmall";
import HomepageNewsWidgetItemMain from "./HomepageNewsWidgetItemMain";

const homepageNewsWidget = (props) => {
  let blog = <Spinner />;

  if (!props.loading) {
    blog = <section className="news-widget">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            { props.blog[0] ? <HomepageNewsWidgetItemMain blog={props.blog[0]} /> : null}
          </div>
          <hr className="news-item__delimiter d-md-none" />
          <div className="col-lg-6 d-flex align-content-between flex-wrap">
            { props.blog[1] ? <HomepageNewsWidgetItemSmall blog={props.blog[1]} /> : null}
            { props.blog[2] ?
              <Fragment>
                <hr className="news-item__delimiter"/>
                <HomepageNewsWidgetItemSmall blog={props.blog[2]} />
              </Fragment>
              : null }
          </div>
        </div>
      </div>
    </section>;
    if (props.blog.length === 0) {
      blog = null;
    }
  }

  return blog;
}

export default homepageNewsWidget;