import {updateObject} from "../../../shared/utility";

export const fetchSingleInstructorCoursesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleInstructorCoursesSuccess = (state, action) => {
  return updateObject(state, {
    singleInstructorCourses: action.singleInstructorCourses,
    error: null,
    loading: false
  });
};
export const fetchSingleInstructorCoursesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const singleInstructorCoursesUnmount = (state, action) => {
  return updateObject(state, {
    singleInstructorCourses: [],
    error: null,
    loading: false
  });
};