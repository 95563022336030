import React, {Component, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import iconApproved from "../../../assets/images/icon-approved-small.png";
import {formatSecondsToHours, getHourString, getLessonString} from "../../../shared/utility";

class CoursePackageBox extends Component {

    handleGoToLesson = (e) => {
        e.preventDefault();
        this.props.onJumpToLesson();
        this.props.history.push('/kurs/' + this.props.course.slug);
    }
    
    render() {
        const lessonCount = this.props.course.lessonCount;
        const lessonCountSpell = getLessonString(lessonCount);
      
        const instructorsCount = this.props.course.instructors.length;
        const instructorsSpell = ((instructorsCount % 10) === 1) ? "predavač" : "predavača";

        const totalCourseDurationInHours = formatSecondsToHours(this.props.course.totalDuration);
        const totalCourseDurationInHoursSpell = getHourString(totalCourseDurationInHours);
        
        let coursePackageBoxButtons = null;

        if (this.props.course.approved) {
            coursePackageBoxButtons = (
                <a href="#" onClick={(event) => this.handleGoToLesson(event)} className="btn btn-secondary btn-goto">Pristupi kursu</a>
            );
        } else {
            coursePackageBoxButtons = <Link to={'/kurs/' + this.props.course.slug}
                                            className="btn btn-link">Pogledaj</Link>; 
        }

        return (
            <div className="course">
                {(this.props.course.approved) ? (
                    <img src={iconApproved} className="course__icon-approved" />
                ): null}
                <Link to={'/kurs/' + this.props.course.slug}>
                    <img className="course__img img-fluid"
                         ref={(courseImage) => {
                             this.courseImage = courseImage
                         }}
                         onLoad={this.handleImageLoaded}
                         src={this.props.course.image}
                         alt={this.props.course.name}/>
                </Link>

                <div className="course__details d-flex flex-column justify-content-between">
                    <h3 className="course__details__title">
                        <Link to={'/kurs/' + this.props.course.slug}>
                            {this.props.course.name}
                        </Link>
                    </h3>
                    <div className="course__details__rest">
                        <div className="course__statistics d-flex">
                            <div className="cell lectures">{lessonCount} {lessonCountSpell}</div>
                            <div className="cell hours">{totalCourseDurationInHours} {totalCourseDurationInHoursSpell}</div>
                            <div className="cell instructors">{instructorsCount} {instructorsSpell}</div>
                        </div>

                        <div className="course__buttons">
                            {coursePackageBoxButtons}
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onJumpToLesson: () => dispatch(actions.jumpToLesson())
    };
};

export default withRouter(connect(null, mapDispatchToProps)(CoursePackageBox));