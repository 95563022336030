import {updateObject} from "../../../shared/utility";

export const fetchPodcastsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchPodcastsSuccess = (state, action) => {
  return updateObject(state, {
    podcasts: action.podcasts,
    error: null,
    loading: false
  });
};
export const fetchPodcastsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};