import React, {Fragment} from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { formatDate } from '../../../../shared/utility';

const titleBlock = (props) => {
  const startDate = new Date(props.startDate);
  let instructors = null;
  if (props.instructors) {
    const numberOfInstructors = props.instructors.length;
    let counter = 0;
    instructors = Object.values(props.instructors).map(instructor => {
      counter++;
      const counterMod = counter % 4;
      return(
        <Fragment key={instructor.slug}>
          <div className={"course-header__author" + ((counterMod === 0 || numberOfInstructors == counter) ? " course-header__author--no-margin" : "") }>
            <i className="icon icon-circle-user">{ReactHtmlParser('&nbsp;')}</i>
            <a className="course-header__author__link" href={"/predavac/" + instructor.slug} target="_blank">{instructor.name}</a>
          </div>
          {(counterMod === 0) ? <div className="course-header__break-line"></div> : null}
        </Fragment>        
      );
    });
  }
  return(
    <Fragment>
      <Link className="course-header__backlink" to="/paketi-kurseva">{ReactHtmlParser('&lt')} nazad na pakete</Link>
      <h1 className="course-header__title">{props.title}</h1>
      <div className="course-header__subtitle">{ReactHtmlParser(props.description)}</div>
      <div className="course-header__authors d-md-flex justify-content-start flex-md-wrap">
        {instructors}
      </div>
      <div className="course-header__startdate"><i className="icon icon-calendar"></i> Paket je dostupan od {formatDate(startDate)}</div>
      { props.headerInfo ? (
        <div className="course-header__note">{ReactHtmlParser(props.headerInfo)}</div>
      ) : null }
    </Fragment>
  );
};

export default titleBlock;