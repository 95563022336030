import React, {Component, Fragment} from 'react';

import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {formatStateForForm, inputChangedFunction} from "../../shared/utility";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";
import PageHeader from "../layout/RequestCourse/PageHeader";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-kurs-na-zahtev.png";

const tagManagerArgs = {
  dataLayer: {
    page: 'Kurs na zahtev'
  },
  dataLayerName: 'PageDataLayer'
};

class Contact extends Component {
  state = {
    form: {
      name: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Ime i prezime'
        },
        value: '',
        validation: {
          required: true,
          minLength: 3
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      email: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      phone: {
        order: 2,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Broj telefona'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      organization: {
        order: 3,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Organizacija'
        },
        value: '',
        validation: {
          required: true,
          minLength: 3
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      personCount: {
        order: 4,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Broj osoba'
        },
        value: '',
        validation: {
          required: true,
          isNumeric: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      message: {
        order: 5,
        elementType: 'textarea',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'textarea',
          placeholder: 'Poruka',
          rows: 5
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  RequestCourseHandler = (event) => {
    event.preventDefault();
    const formData = {};
    for (let formElementIdentified in this.state.form) {
      formData[formElementIdentified] = this.state.form[formElementIdentified].value;
    }
    //console.log('RequestCourseHandler: ', formData);
    this.props.onRequestCourse(formData);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }
  
  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('kurs-na-zahtev');
  }

  componentWillUnmount() {
    this.props.onRequestCourseUnmount();
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);
    let form = (
      <form onSubmit={this.RequestCourseHandler}>
        <div className="row">
          {
            formElementsArray.map((el) => {
              const fieldWrapperClass = (el.config.elementType === 'textarea') ? 'col-12' : 'col-md-6';
              return (
                <div className={fieldWrapperClass} key={el.id}>
                  <Input
                    class={el.config.elementClasses}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    value={el.config.value}
                    invalid={!el.config.valid}
                    shouldValidate={el.config.validation}
                    touched={el.config.touched}
                    errorMessage={el.config.errorMessage}
                    changed={(event) => this.inputChangedHandler(event, el.id)}/>
                </div>
              )
            })
          }
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Button
              disabled={!this.state.formIsValid}
              btnType="btn-primary user-form__btn-submit"
              clicked={this.RequestCourseHandler}>Pošalji</Button>
          </div>
        </div>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    if (this.props.formSent) {
      form = <p className="user-form__message success text-center">Vaša zahtev za kursom je uspešno poslat administratoru akademije.</p>;
    }

    return (
      <Fragment>
        <Helmet>
          <title>Kurs na zahtev - Homepage Academy</title>
          <meta name="description" content="Kontaktiraj nas" />
          <meta property="og:title" content="Kurs na zahtev" />
          <meta property="og:description" content="Kontaktiraj nas" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>
        <PageHeader/>
        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex align-items-center justify-content-center request-course__form">
                <div className="request-course__form__box">
                  <h2 className="request-course__form__title">Kurs na zahtev</h2>
                  { form }
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.emailForm.loading,
    error: state.emailForm.error,
    formSent: state.emailForm.formSent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestCourse: (formData) => dispatch(actions.requestCourse(formData)),
    onRequestCourseUnmount: () => dispatch(actions.requestCourseUnmount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);