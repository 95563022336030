import React, {Component} from 'react';
import Page from "../../hoc/Layouts/Page";

class PrivacyPolicy extends Component {
    render() {
        return (
            <Page title="Politika privatnosti">
                <p>Homepage doo Novi Sad (u daljem tekstu: „Homepage“, „Rukovalac“ ili „mi“) ceni Vašu privatnost i
                    brine se o načinu na koji se postupa sa Vašim ličnim podacima. Kao Rukovalac, mi smo posvećeni
                    zakonitoj, poštenoj i transparentnoj obradi podataka o ličnosti u skladu sa Zakonom o zaštiti
                    podataka o ličnosti Republike Srbije i Opštom uredbom o zaštiti podataka o ličnosti (GDPR) Evropske
                    unije.</p>
                <p>Molimo Vas da pažljivo pročitate ovu Politiku privatnosti da biste u potpunosti razumeli koje to
                    podatke o ličnosti obrađujemo, u koje svrhe i po kom pravnom osnovu, kao i da biste se upoznali sa
                    Vašim pravima, načinom zaštite Vaših podataka i vremenskim periodom u kojem čuvamo Vaše lične
                    podatke.</p>

                <h2>1. Koje podatke o ličnosti prikupljamo od Vas?</h2>
                <p>Mi prikupljamo od Vas određene podatke tokom našeg poslovanja, uključujući podatke koje unosite sami,
                    podatke o Vašem učešću na kursevima, podatke o Vašoj upotrebi naše web stranice, kada nas
                    kontaktirate ili tražite informacije od nas i kada nas angažujete da Vam pružimo usluge. Takođe,
                    automatski prikupljamo neke podatke, poput informacija o Vašem uređaju i sa kojim vrstama naših
                    usluga komunicirate ili provodite vreme koristeći ih.</p>
                <p>Svrha obrade ličnih podataka zavisi od kategorije lica čije podatke prikupljamo i od vrste ličnih
                    podataka koji se obrađuju u konkretnom slučaju.</p>

                <h3>1.1. Lica u poslovnom odnosu sa nama</h3>
                <p>Da bismo ostvarili, upravljali i/ili održavali poslovni odnos sa Vama kao našim postojećim ili
                    potencijalnim klijentom i/ili poslovnim saradnikom, obrađujemo podatke kao što su: ime i prezime,
                    email adresa i broj telefona. Takođe, u svrhu sklapanja i izvršenja ugovora, mi obrađujemo podatke
                    radi naplate potraživanja u koje spadaju, pored navedenih, JMBG, adresa i podaci koji su neophodni
                    za sprovođenje sudskog ili vansudskog postupka, a koji se mogu odnositi na Vaše finansije,
                    zaposlenje, imovinu i slično.</p>

                <h3>1.2. Lica koja pohađaju kurseve na Homepage Academy</h3>
                <table className="table">
                    <tbody>
                    <tr>
                        <th scope="row">Podaci o nalogu</th>
                        <td>
                            Da biste koristili određene funkcije, kao što je upis na pojedinačni kurs, morate da
                            kreirate korisnički nalog. Kada kreirate ili ažurirate svoj nalog, mi prikupljamo podatke o
                            ličnosti koje dobrovoljno delite sa nama, poput email adrese, lozinke, pola i datuma
                            rođenja.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Podaci o profilu</th>
                        <td>
                            Vi odlučujete o dodatnim podacima o ličnosti koje dobrovoljno delite sa nama, kao što su
                            fotografija, link ka Vašoj web stranici, profili na socijalnim mreža i slično. Vaši podaci o
                            profilu će biti javno dostupni.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Deljeni sadržaj</th>
                        <td>
                            U određenom obimu, Vama će biti omogućena komunikacija sa drugim korisnicima, ili pristup
                            deljenom sadržaju, uključujući pravo da date ocenu na stranici kursa, da postavite pitanje
                            ili da na njega odgovorite, da pošaljete poruku polazniku kursa ili predavaču, da objavite
                            fotografiju ili drugi Vaš rad. Ovakvi deljeni sadržaji mogu da budu javni i vidljivi od
                            strane trećih lica.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Podaci o kursu</th>
                        <td>
                            Kada se prijavite i pohađate kurseve, mi prikupljamo određene podatke u vezi sa kursem, Vaše
                            zadatke, interakciju sa predavačem i drugim polaznicima kursa, eseje, odgovore na pitanja i
                            drugu stručnu komunikaciju.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Finansijski podaci u vezi sa plaćanjem kursa</th>
                        <td>
                            Kada plaćate kurs, mi prikupljamo određene podatke u vezi sa instrukcijama za plaćanje,
                            uključujući Vaše ime i prezime, broj računa, adresu i poštanski broj. Ukoliko plaćate
                            platnom karticom, Vi dostavljate određene podatke o plaćanju direktno našim partnerima za
                            obradu plaćanja. Mi ne prikupljamo i ne čuvamo osetljive podatke o vlasnicima kartica, kao
                            što su puni brojevi kreditnih kartica ili podaci za autentifikaciju kartice.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Komunikacija i podrška</th>
                        <td>
                            Ako nas kontaktirate za podršku ili u cilju prijavljivanja problema, bez obzira da li ste
                            kreirali nalog, mi prikupljamo Vaše kontakt podatke, poruke i druge podatke o ličnosti poput
                            Vašeg imena i prezimena, email adrese, lokacije, operativnog sistema, IP adrese i bilo kojih
                            drugih podataka koje dobrovoljno delite sa nama ili koje prikupljamo automatski. Ove podatke
                            o ličnosti koristimo da bismo Vam odgovorili na postavljeno pitanje i/ili zahtev.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h3>1.3. Lica koja posećuju našu web stranicu</h3>
                <p>Naše web stranice <a href="https://homepage.rs" target="_blank">https://homepage.rs</a> i <a
                    href="https://homepageacademy.rs" target="_blank">https://homepageacademy.rs</a> koriste Google
                    Analytics kolačić treće strane (third-party cookies). Kolačići su male datoteke koje se automatski
                    kreiraju od strane Vašeg pretraživača i čuvaju se na Vašem uređaju (računar, tablet, pametni
                    telefon ili slično) kada posetite našu web stranicu. Kolačići ne štete vašem uređaju i ne
                    sadrže viruse ili druge maliciozne programe.</p>
                <p><strong>Google Analytics:</strong> Mi koristimo Google Analytics za analiziranje podataka o
                    korišćenju naše web stranice. Usluge Google Analytics ne mogu da funkcionišu bez upotrebe kolačića
                    (cookies) koji omogućavaju statističku analizu korišćenja ove web stranice od strane posetilaca,
                    kao i prikaza sadržaja vezanog za korišćenje ili oglase. Google Analytics koristi podatke koji se
                    odnose na Vas, kao što su lokacija, pregledač (browser), IP adresa i koliko je puta web stranica
                    posećena, sve u cilju optimizacije naših usluga za Vas.</p>
                <p><strong>Kako da obrišete ili blokirate kolačiće:</strong> Vi možete da obrišete ili blokirate
                    kolačiće u bilo kom trenutku promenom konfiguracije pregledača. To će Vam omogućiti da sprečite
                    instalaciju nekih ili svih kolačića. Uputstva za upravljanje kolačićima, u zavisnosti od pregledača
                    koji koristite, možete pronaći ovde:</p>
                <ul>
                    <li><a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies"
                           target="_blank">Internet
                        Explorer (IE)</a></li>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a>
                    </li>
                    <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">Safari
                        (desktop)</a>
                    </li>
                    <li><a href="https://support.apple.com/en-us/HT201265" target="_blank">Safari (mobile)</a></li>
                    <li><a
                        href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                        target="_blank">Firefox</a></li>
                    <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a>
                    </li>
                    <li><a href="http://www.allaboutcookies.org/manage-cookies/netscape-6-plus.html"
                           target="_blank">Netscape</a>
                    </li>
                </ul>

                <p>Više infomacija o upravljanju podešavanjima kolačića možete pronaći na <a
                    href="http://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a></p>
                <p><strong>Kontakt forma/upiti:</strong> Kada nas kontaktirate putem kontakt forme <a
                    href="https://homepage.rs/#kontakt" target="_blank">https://homepage.rs/#kontakt</a>, <a
                    href="https://homepageacademy.rs/kontakt"
                    target="_blank">https://homepageacademy.rs/kontakt</a> ili <a
                    href="https://homepageacademy.rs/kurs-na-zahtev"
                    target="_blank">https://homepageacademy.rs/kurs-na-zahtev</a> mi prikupljamo Vaše podatke o ličnosti
                    (ime, organizacija, email, telefon i tekst Vaše poruke) kako bismo odgovorili na Vaš upit. Pravni
                    osnov za ovu vrstu obrade je naš legitimni interes da odgovorimo na Vaš upit. Vi nemate zakonsku
                    obavezu da nam dostavite navedene podatke, ali bez njih nećemo biti u mogućnosti da Vam pružimo
                    odgovarajući odgovor ili uslugu.</p>

                <h3>1.4. Lica koja posećuju naše stranice na društvenim mrežama</h3>
                <p>Mi smo aktivni na nekoliko društvenih mreža uključujući Facebook, Instagram, YouTube, Behance i
                    Linkedin.
                    Kada komunicirate sa nama na ovim mrežama, Vi stavljate na raspolaganje informacije i Vaše podatke,
                    na primer
                    kada odgovorite na naše objave, komentarišete ih ili podelite, kada nam dodelite „like“ ili nas
                    zapratite na
                    društvenim mrežama.</p>
                <p>Pored toga, obrađujemo standardne informacije poput identifikacionih podataka, kontakt informacija
                    ili Vaše
                    profilne fotografije. Nadalje, mi ćemo u nekim slučajevima deliti, na primer, vest u kojoj će Vaše
                    ime biti
                    uključeno. U takvim slučajevima uvek zahtevamo Vašu prethodnu saglasnost. Uvek možete da povučete
                    Vaš
                    pristanak tako što ćete nam se obratiti na: <a href="mailto:office@homepage.rs"
                                                                   target="_blank">office@homepage.rs</a> ili <a
                        href="mailto:office@homepageacademy.rs" target="_blank">office@homepageacademy.rs</a>. Svrha
                    obrade je brendiranje i marketing
                    Homepage-a.</p>
                <p>Pravni osnov za obradu je legitimni interes Homepage-a kao merketinške agencije na društvenim mrežama
                    za
                    deljenjem znanja u formi deljenja članaka i vesti, u skladu sa Članom 6(1)(f) GDPR-a.</p>
                <p>Kada koristite Facebook, Facebook koristi informacije prikupljene sa Homepage Facebook stranice. U
                    tom
                    slučaju, Homepage i Facebook se smatraju zajedničkim rukovaocima koji obrađuju Vaše podatke o
                    ličnosti. Više o
                    zajedničkim rukovaocima možete pročitati <a
                        href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank">ovde</a>.
                </p>
                <p>Informacije na društvenim mrežama brišu se kada Homepage obriše objavu, ili kada Vi obrišete Vaš
                    komentar,
                    podelu, reakciju ili indikaciju da ste dodelili „like“ ili da pratite Homepage.</p>
                <p><strong>YouTube video zapisi:</strong> Naša web stranica sadrži linkove ka video zapisima na
                    YouTube LLC, 901 Cherry Ave,
                    San Bruno, CA 94066, USA. Prilikom posete takvom sadržaju na našoj web stranici, uspostavlja se
                    direktna veza
                    između Vašeg pretraživača i YouTube servera nakon što ste aktivirali video. YouTube prima
                    informaciju da ste
                    posetili Homepage web stranicu sa Vaše IP adrese. Više informacija o sadržaju prenetih podataka ili
                    o njihovoj
                    upotrebi od strane YouTube možete pročitati <a href="https://policies.google.com/privacy"
                                                                   target="_blank">ovde</a>.</p>

                <h3>1.5. Lica zaposlena ili na drugi način radno angažovana kod nas</h3>
                <p>Mi obrađujemo podatke o ličnosti zaposlenih ili na drugi način radno angažovanih u Homepage poput
                    imena i
                    prezimena, kontakt podataka, nivoa obrazovanja i slično, u svrhu zasnivanja radnog odnosa ili drugog
                    oblika
                    radnog angažovanja, za upravljanje zaposlenima, vođenje evidencije o zaposlenima i njihovoj zaradi.
                    Pravni
                    osnov za prikupljanje i obradu ovih podataka o ličnosti je zakon.</p>
                <p><strong>Lica koja apliciraju za rad:</strong> Ukoliko ste kandidat za posao, mi prikupljamo i
                    obrađujemo Vaše lične podatke u
                    cilju uspostavljanja potencijalne saradnje i to: ime i prezime, adresa, broj telefona, stepen i
                    vrsta
                    obrazovanja, prethodno radno iskustvo i slično. Ove podatke prikupljamo direktno od Vas kada nam
                    dostavite
                    svoju radnu biografiju (CV) i/ili motivaciono pismo. Pravni osnov za ovu vrstu obrade je naš
                    legitimni interes
                    za sprovođenje procesa selekcije a radi popunjavanja otvorenih pozicija.</p>

                <h3>1.6. Lica koja pristupaju našim prostorijama</h3>
                <p>Ukoliko pristupate našim prostorijama, Vaš ulazak i boravak će biti zabeleženi putem sistema video
                    nadzora.</p>

                <h3>1.7. Lica uključena u promotivne aktivnosti</h3>
                <p>Ako ste uključeni u naše promotivne aktivnosti, primate naš promotivni materijal ili prisustvujete
                    događajima
                    koje organizujemo, možemo da obrađujemo podatke o ličnosti poput imena i prezimena, email adrese,
                    broja
                    telefona, pozicije na kojoj radite, fotografije ili video zapisa. Pravni osnov za ovu vrstu obrade
                    je naš
                    legitimni interes za promociju i druge marketinške ciljeve.</p>

                <h2>2. Koliko dugo čuvamo Vaše podatke</h2>
                <p>Vaše podatke o ličnosti čuvamo u roku neophodnom za ostvarivanje svrhe u koju su prikupljeni.
                    Izuzetno, u
                    obavezi smo da Vaše podatke o ličnosti čuvamo i po prestanku postojanja svrhe obrade radi poštovanja
                    naših
                    ugovornih obaveza, ostvarivanja legitimnog interesa Homepage-a, ispunjavanja obaveza definisanih
                    pozitivnopravnim propisima Republike Srbije i na osnovu Vašeg pristanka.</p>

                <h2>3. Sa kim delimo Vaše lične podatke</h2>
                <p>Mi cenimo Vašu privatnost i preduzimamo odgovarajuće mere zaštite Vaših ličnih podataka koje
                    prikupljamo i
                    obrađujemo. Ukoliko imamo potrebu za tim, Vaše lične podatke možemo da delimo sa određenim pouzdanim
                    trećim
                    licima u skladu sa zaključenim ugovorima, uključujući:</p>

                <ul className="list-check">
                    <li><strong>Vaše predavače:</strong> Mi delimo određene podatke o Vama (osim email adrese) sa
                        predavačima ili njihovim asistentima
                        u nastavi kurseva koje ste upisali, ili o kojima zatražite informacije, kako bismo mogli da
                        poboljšamo kvalitet
                        kurseva. Ovi podaci o ličnosti uključuju grad, državu, jezik pregledača, operativni sistem,
                        podešavanja uređaja,
                        Vašu web lokaciju i aktivnosti. Vašu email adresu nećemo deliti.
                    </li>
                    <li><strong>Druge predavače i polaznike kursa:</strong> U zavisnosti od Vaših podešavanja, Vaši
                        deljeni sadržaji i podaci profila
                        mogu biti javno vidljivi, uključujući ostale polaznike kursa i predavače. Ako postavite pitanje
                        Predavaču, Vaši
                        podaci (uključujući Vaše ime) mogu takođe biti javno vidljivi od strane drugih korisnika u
                        zavisnosti od Vaših
                        postavki.
                    </li>
                    <li><strong>Eksterne saradnike:</strong> Mi delimo Vaše podatke o ličnosti onim saradnicima kojima
                        smo poverili vršenje pojedinih
                        radnji obrade na osnovu zaključenih ugovora, uključujući usluge elektronske pošte i hostinga,
                        obrade plaćanja,
                        analize podataka, marketinške i reklamne usluge (uključujući retargeted oglašavanje), advokate,
                        poslovne banke,
                        kao i druge saradnike koji nam pružaju usluge slanja i dostave pošiljku, arhiviranja,
                        telekomunikacionih usluga,
                        IT podrške. Eksterni saradnici mogu da imaju svojstvo rukovaoca, zajedničkog rukovaoca,
                        obrađivača,
                        podobrađivača, primaoca ili treće strane.
                    </li>
                    <li><strong>Organe vlasti:</strong> U cilju sigurnosti i usklađivanja sa propisima, mi delimo
                        određene podatke o Vama državnim
                        organima, organima teritorijalne autonomije i jedinice lokalne samouprave, javnim preduzećima,
                        ustanovama i
                        drugim javnim službama, organizacijama i drugim pravnim ili fizičkim licima koja vrše javna
                        ovlašćenja.
                    </li>
                </ul>

                <p><strong>Prenos Vaših podataka o ličnosti u druge države:</strong> Da bismo pružili naše usluge u
                    skladu sa ovom Politikom privatnosti, u nekim slučajevima postoji potreba da prenesemo Vaše podatke
                    o ličnosti van teritorije Republike Srbije, na primer u države koje su obezbedile odgovarajući
                    stepen zaštite podataka o ličnosti (države Evropskog ekonomskog prostora, engl. European Economic
                    Area - EEA), ili izvan EEA, na lokaciju koja primenjuje odgovarajuće zaštitne mere, u skladu sa
                    GDPR, poput Sjedinjenih Američkih Država, u kom slučaju se na primaoca primenjuje EU/US Privacy
                    Shield.
                </p>

                <h2>4. Vaša prava</h2>
                <p>Mi želimo da budemo sigurni da ste u potpunosti svesni svojih prava u vezi sa zaštitom ličnih
                    podataka. Zakon o
                    zaštiti podataka o ličnosti Republike Srbije i Opšta uredba o zaštiti podataka o ličnosti (GDPR)
                    Evropske unije
                    Vam garantuju određena prava.</p>

                <p>Imate pravo da se informišete o podacima koje obrađujemo i kako ih obrađujemo, kao i pravo da
                    zahtevate uvid i
                    njihovu kopiju. Takođe, imate pravo da ograničite našu obradu, da zahtevate ispravku, dopunu i
                    ažuriranje
                    ukoliko su podaci nepotupni ili netačni, pravo da zahtevate brisanje podataka nakon isteka
                    predviđenog perioda
                    čuvanja i pravo da dobijete podatke o ličnosti koje ste nam prethodno dostavili kao i da zahtevate
                    da ih
                    prenesemo drugom rukovaocu. U svakom trenutku možete da prigovorite nakon čega ćemo obustaviti dalju
                    obradu
                    Vaših podataka i pravo da povučete svoj pristanak koji ste nam prethodno dali.</p>

                <p>Ukoliko smatrate da su Vaša prava na zaštitu podataka o ličnosti povređena, možete se pritužbom
                    obratiti
                    Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti: <a
                        href="https://www.poverenik.rs" target="_blank">https://www.poverenik.rs</a></p>

                <p>Ako podnesete zahtev, imamo mesec dana da Vam odgovorimo. Ako želite da koristite bilo koje od
                    navedenih prava,
                    obratite nam se na: <a href="mailto:office@homepage.rs" target="_blank">office@homepage.rs</a>.</p>

                <h2>5. Budimo u kontaktu</h2>
                <p>Ukoliko imate dodatna pitanja u vezi sa našom Politikom privatnosti ili podacima o ličnosti koje
                    prikupljamo i obrađujemo, molimo Vas da nam se obratite na: <a href="mailto:office@homepage.rs"
                                                                                   target="_blank">office@homepage.rs</a>.
                </p>
            </Page>
        );
    }
}

export default PrivacyPolicy;