import React from 'react';

const intro = (props) => (
  <section className="instructors-intro">
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex align-items-start align-items-md-center justify-content-center flex-column">
          <h2 className="instructors-intro__title title">I znanje i iskustvo</h2>
          <div className="instructors-intro__text text-md-center">
            <p>Svi predavači Homepage akademije su profesionalci koji su svoje ekspertize dokazali u praksi!</p>
            <p>Osim predavača koji su deo Homepage Creative. Digital. agencije, gostujući predavači i predavači iz partnerskih organizacija takođe su stručnjaci koji su svoja znanja dokazali u praktičnom radu kroz višegodišnje iskustvo i respektabilne projekte. Oni nesebično prenose svoja znanja kako bi zajednica profesionalaca rasla i kako bi poslovno okruženje bilo što pozitivnije za sve nas.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default intro;