import {updateObject} from "../../../shared/utility";

export const fetchSingleLessonSharedStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleLessonSharedSuccess = (state, action) => {
  return updateObject(state, {
    lesson: action.lesson,
    error: null,
    loading: false
  });
};
export const fetchSingleLessonSharedFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const singleLessonSharedUnmount = (state, action) => {
  return updateObject(state, {
    lesson: [],
    error: null,
    loading: false
  });
};