import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const fetchSingleLessonSharedStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_SHARED_START
  };
};
export const fetchSingleLessonSharedSuccess = (lesson) => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_SHARED_SUCCESS,
    lesson: lesson
  }
};
export const fetchSingleLessonSharedFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_SHARED_FAIL,
    error: error
  }
};
export const fetchSingleLessonShared = (slug) => {
  return dispatch => {
    dispatch(fetchSingleLessonSharedStart());
    const headers = getAxiosHeader();
    axios.get('/lessons-shared/' + slug, headers)
    .then(res => {
      //console.log('fetchSingleLessonShared', res.data.data);
      dispatch(fetchSingleLessonSharedSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('fetchSingleLessonShared error', err);
      dispatch(fetchSingleLessonSharedFail(err));
    });
  }
};

export const singleLessonSharedUnmount = () => {
  return {
    type: actionTypes.SINGLE_LESSON_UNMOUNT
  };
};