import React, {Component} from 'react';
import {connect} from "react-redux";

import UserMenuItems from "../../components/layout/UserMenuItems/UserMenuItems";

class UserAccount extends Component {
  render() {
    const nameInitial = this.props.user.name ? this.props.user.name.charAt(0) : '';

    return (
      <section className="user-account">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-10 offset-lg-1">
              <div className="user-account__box">
                <div className="row no-gutters">
                  <div className="col-lg-4 col-xl-3 user-account__sidebar">
                    <div className="user-account__user">
                      <div className="user-account__user__avatar">{nameInitial}</div>
                      <div className="user-account__user__name">{this.props.user.name}</div>
                    </div>
                    <div className="user-account__navigation">
                      <UserMenuItems/>
                    </div>
                  </div>
                  <div className="col-lg-8 col-xl-9 user-account__main">{this.props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: {
      name: state.auth.user.name,
      email: state.auth.user.email
    }
  };
};

export default connect(mapStateToProps)(UserAccount);