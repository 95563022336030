import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

const LessonPlayer = (props) => {
  const lessonEndVideoHandler = () => {
    props.watched(props.lessonSlug);
    props.nextLesson(props.lessonSlug);
  };
  return (
    <div className="lesson-player__box embed-responsive embed-responsive-16by9">
      <Vimeo
        autoplay
        video={props.videoId}
        onEnd={lessonEndVideoHandler} />
    </div>
  );
};

export default LessonPlayer;