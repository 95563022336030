import React, {Component} from 'react';
import LogoBancaIntesa from "../../../assets/images/logo-banca-intesa.png";
import LogoMasterCardSecureCode from "../../../assets/images/logo-mastercard-secure-code.png";
import LogoVerifiedByVisa from "../../../assets/images/logo-verified-by-visa-blue.png";
import LogoZnaciPrihvatanja from "../../../assets/images/logo-znaci-prihvatanja.png";

class FooterBank extends Component {
  render() {
    return (
      <div className="footer-bank">
        <div className="container">
          <div className="row">
              <div className="col-sm-4 col-lg-5 d-flex justify-content-center justify-content-sm-end align-items-center footer-bank__column">
                  <a href={"https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"} target={"_blank"} className={"footer-bank__link"}>
                    <img src={LogoVerifiedByVisa} alt={"Verified by VISA"} />
                  </a>
                  <a href={"http://www.mastercard.com/rs/consumer/credit-cards.html"} target={"_blank"} className={"footer-bank__link footer-bank__link--mastercard-secure-code"}>
                    <img src={LogoMasterCardSecureCode} alt={"MasterCard Secure Code"} />
                  </a>
              </div>
              <div className="col-sm-4 col-lg-2 d-flex justify-content-center align-items-center footer-bank__column">
                  <a href={"https://www.bancaintesa.rs"} target={"_blank"} className={"footer-bank__link footer-bank__link--banca-intesa"}>
                      <img src={LogoBancaIntesa} alt={"Banka Intesa"} />
                  </a>
              </div>
              <div className="col-sm-4 col-lg-5 d-flex justify-content-center justify-content-sm-start align-items-center footer-bank__column">
                  <img src={LogoZnaciPrihvatanja} alt={''} className={"footer-bank__img"} />
              </div>
          </div>
        </div>
      </div>
    );
  }
}


export default FooterBank;