/********************************************/
/* Reset password confirm REDUCER functions */
/********************************************/
import { updateObject } from '../../../shared/utility';

export const resetPasswordConfirmStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    resetPasswordConfirmed: false
  });
};
export const resetPasswordConfirmSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    resetPasswordConfirmed: true
  });
};
export const resetPasswordConfirmFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};