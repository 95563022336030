import React from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { formatDate } from '../../../shared/utility';
import SocialShare from '../UI/SocialShare/SocialShare';

const podcastItem = (props) => {
  const podcastDate = new Date(props.podcast.createdAt);
  const podcastUrl = process.env.PUBLIC_URL + "/novosti/" + props.podcast.slug;
  return (
    <div className={"podcast-item podcast-item-" + props.podcast.id}>
        <div className="podcast-item__img-box" style={{backgroundImage: "url(" + props.podcast.image + ")"}}>
          <Link className="podcast-item__img-box__link" to={"/podkast/" + props.podcast.slug}>{ReactHtmlParser('&nbsp;')}</Link>
        </div>
        <div className="podcast-item__details">
          <h3 className="podcast-item__title">
            <Link to={"/podkast/" + props.podcast.slug}>{props.podcast.name}</Link>
          </h3>
          <div className="podcast-item__text">{props.podcast.shortDescription}</div>
          <div className="podcast-item__footer d-flex align-items-center justify-content-between">
            <div className="podcast-item__date">{formatDate(podcastDate)}</div>
            <SocialShare
              url={podcastUrl}
              title={props.podcast.name}
              showLabel={false}
            />
          </div>
        </div>
    </div>
  );
};

export default podcastItem;