import React, {Component, Fragment} from 'react';
import { Link } from "react-router-dom";
import ModalBox from "../layout/UI/ModalBox/ModalBox";
import HeaderTestIntro from "../layout/Header/HeaderTestIntro";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {lessonUrlGenerator} from "../../shared/utility";
import ReactHtmlParser from "react-html-parser";

class TestIntro extends Component {
  state = {
    showModal: false
  }
  
  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  componentDidMount() {
    const courseSlug = this.props.match.params.course;
    this.props.onFetchTestInfo(courseSlug);
    this.props.onFetchTestAttempts(courseSlug);
    this.props.onFetchPaidSlots(courseSlug);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.testAttempts !== this.props.testAttempts && this.props.testAttempts.hasInProgress) {
      this.props.history.push(lessonUrlGenerator(this.props.match.params.course, this.props.match.params.lesson));
    }
  }

  handleModalSubmit = () => {
    this.props.history.push('/test/' + this.props.match.params.course + '/' + this.props.match.params.lesson);
  };

  render() {
    let mainIntroText = null;
    if (!this.props.testLoading) {
      mainIntroText = this.props.testInfo.intro ? ReactHtmlParser(this.props.testInfo.intro) : (
          <Fragment>
            <p><strong>Za dobijanje sertifikata o završenom kursu "<Link to={lessonUrlGenerator(this.props.match.params.course, this.props.match.params.lesson)}>{this.props.testInfo.courseName}</Link>", potrebno je da položiš test sa više od {this.props.testInfo.passingScorePercent}% tačnih odgovora.</strong></p>
            <h3 className="subtitle">O testu</h3>
            <p>Test se sastoji od <strong>30 pitanja</strong>, po jedno pitanje iz svih 30 modula koliko ih ima u kursu "<Link to={lessonUrlGenerator(this.props.match.params.course, this.props.match.params.lesson)}>{this.props.testInfo.courseName}</Link>". Za testiranje ti je na raspolaganju <strong>40 minuta</strong>.</p>
            <p>Po završenom testu, imaćeš informaciju o procentu tačnosti odgovora (za uspešno položen test je <strong>potrebno da imaš više od {this.props.testInfo.passingScorePercent}% tačnih odgovora</strong>), broju ukupnih tačnih odgovara, kao i koliko još besplatnih pokušaja imaš za polaganje testa i dobijanje sertifikata.</p>
            <p>Po uspešnom testiranju svoj sertifikat možeš preuzeti odmah, a biće ti dostavljen i mejlom na adresu kojom si se registrovao/la na Homepage Academy platformu.</p>
          </Fragment>
      );
    }

    let messageIntroVariableText = null;
    if(!this.props.testLoading && !this.props.testInfo.free) {
      switch (this.props.numberOfRemainingFreeAttempts) {
        case 0 :
          messageIntroVariableText = null;
          break;
        case 1 :
          messageIntroVariableText = (
            <Fragment>
              <h3 className="subtitle">Imaš još jedan besplatan pokušaj</h3>
              <p>Ovo je tvoj poslednji pokušaj da besplatno testiraš svoje znanje. Pre pokretanja testa, potrudi se da odslušaš sve lekcije, a zatim da odgovoriš tačno na što više pitanja. Ukoliko i ovaj pokušaj testiranja bude neuspešan, svaki naredni se naplaćuje u visini od 10% od cene kursa.</p>
            </Fragment>
          );
          break;
        default:
          messageIntroVariableText = (
            <Fragment>
              <h3 className="subtitle">Dva besplatna pokušaja</h3>
              <p>Na raspolaganju su ti dva besplatna pokušaja da testiraš svoje znanje. Potrudi se da, pre pokretanja testa, odslušaš sve lekcije i odgovoriš tačno na što više pitanja. Nakon <strong>dva neuspela pokušaja</strong> testiranja, svaki naredni se naplaćuje u visini od <strong>10% od cene kursa</strong>.</p>
            </Fragment>
          );
          break;
      }
    }
    return (
      <Fragment>
        <HeaderTestIntro courseSlug={this.props.match.params.course} lessonSlug={this.props.match.params.lesson} />
        <section className="test-intro">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="title">Testiraj svoje znanje</h1>
              </div>
              <div className="col-lg-6 offset-lg-3">
                {mainIntroText}
                {messageIntroVariableText}
                <a href="#" className="btn btn-lg btn-primary btn--start-test" onClick={this.handleShowModal}>Započni test</a>
              </div>
            </div>
          </div>
        </section>
        <ModalBox
          subtitle={null}
          title="Da li si siguran/na da želiš da započneš test?"
          show={this.state.showModal}
          close={this.handleCloseModal}
          submit={this.handleModalSubmit}
          showCloseIcon={true}>
        </ModalBox>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    testLoading: state.test.loading,
    testInfo: state.test.testInfo,
    testAttempts: state.test.testAttempts,
    paidSlots: state.test.paidSlots,
    numberOfRemainingFreeAttempts: state.test.testInfo.numberOfFreeAttempts - state.test.testAttempts.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchTestInfo: (slug) => dispatch(actions.fetchTestInfo(slug)),
    onFetchTestAttempts: (slug) => dispatch(actions.fetchTestAttempts(slug)),
    onFetchPaidSlots: (slug) => dispatch(actions.fetchPaidSlots(slug)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestIntro);