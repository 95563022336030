import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const pageHeader = (props) => {
  const subtitle = (props.subtitle) ? ReactHtmlParser('<h3 class="page-header__subtitle">' + props.subtitle + '</h3>') : null;
  const additionalClass = (props.class) ? ' page-header--' + props.class : '';
  return (
    <div className={"page-header" + additionalClass}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-1">
            <h1 className="page-header__title">{props.children}</h1>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
}

export default pageHeader;