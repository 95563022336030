import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Link} from "react-router-dom";

const pageHeader = (props) => (
  <div className="page-header page-header--instructor">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
          <Link className="page-header__backlink" to="/predavaci">{ReactHtmlParser('&lt nazad na predavače')}</Link>
          <h1 className="page-header__title">{props.instructor.name}</h1>
          <h3 className="page-header__subtitle">{props.instructor.title} {props.instructor.company ? '@' : ''} {props.instructor.company}</h3>
          <img
            className="page-header__avatar"
            src={ props.instructor.image }
            alt={ props.instructor.name } />
        </div>
      </div>
    </div>
  </div>
);

export default pageHeader;