import React from 'react';
import { Accordion, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import {formatTime, getLessonString} from "../../../shared/utility";

const modulesAndLessonsAccordion = (props) => {
  let activeModuleKey = 0;
  
  const items = Object.keys(props.courseModules).map(key => {
    const lessonWatchedChangeHandler = (e) => {
      e.preventDefault();
      //console.log('lessonWatchedChangeHandler', e.target.value);
      props.watched(e.target.value);
    };
    
    const lessonCount = props.courseModules[key]['lessons'].length;
    const lessonsSpell = getLessonString(lessonCount);
    let moduleDuration = 0;

    const lessons = Object.keys(props.courseModules[key]['lessons']).map(lkey => {
      const lessonSlug = props.courseModules[key]['lessons'][lkey]['slug'];
      
      let activeLessonClass = '';
      if (props.currentLesson === props.courseModules[key]['lessons'][lkey]['id']) {
        activeLessonClass = ' active';
        activeModuleKey = key;
      }
      
      const lessonDuration = formatTime(props.courseModules[key]['lessons'][lkey]['duration']);
      moduleDuration += props.courseModules[key]['lessons'][lkey]['duration'];
      //console.log(lessonSlug + ': ', lessonDuration, moduleDuration);
      return(
        ( props.courseModules[key]['lessons'][lkey]['free'] ||
          (props.courseApproved && props.courseStarted)
        ) ? (
          <div className="card-body__lesson d-flex" key={lkey}>
            <div className="card-body__lesson__checkbox checkbox-wrapper">
              <input
                id={"courseWathed-" + (parseInt(key)+1) + "-" + (parseInt(lkey)+1)}
                name={"courseWathed-" + (parseInt(key)+1) + "-" + (parseInt(lkey)+1)}
                type="checkbox"
                checked={props.courseModules[key]['lessons'][lkey]['meta']['watched']}
                disabled={props.courseModules[key]['lessons'][lkey]['meta']['watched']}
                value={lessonSlug}
                onChange={(event) => lessonWatchedChangeHandler(event)} />
              <label htmlFor={"courseWathed-" + (parseInt(key)+1) + "-" + (parseInt(lkey)+1)}></label>
            </div>
            <div className={"card-body__lesson__number" + activeLessonClass}>{parseInt(lkey)+1}.</div>
            <div className="card-body__lesson__data d-flex flex-column">
              <h4 className={"card-body__lesson__title" + activeLessonClass}>
                <Link to={"/kurs/" + props.courseSlug + "/lekcija/" + lessonSlug}>
                  {props.courseModules[key]['lessons'][lkey]['name']}
                </Link>
              </h4>
              <div className={"card-body__lesson__time" + activeLessonClass}><i className="icon icon-circle-time"></i> {lessonDuration}</div>
            </div>
          </div>
        ) : null
      )
    });
    
    return (
      <Card key={key}>
        <Accordion.Toggle as={Card.Header} eventKey={"module-" + key}>
          <h3 className="card-header__title">{parseInt(key) > 0 ? parseInt(key) + '.' : ''} {props.courseModules[key]['name']}</h3>
          <div className="card-header__details d-flex">
            <div className="card-header__detail">{lessons.length} {lessonsSpell}</div>
            <div className="card-header__detail">{(props.courseApproved && props.courseStarted) ? formatTime(moduleDuration) : ''}</div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={"module-" + key}>
          <Card.Body>
            {lessons}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  });
  
  return (
    <div className={"modules-list moduli-" + activeModuleKey}>
      <h2 className="modules-list__title">Svi moduli i lekcije kursa</h2>
      <Accordion defaultActiveKey={"module-" + activeModuleKey} id="modulesList">
        {items}
      </Accordion>
    </div>
  );
};

export default modulesAndLessonsAccordion;