import React from 'react';
import LogoHomepageWhite from "../../../../assets/images/logo-homepage-academy-white.svg";
import LogoHomepageWhiteSmall from "../../../../assets/images/favicon-homepage-academy-white.svg";
import { Link } from "react-router-dom";

const logoDark = () => (
  <Link to="/" className="navbar-brand" >
      <img className="main-logo d-none d-md-flex" src={LogoHomepageWhite} alt="Homepage Academy" />
      <img className="main-logo d-block d-md-none" src={LogoHomepageWhiteSmall} alt="Homepage Academy" />
  </Link>
);

export default logoDark;