import React from 'react';

const contactDetails = () => {
  return(
    <div className="contact-box__credentials">
      <h2 className="contact-box__title">Kontakt informacije:</h2>
      <p className="contact-box__row">
        <i className="icon icon-contact-mail"></i>
        <a href="mailto:office@homepageacademy.rs" target="_blank">office@homepageacademy.rs</a>
      </p>
      <p className="contact-box__row">
        <i className="icon icon-contact-phone"></i>
        <a href="tel:+381 62 193 93 93" target="_blank">+381 62 193 93 93</a>
      </p>
      <p className="contact-box__row">
        <i className="icon icon-contact-address"></i>
        Kisačka 47, 21000 Novi Sad
      </p>
      <p className="contact-box__row">
        <i className="icon icon-contact-website"></i>
        <a href="https://homepageacademy.rs" target="_blank">homepageacademy.rs</a>
      </p>
    </div>
  );
};

export default contactDetails;