import React from 'react';

const benefits = () => (
  <section className="benefits">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="row">
            <div className="col-md-5">
              <div className="benefits__intro">
                <h2 className="intro__title">Unapredi svoje znanje</h2>
                <div className="intro__descriptin">
                  <p>Kursevi Homepage akademije koncipirani su tako da se svaki od njih sastoji od modula kao tematskih celina koje objedinjuju video lekcije, tekstualne fajlove, zadatke i praktične primere.</p>
                  <p>Naši predavači, stručnjaci dokazani kroz praksu, vode te kroz svaku lekciju i omogućavaju ti da ceo proces savladaš na što jednostavniji način. Osim toga, polaznici Homepage akademije imaju ekskluzivan pristup zajednici predavača i polaznika, gde svi zajedno razmenjujete znanja i informacije, te si uvek u korak sa novinama iz oblasti koje si naučio kroz kurseve Homepage akademije.</p>
                </div>
              </div>
            </div>
            <div className="col-md-7 benefits__list d-flex justify-content-between align-self-stretch flex-column">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-uciodeksperata"></span>
                      <h4 className="benefit__title">Uči od eksperata</h4>
                      <div className="benefit__description">Nauči vredne tehnike i metodologije od stručnjaka, digitalnih eksperata i preduzetnika, koji će sa tobom podeliti znanje koje su uspešno dokazali u praksi.</div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-profesionalnorazvijeni"></span>
                      <h4 className="benefit__title">Profesionalno razvijeni kursevi</h4>
                      <div className="benefit__description">Odabrali smo aktuelne teme i u praksi dokazane stručnjake kako bismo sistematizovali znanje iz marketinga, digitala, preduzetništva i srodnih oblasti. Kroz našu platformu ćeš imati utisak da radiš zajedno sa njima.</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-ucikadagdekoliko"></span>
                      <h4 className="benefit__title">Uči kada, gde i koliko hoćeš</h4>
                      <div className="benefit__description">Napravi svoj raspored učenja i pristupi platformi Homepage akademije u svakom trenutku - pomoću telefona, tableta ili računara. </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-sertifikat"></span>
                      <h4 className="benefit__title">Potvrde i sertifikati</h4>
                      <div className="benefit__description">Svaki polaznik Homepage akademije dobija potvrdu o odslušanom kursu koji je odabrao i odslušao. U slučaju da kurs podrazumeva proveru stečenog znanja, polaznik dobija sertifikat o položenom testu.</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-ponavljanje"></span>
                      <h4 className="benefit__title">Ponavljanje je majka znanja</h4>
                      <div className="benefit__description">Lekcije u video formatu ti obezbeđuju da ne propustiš nijedan detalj. Dok je kurs aktuelan, imaš pristup video materijalima i možeš da ih pogledaš više puta.</div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="benefit">
                      <span className="benefit__icon icon icon-benefits-budideozajednice"></span>
                      <h4 className="benefit__title">Budi deo zajednice koja deli znanje</h4>
                      <div className="benefit__description">Postavljaj pitanja, zatraži mišljenje ili ponudi rešenje kroz zatvorenu Facebook grupu u kojoj polaznici Homepage akademije i njeni predavači, razmenjuju informacije.</div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default benefits;