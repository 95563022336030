import React, {Component, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {withRouter} from 'react-router-dom';

import PageHeader from "../layout/SingleCoursePackage/PageHeader/PageHeader";

import CoursePackageBoxes from "../layout/CoursePackageBox/CoursePackageBoxes";

import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {differenceFromToday, updateObject} from "../../shared/utility";
import Spinner from "../layout/UI/Spinner/Spinner";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/hompageacademy-fb-share-image.png";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import PaymentInfoModal from "../layout/PaymentInfoModal/PaymentInfoModal";

const tagManagerArgs = {
    dataLayer: {
        page: 'Pojedinacni paket kurseva'
    },
    dataLayerName: 'PageDataLayer'
};
const COURSE_CARD_OFFSET_Y = 50;

class SingleCoursePackage extends Component {
    state = {
        courseCardFixed: false,
        paymentProcessorState: null,
        paymentProcessorMessage: null
    }

    scrollHandler = event => {
        if (window.scrollY > COURSE_CARD_OFFSET_Y && !this.state.courseCardFixed) {
            this.setState(updateObject(this.state, {
                courseCardFixed: true
            }));
        } else if (window.scrollY <= COURSE_CARD_OFFSET_Y && this.state.courseCardFixed) {
            this.setState(updateObject(this.state, {
                courseCardFixed: false
            }));
        }
    }

    componentWillMount() {
        this.props.onFetchSingleCoursePackage(this.props.match.params.slug);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler);
        TagManager.dataLayer(tagManagerArgs);

        const paymentProcessorQuery = this.getPaymentProcessorQuerySearch(this.props.location.search);
        if (paymentProcessorQuery.payment && ['failed', 'success'].includes(paymentProcessorQuery.payment)) {
            this.setState(updateObject(this.state, {
                paymentProcessorState: paymentProcessorQuery.payment,
                paymentProcessorMessage: paymentProcessorQuery.message
            }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        this.props.onSingleCoursePackageUnmount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.singleCourse !== prevProps.singleCourse) && this.props.singleCourse.slug) {
            ReactGA.pageview(this.props.singleCourse.slug);
        }
    }

    setCoursePackagePath = (coursePackagePath) => {
        this.props.onSetAuthRedirectPath(coursePackagePath);
    }

    getPaymentProcessorQuerySearch = (querySearch) => {
        const query = new URLSearchParams(
            this.props.location.search
        );
        return {
            payment: query.get('payment'),
            message: query.get('message')
        };
    }

    paymentModalCloseHandler = () => {
        this.setState(updateObject(this.state, {
            paymentProcessorState: null
        }));
    }

    render() {
        let singleCoursePackage = (
            <section className="course-details">
                <Spinner/>
            </section>
        );
        const singleCoursePackageCount = Object.keys(this.props.singleCoursePackage).length;
        let paymentInfo = null;
        if (!this.props.loading) {
            if (singleCoursePackageCount > 0) {
                const secondsForCountdown = differenceFromToday(this.props.singleCoursePackage.startDate);
                const courseStarted = secondsForCountdown < 0;

                singleCoursePackage = (
                    <Fragment>
                        {this.props.singleCoursePackage ? (
                            <PageHeader
                                singleCoursePackage={this.props.singleCoursePackage}
                                secondsForCountdown={secondsForCountdown}
                                courseCardFixed={this.state.courseCardFixed}
                                courseStarted={courseStarted}
                                isAuthenticated={this.props.isAuthenticated}
                                setCoursePackagePath={this.setCoursePackagePath}
                            />
                        ) : null}
                        <section className="course-details">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-1">
                                        <div className="row">
                                            <CoursePackageBoxes courses={this.props.singleCoursePackage.courses}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Fragment>
                );

                if (this.state.paymentProcessorState) {
                    paymentInfo = <PaymentInfoModal
                        productType={'Paket'}
                        paymentProcessorState={this.state.paymentProcessorState}
                        courseStarted={courseStarted ?? null}
                        startDate={this.props.singleCoursePackage.startDate ?? null}
                        courseName={this.props.singleCoursePackage.name}
                        orderInfo={this.props.orderInfo}
                        hide={this.paymentModalCloseHandler}
                    />;
                }

            } else {
                singleCoursePackage = (
                    <section className="course-details">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="mt-5">Traženi paket kurseva nije pronađen.</p>
                                </div>
                            </div>
                        </div>
                    </section>);
            }
        }

        const courseTitle = this.props.singleCoursePackage.name ? this.props.singleCoursePackage.name + ' - Homepage Academy' : 'Homepage Academy';
        const courseDescription= this.props.singleCoursePackage.name ? this.props.singleCoursePackage.name : '';
        const ogImage = this.props.singleCoursePackage.ogImage ? this.props.singleCoursePackage.ogImage : FbShareImage;
        const ogTitle = this.props.singleCoursePackage.ogTitle ? this.props.singleCoursePackage.ogTitle : 'Postani deo zajednice koja deli znanje!';
        const ogDescription = this.props.singleCoursePackage.ogDescription ? this.props.singleCoursePackage.ogDescription : 'Registruj se!';

        return (
            <Fragment>
                <Helmet>
                    <title>{courseTitle}</title>
                    <meta name="description" content={courseDescription} />
                    { ogImage ? (
                        <meta property="og:image" content={ogImage} />
                    ) : null }
                    { ogTitle ? (
                        <meta property="og:title" content={ogTitle} />
                    ) : null }
                    { ogDescription ? (
                        <meta property="og:description" content={ogDescription} />
                    ) : null }
                </Helmet>
                {singleCoursePackage}
                {paymentInfo}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.course.loading,
        singleCoursePackage: state.course.singleCoursePackage,
        orderInfo: state.course.singleCoursePackage.orderInfo,
        isAuthenticated: state.auth.token != null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchSingleCoursePackage: (slug) => dispatch(actions.fetchSingleCoursePackage(slug)),
        onSingleCoursePackageUnmount: () => dispatch(actions.singleCoursePackageUnmount()),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleCoursePackage));