/*****************************/
/* User registration ACTIONS */
/*****************************/
import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import {getMailchimpAjaxUrl} from "../../../shared/utility";
import jsonp from "jsonp";
import toQueryString from "to-querystring";

export const userRegistrationStart = () => {
  return {
    type: actionTypes.REGISTRATION_START
  };
};
export const userRegistrationSuccess = (user) => {
  //console.log('ACTION userRegistrationSuccess');
  return {
    type: actionTypes.REGISTRATION_SUCCESS,
    user: user
  };
};
export const userRegistrationFail = (error) => {
  return {
    type: actionTypes.REGISTRATION_FAIL,
    error: error
  };
};
export const userRegistration = (registrationData) => {
  return dispatch => {
    dispatch(userRegistrationStart());
    axios.post('/users', registrationData)
      .then(response => {
        //console.log('userRegistration AXIOS response', response);
        dispatch(userRegistrationSuccess(response.data.data));
      })
      .catch(err => {
        //console.log('userRegistration AXIOS response ERROR', err.response);
        dispatch(userRegistrationFail(err.response));
      });
  }
};
export const userRegistrationUnmount = () => {
  return {
    type: actionTypes.REGISTRATION_UNMOUNT
  };
};
export const userRegistrationMailchimpSubscribe = (email) => {
  return dispatch => {
    const mailchimpData = {
      EMAIL: email
    };
    const params = toQueryString(mailchimpData);
    const url = getMailchimpAjaxUrl("https://homepage.us17.list-manage.com/subscribe/post?u=786fe09e1bb45968511438f03&amp;id=35c4ee4e7c") + "&" + params;
    jsonp(url, { param: 'c' }, (err, data) => {});
  };
};

