import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {inputChangedFunction, formatStateForForm, formatUserForForm, checkAxiosErrorFields} from "../../shared/utility";

import UserAccount from "../../hoc/Layouts/UserAccount";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Profil'
  },
  dataLayerName: 'PageDataLayer'
};

class ChangeProfile extends Component {

  state = {
    form: {
      name: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Ime i prezime'
        },
        value: '',
        validation: {
          required: true,
          minLength: 3
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      email: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email',
          readOnly: true
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      phone: {
        order: 2,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Broj telefona'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      country: {
        order: 3,
        elementType: 'select',
        elementClasses: 'form-control',
        elementConfig: {
          options: [
            {value: '1', displayValue: 'Serbia'},
            {value: '2', displayValue: 'Other'}
          ]
        },
        value: '1',
        validation: {},
        valid: true,
        errorMessage: null,
        touched: false
      },
      city: {
        order: 4,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Grad'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      postCode: {
        order: 5,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Poštanski broj'
        },
        value: '',
        validation: {
          required: true,
          minLength: 5,
          maxLength: 5
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      address: {
        order: 6,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Adresa'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      organization: {
        order: 7,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Organizacija'
        },
        value: '',
        validation: {
          required: false
        },
        valid: true,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  ChangeProfileHandler = (event) => {
    event.preventDefault();
    const changeProfileData = {};
    for (let formElementIdentified in this.state.form) {
      // Email can't be updated
      if (formElementIdentified !== 'email') {
        changeProfileData[formElementIdentified] = this.state.form[formElementIdentified].value;
      }
    }
    //console.log('changeProfileData', changeProfileData);
    this.props.onChangeProfile(changeProfileData, this.props.token);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    if (Object.keys(this.props.user).length > 0) {
      //console.log('ChangeProfile componentDidMount entered!');
      this.setState(formatUserForForm(this.state, this.props.user));
      TagManager.dataLayer(tagManagerArgs);
      ReactGA.pageview('change-profile');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // Form fields error mapping
    if (prevProps.error !== this.props.error && this.props.error !== null) {
      const errorFields = this.props.error.data.error.data.fields;
      this.setState(checkAxiosErrorFields(errorFields, this.state));
    }

    if ((this.props.user !== prevProps.user) && Object.keys(this.props.user).length > 0) {
      //console.log('ChangeProfile componentDidUpdate entered!');
      this.setState(formatUserForForm(this.state, this.props.user));
    }
  }

  componentWillUnmount() {
    this.props.onChangeProfileUnmount();
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);
    //console.log('ChangeProfile Render', this.state);

    let form = (
      <form onSubmit={this.ChangeProfileHandler}>
        <div className="row">
          {
            formElementsArray.map((el) => {
              return (
                <div className="col-md-6" key={el.id}>
                  <Input
                    class={el.config.elementClasses}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    value={el.config.value ? el.config.value : ''}
                    invalid={!el.config.valid}
                    shouldValidate={el.config.validation}
                    touched={el.config.touched}
                    errorMessage={el.config.errorMessage}
                    changed={(event) => this.inputChangedHandler(event, el.id)}/>
                </div>
              )
            })
          }
        </div>
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <Button
              disabled={!this.state.formIsValid}
              btnType="btn-primary user-form__btn-submit"
              clicked={this.ChangeProfileHandler}>Sačuvaj</Button>
          </div>
        </div>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    let formMessage = null;
    if (this.props.profileChanged) {
      formMessage = (
        <p className="user-form__message success text-left">Korisnički profil je uspešno izmenjen.</p>
      );
    }

    return (
      <UserAccount>
        <h2 className="user-account__main__title">Korisnički profil</h2>
        <div className="user-account__main__content">
          { formMessage }
          { form }
        </div>
      </UserAccount>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.auth.loading,
    error: state.auth.error,
    user: state.auth.user,
    profileChanged: state.auth.profileChanged
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeProfile: (userData, token) => dispatch(actions.changeProfile(userData, token)),
    onChangeProfileUnmount: () => dispatch(actions.changeProfileUnmount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfile);