import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const fetchFavoriteLessonsStart = () => {
  return {
    type: actionTypes.FETCH_FAVORITE_LESSONS_START
  };
};
export const fetchFavoriteLessonsSuccess = (favoriteLessons) => {
  return {
    type: actionTypes.FETCH_FAVORITE_LESSONS_SUCCESS,
    favoriteLessons: favoriteLessons
  }
};
export const fetchFavoriteLessonsFail = (error) => {
  return {
    type: actionTypes.FETCH_FAVORITE_LESSONS_FAIL,
    error: error
  }
};
export const fetchFavoriteLessons = () => {
  return dispatch => {
    dispatch(fetchFavoriteLessonsStart());
    const headers = getAxiosHeader();
    axios.get('/users/me/favorite-lessons', headers)
    .then(res => {
      //console.log('fetchFavoriteLessons res', res);
      dispatch(fetchFavoriteLessonsSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('fetchFavoriteLessons error', err);
      dispatch(fetchFavoriteLessonsFail(err));
    });
  }
};