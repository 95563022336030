import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchPodcastsStart = () => {
  return {
    type: actionTypes.FETCH_PODCASTS_START
  };
};
export const fetchPodcastsSuccess = (podcasts) => {
  return {
    type: actionTypes.FETCH_PODCASTS_SUCCESS,
    podcasts: podcasts
  }
};
export const fetchPodcastsFail = (error) => {
  return {
    type: actionTypes.FETCH_PODCASTS_FAIL,
    error: error
  }
};
export const fetchPodcasts = () => {
  return dispatch => {
    dispatch(fetchPodcastsStart());

    const headers = getAxiosHeader();
    axios.get('/podcasts', headers)
      .then(res => {
        //console.log('fetchPodcasts res', res.data.data);
        dispatch(fetchPodcastsSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('fetchPodcasts error', err);
        dispatch(fetchPodcastsFail(err));
      });
  }
};