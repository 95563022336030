import React, {Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router-dom';
import {getFreeAttemptsString, NEXT_AVAILABLE_TEST_ATTEMPT_DAYS} from '../../../shared/utility';

const lessonTestBox = (props) => {

  const handleLessonTestButton = (e) => {
    switch(props.status) {
      case 'passed':
        e.preventDefault();
        window.open(props.certificateUrl, '_blank');
        break;
      case 'buy':
        e.preventDefault();
        if (props.invoiceUrl) {
          window.open(props.invoiceUrl, '_blank');
        } else {
          props.createInvoice();
        }
        break;
    }
  };
  
  let boxIcon = 'test-doc';
  let boxTitle = 'Proveri svoje znanje';
  let boxMessage = !props.isFree ? ((props.numberOfRemainingFreeAttempts > 0) ? 'Imaš ' + (props.testAttemptsCount > 0 ? 'još ' : '') + props.numberOfRemainingFreeAttempts + ' ' + getFreeAttemptsString(props.numberOfRemainingFreeAttempts) : 'Registrovali smo tvoju uplatu') : '';
  let buttonText = 'Pokreni test';
  let buttonIcon = 'arrow-continue';
  let buttonLink = '#';
  let buttonAdditionalClass = '';
  
  switch(props.status) {
    case 'run':
      buttonLink = '/test-intro/' + props.courseSlug + '/' + props.lessonSlug;
      break;
    case 'continue':
      boxTitle = 'Započeo/la si testiranje';
      buttonText = 'Nastavi test';
      boxMessage = 'Vrati se na test i odgovori na sva pitanja pre isteka predviđenog vremena.';
      buttonLink = '/test/' + props.courseSlug + '/' + props.lessonSlug;
      break;
    case 'buy':
      boxIcon = 'test-doc-exclamation';
      boxTitle = 'Plati pa pokreni test';
      boxMessage = 'Klikom na dugme "Uplatnica" otvoriće ti se dokument sa podacima za plaćanje. Podaci za plaćanje će ti stići na mejl kojim si registrovan/a na platformu.';
      buttonText = 'Uplatnica';
      break;
    case 'passed':
      boxIcon = 'test-doc-checked';
      boxTitle = 'Položio/la si test';
      boxMessage = 'Možeš preuzeti svoj sertifikat.\n';
      buttonText = 'Sertifikat';
      buttonIcon = 'download';
      buttonLink = '#';
      break;
    case 'waiting':
      buttonAdditionalClass = 'disabled';
      boxTitle = props.isFree ? ('Svoje znanje možeš opet proveriti za  ' + props.daysUntilNextTestAttempts + ' dana.') : 'Imaš još jedan besplatan pokušaj';
      boxMessage = !props.isFree ? ('Svoje znanje možeš opet proveriti za  ' + props.daysUntilNextTestAttempts + ' dana.') : '';
      buttonLink = '#';
      break;
  }
  
  let contentIcon = '';
  let contentText = '';
  
  if (!props.testLoading) {
    contentIcon = <i className={"icon icon-" + boxIcon}></i>;
    contentText = <Fragment>
      <h4>{ boxTitle }</h4>
      <p>{ boxMessage }</p>
    </Fragment>;
  }
  
  return(
    <div className="lesson-details__test">
      <h2 className="title">Test</h2>
      <div className="test-info">
        <div className="row">
          <div className="col-2 col-xl-1 test-info__icon d-flex align-items-center justify-content-start">
            { contentIcon }
          </div>
          <div className="col-10 col-xl-8 test-info__message d-flex align-items-start justify-content-center flex-column">
            { contentText }
          </div>
          <div className="col-2 d-xl-none"></div>
          <div className="col-10 col-xl-3 mt-3 test-info__button d-flex align-items-center justify-content-start justify-content-xl-end">
            <Link
              className={"btn btn-primary btn-lg " + buttonAdditionalClass + (props.testLoading ? ' disabled' : '') }
              role="button"
              to={buttonLink}
              onClick={handleLessonTestButton}
            >
              { props.testLoading ? 'Učitavanje...' :  ReactHtmlParser(buttonText + '<i class="icon icon-' + buttonIcon + '"></i>') }
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lessonTestBox;