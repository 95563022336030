import React from 'react';
import TestFailIcons from "../../../../src/assets/images/test-fail-icons.png";
import TestSuccessIcons from "../../../../src/assets/images/test-success-icons.png";

const testResultsIcons = (props) => {
  const testIcons = props.passed ? TestSuccessIcons : TestFailIcons;
  const testIconsAlt = props.passed ? "Čestitamo!  Uspešno si položio/la test!" : "Više sreće drugi put!  Nažalost nisi položio/la test!";
  return (
    <img className="test-results__icons" src={testIcons} alt={testIconsAlt} />
  );
}

export default testResultsIcons;