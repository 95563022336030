import {updateObject, differenceFromToday} from "../../../shared/utility";

export const fetchTestAttemptsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchTestAttemptsSuccess = (state, action) => {
  const passedCourse = action.testAttempts.filter(item => {
    return item.passed === true;
  });
  //console.log(action.testAttempts, passedCourse, action.testAttempts.length);
  
  return updateObject(state, {
    testAttempts: {
      count: action.testAttempts.length,
      hasInProgress: (action.testAttempts.length > 0) ? action.testAttempts[action.testAttempts.length - 1].endTime === null : false,
      lastAttemptFinishedBefore: (action.testAttempts.length > 0) ? differenceFromToday(action.testAttempts[action.testAttempts.length - 1].endTime) : null,
      passed: passedCourse.length > 0,
      certificate: (passedCourse.length > 0) ? passedCourse[0].certificate : null
    },
    error: null,
    loading: false
  });
};
export const fetchTestAttemptsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchTestAttemptsUnmount = (state, action) => {
  return updateObject(state, {
    testAttempts: {
      count: 0,
      hasInProgress: false,
      lastAttemptDate: null,
      passed: false,
      certificate: null
    },
    error: null,
    loading: false
  });
};