import React, {useState} from 'react';

import Logo from "./Logo/Logo";
import NavbarNav from "./NavbarNav/NavbarNav";
import { Navbar } from "react-bootstrap";

const header = (props) => {

  const [expanded, setExpanded] = useState(false);
  
  const onToggleHandler = () => {
    if (window.innerWidth < 992) {
      setExpanded(!expanded);
    }
  };
  
  const onGetPrevPath = (prevPath) => {
    props.setPrevPath(prevPath);
  };
  
  return (
    <div className={props.classes}>
      <Navbar bg="light" expand="lg" className={"js--navbar fixed-top" + (expanded ? " navbar-opened" : "")} expanded={expanded}>
        <div className="container">
          <Navbar.Toggle aria-controls="navbarMainMenu" onClick={onToggleHandler} />
          <Logo />
          <NavbarNav isAuthenticated={props.isAuth} user={props.user} navSelect={onToggleHandler} getPrevPath={onGetPrevPath} />
        </div>
      </Navbar>
    </div>
  );
}

export default header;