import React from 'react';
import useWindowDimensions from "../../../hoc/hooks/useWindowDimensions";

const lessonWatched = (props) => {
  const { height, width } = useWindowDimensions();
  const lessonWatchedChangeHandler = (e) => {
    e.preventDefault();
    props.watched(e.target.value);
  };
  const labelText = (width > 768) ? 'Potvrda o odslušanoj lekciji' : 'odslušano';
  return (
    <div className="card-body__course__checkbox checkbox-wrapper checkbox-wrapper--big checkbox-wrapper--watched">
      <input
        className="mobile-centered"
        id="courseOptionsWatched"
        name="courseOptionsWatched"
        type="checkbox"
        value={props.lessonSlug}
        checked={props.checked}
        onChange={(event) => lessonWatchedChangeHandler(event)}
        disabled={props.checked} />
      <label htmlFor="courseOptionsWatched">{labelText}</label>
    </div>
  );
};

export default lessonWatched;