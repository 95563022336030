import React from 'react';
import ContentEditable from "react-contenteditable";

const lessonNotes = (props) => {
  const contentEditableChangeHandler = (e) => {
    props.noteChanged(e.target.value);
  };

  const noteboxCollapseHandler = (e) => {
    e.preventDefault();
    props.toggleNoteCollapsed(!props.noteCollapsed)
  };
  const contentEditableOpenHandler = (e) => {
    e.preventDefault();
    //console.log('contentEditableOpenHandler');
    props.toggleNoteCollapsed(true)
  };

  return(
    <div className="lesson-details__notes">
      <h2 className="title">Beleške</h2>
      <div className="lesson-details__notebox">
        <ContentEditable
          className={"lesson-details__notetext js-collapse-textbox" + (props.noteCollapsed ? " open" : "")}
          html={props.notes}
          onChange={contentEditableChangeHandler}
          onFocus={contentEditableOpenHandler}
        />
        <a className={"lesson-details__notebox__readmore-arrow" + (!props.noteCollapsed ? " show" : "")} href="#" onClick={(event) => noteboxCollapseHandler(event)}>
          <i className="icon icon-arrow-up rotate-45"></i>
        </a>
        <a className={"lesson-details__notebox__close" + (props.noteCollapsed ? " show" : "")} href="#" onClick={(event) => noteboxCollapseHandler(event)}>
          zatvori <i className="icon icon-arrow-up"></i>
        </a>
      </div>
    </div>
  );
};

export default lessonNotes;