import React from 'react';
import {getLessonString, getDayString} from '../../../../shared/utility';

const courseStatistics = (props) => {
  const modulesCount = (props.singleCourse.modulesCount > 0) ? (props.singleCourse.modulesCount - 1) : 0;
  const modulesSpell = (((modulesCount % 10) === 1) && (modulesCount !== 11)) ? "modul" : "modula";

  const lessonCount = (props.singleCourse.lessonCount);
  const lessonCountSpell = getLessonString(lessonCount);

  const attachmentSpell = (((props.singleCourse.attachmentCount % 10) === 1) && (props.singleCourse.attachmentCount !== 11)) ? "materijal" : "materijala";
  const instructorsCount = props.singleCourse.instructors.length;
  const instructorsSpell = (((instructorsCount % 10) === 1) && (instructorsCount !== 11)) ? "predavač" : "predavača";
  
  const availableDaysSpell = getDayString(props.singleCourse.availableDays);

  return (
    <div className="course-card__statistics">
{/*      <div className="course-card__statistics__row"><i className="icon icon-circle-time"></i>
        <strong>{props.singleCourse.attachmentCount}</strong> {attachmentSpell}
      </div>*/}
      <div className="course-card__statistics__row"><i className="icon icon-module"></i>
        <strong>{modulesCount}</strong> {modulesSpell}
      </div>
      <div className="course-card__statistics__row"><i className="icon icon-circle-play"></i>
        <strong>{lessonCount}</strong> {lessonCountSpell}
      </div>
      { props.singleCourse.certificate ? (
        <div className="course-card__statistics__row"><i className="icon icon-certificate"></i>
          online test i sertifikat
        </div>
      ) : null}
      { props.singleCourse.confirmationCertificate ? (
        <div className="course-card__statistics__row"><i className="icon icon-certificate"></i>
          <strong>potvrda</strong> o završenom kursu
        </div>
      ) : null}
      <div className="course-card__statistics__row"><i className="icon icon-circle-user"></i>
        <strong>{instructorsCount}</strong> {instructorsSpell}
      </div>
      <div className="course-card__statistics__row"><i className="icon icon-calendar"></i>
        <strong>{props.singleCourse.availableDays}</strong>  {availableDaysSpell} dostupnost kursa
      </div>
      {(props.singleCourse.facebookGroupLink) ? (
        <div className="course-card__statistics__row">
          <i className="icon icon-circle-facebook"></i>
          <strong>
            <a href={props.singleCourse.facebookGroupLink} target="_blank">podrška grupe</a>
          </strong>
        </div>
      ) : null}
    </div>
  );
};

export default courseStatistics;