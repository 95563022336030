import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const lessonFavoriteStart = () => {
  return {
    type: actionTypes.LESSON_FAVORITE_START
  };
};
export const lessonFavoriteSuccess = (lessonMeta) => {
  return {
    type: actionTypes.LESSON_FAVORITE_SUCCESS,
    lessonMeta: lessonMeta
  }
};
export const lessonFavoriteFail = (error) => {
  return {
    type: actionTypes.LESSON_FAVORITE_FAIL,
    error: error
  }
};
export const lessonFavorite = (slug) => {
  return dispatch => {
    dispatch(lessonFavoriteStart());
    const headers = getAxiosHeader();
    axios.post('/lessons/' + slug + '/favorite', null, headers)
      .then(res => {
        //console.log('lessonFavorite success', res.data.data);
        dispatch(lessonFavoriteSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('lessonFavorite error', err);
        dispatch(lessonFavoriteFail(err));
      });
  }
};
