import React from 'react';
import ReactHtmlParser from "react-html-parser";


const testResultsTitle = (props) => {
  const title = props.passed ? ReactHtmlParser('Čestitamo!<br/>Uspešno si položio/la test!') : ReactHtmlParser('Više sreće drugi put!<br/>Nažalost nisi položio/la test!');
  return (
    <h2 className="test-results__title">{title}</h2>
  );
}

export default testResultsTitle;