import {updateObject} from "../../../shared/utility";

export const requestCourseStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    formSent: false
  });
};
export const requestCourseSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
    formSent: true
  });
};
export const requestCourseFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    formSent: false
  });
};
export const requestCourseUnmount = (state, action) => {
  return updateObject(state, {
    response: [],
    error: null,
    loading: false,
    formSent: false
  });
};