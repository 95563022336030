import * as actionTypes from '../../actions/actionTypes';

import { fetchPodcastsStart, fetchPodcastsSuccess, fetchPodcastsFail} from './podcast';
import { fetchSinglePodcastStart, fetchSinglePodcastSuccess, fetchSinglePodcastFail, singlePodcastUnmount} from './singlePodcast';

const initialState = {
  podcasts: [],
  singlePodcast: [],
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_PODCASTS_START:
      return fetchPodcastsStart(state, action);
    case actionTypes.FETCH_PODCASTS_SUCCESS:
      return fetchPodcastsSuccess(state, action);
    case actionTypes.FETCH_PODCASTS_FAIL:
      return fetchPodcastsFail(state, action);

    case actionTypes.FETCH_SINGLE_PODCAST_START:
      return fetchSinglePodcastStart(state, action);
    case actionTypes.FETCH_SINGLE_PODCAST_SUCCESS:
      return fetchSinglePodcastSuccess(state, action);
    case actionTypes.FETCH_SINGLE_PODCAST_FAIL:
      return fetchSinglePodcastFail(state, action);
    case actionTypes.SINGLE_PODCAST_UNMOUNT:
      return singlePodcastUnmount(state, action);

    default:
      return state;
  }
};

export default reducer;