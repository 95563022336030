import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {connect} from "react-redux";
import iconApproved from "../../../assets/images/icon-approved-small.png";
import iconPurchased from "../../../assets/images/icon-purchased-small.png";
import ImageCarousel from "./ImageCarousel";
import {getCourseString} from "../../../shared/utility";

class coursePackageBox extends Component {

    render() {
        const coursesCount = this.props.coursePackage.courses.length;
        const coursesCountSpell = getCourseString(coursesCount);

        const instructorsCount = this.props.coursePackage.instructors.length;
        const instructorsSpell = ((instructorsCount % 10) === 1) ? "predavač" : "predavača";

        let courseBoxButtons = (
            <Fragment>
                { (!this.props.coursePackage.purchased && !this.props.coursePackage.approved) ? <Link to={ "/checkout/" + this.props.coursePackage.slug + "/package" } className="btn btn-primary">Kupi odmah</Link> : null }
                <Link to={'/paket-kurseva/' + this.props.coursePackage.slug} className="btn btn-link">Pogledaj</Link>
            </Fragment>
        );

        return(
            <div className="course">
                {(this.props.coursePackage.approved) ? (
                    <img src={iconApproved} className="course__icon-approved" />
                ): (
                    (this.props.coursePackage.purchased) ? (
                        <img src={iconPurchased} className="course__icon-approved" />
                    ) : null
                )}
                <ImageCarousel
                    image={this.props.coursePackage.image}
                    title={this.props.coursePackage.name}
                    subtitle={this.props.coursePackage.subtitle}
                    courses={this.props.coursePackage.courses}
                    color={this.props.coursePackage.color ?? null}
                />
                
                <div className="course__details d-flex flex-column justify-content-between">
                    <div className="course__details__description">
                        {ReactHtmlParser(this.props.coursePackage.shortDescription)}
                    </div>
                    <div className="course__details__rest">
                        <div className="course__statistics d-flex">
                            <div className="cell course-count">{coursesCount} {coursesCountSpell}</div>
                            <div className="cell instructors">{instructorsCount} {instructorsSpell}</div>
                        </div>
                        <div className="course__prices">
                            <span className={'regular-price'}>{this.props.coursePackage.price} { ReactHtmlParser('&euro;') }</span>
                        </div>
                        <div className="course__prices-info">(cena je izražena sa PDV-om)</div>

                        <div className="course__package__gap"></div>

                        <div className="course__buttons">
                            { courseBoxButtons }
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(null, mapDispatchToProps)(coursePackageBox));