import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const requestCourseStart = () => {
  return {
    type: actionTypes.REQUEST_COURSE_START
  };
};
export const requestCourseSuccess = (response) => {
  return {
    type: actionTypes.REQUEST_COURSE_SUCCESS,
    response: response
  }
};
export const requestCourseFail = (error) => {
  return {
    type: actionTypes.REQUEST_COURSE_FAIL,
    error: error
  }
};
export const requestCourse = (formData) => {
  return dispatch => {
    dispatch(requestCourseStart());
    const headers = getAxiosHeader();
    axios.post('/util/request-course', formData, headers)
      .then(response => {
        //console.log('requestCourse AXIOS response ', response.data.data);
        dispatch(requestCourseSuccess(response.data.data));
      })
      .catch(err => {
        //console.log('requestCourse AXIOS catch err ', err);
        dispatch(requestCourseFail(err));
      });
  }
};

export const requestCourseUnmount = () => {
  return {
    type: actionTypes.REQUEST_COURSE_UNMOUNT
  };
};