import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
  siteInfo: false
};

const siteInfoCloseSuccess = (state, action) => {
  return updateObject(state, {
    siteInfo: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SITE_INFO_CLOSE_SUCCESS:
      return siteInfoCloseSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;