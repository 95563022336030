import React from 'react';

import TitleBlock from './TitleBlock';
import CoursePackageCard from './CoursePackageCard';

const pageHeader = (props) => {

  const setCoursePackagePath = (coursePackagePath) => {
    props.setCoursePackagePath(coursePackagePath);
  };
  
  let titleBlock = null;
  if (props.singleCoursePackage.name) {
    titleBlock = (
      <TitleBlock
        title={props.singleCoursePackage.name}
        description={props.singleCoursePackage.description}
        startDate={props.singleCoursePackage.startDate}
        headerInfo={props.singleCoursePackage.headerInfo}
        instructors={props.singleCoursePackage.instructors}
      />
    );
  }
  return (
    <div className="course-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-1">
            {titleBlock}
          </div>
          <div className="col-lg-4">
              <CoursePackageCard
                singleCoursePackage={props.singleCoursePackage}
                secondsForCountdown={props.secondsForCountdown}
                courseCardFixed={props.courseCardFixed}
                courseStarted={props.courseStarted}
                isAuthenticated={props.isAuthenticated}
                setCoursePackagePath={setCoursePackagePath}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageHeader;