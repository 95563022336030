import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux';
import { Redirect, Link } from "react-router-dom";

import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";

import * as actions from '../../store/actions/index';
import {inputChangedFunction, formatStateForForm} from "../../shared/utility";
import {getLoggedInUser} from "../../store/actions/index";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import ReCaptcha from "../../hoc/Layouts/ReCaptchaHoc";

const tagManagerArgs = {
  dataLayer: {
    page: 'Login'
  },
  dataLayerName: 'PageDataLayer'
};

class Login extends Component {

  state = {
    form: {
      email: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email',
          autoComplete: 'username'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      password: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'password',
          placeholder: 'Lozinka',
          autoComplete: 'current-password'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  loginHandler = (event) => {
    event.preventDefault();
    this.props.onUserLogin(this.state.form.email.value, this.state.form.password.value);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('login');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // there is a token and user isn't loaded
    if (this.props.token && Object.keys(this.props.user).length === 0) {
      //console.log('Login componentDidUpdate onGetLoggedInUser');
      this.props.onGetLoggedInUser(this.props.token);
    }
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);

    let form = (
      <Fragment>
        <h2 className="user-form__title">Dobro došli!</h2>
        <form onSubmit={this.loginHandler}>
          <div className="row">
            {
              formElementsArray.map((el) => {
                return (
                  <div className="col-12" key={el.id}>
                    <Input
                      class={el.config.elementClasses}
                      elementType={el.config.elementType}
                      elementConfig={el.config.elementConfig}
                      value={el.config.value}
                      invalid={!el.config.valid}
                      shouldValidate={el.config.validation}
                      touched={el.config.touched}
                      errorMessage={el.config.errorMessage}
                      changed={(event) => this.inputChangedHandler(event, el.id)}/>
                  </div>
                )
              })
            }
            <div className="col-12 d-flex justify-content-center">
              <Button
                disabled={!this.state.formIsValid}
                btnType="btn-primary user-form__btn-submit"
                clicked={this.loginHandler}>Prijavi se</Button>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center flex-column">
              <Link className="user-form__link--reset" to="/reset-password">Resetuj lozinku</Link>
              <Link className="user-form__link--register" to="/register">Registruj se</Link>
            </div>
          </div>
        </form>
      </Fragment>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    let authRedirectPath = null;
    if (this.props.isAuthenticated) {
      const finalRedirectPath = localStorage.getItem('redirectPath') ? localStorage.getItem('redirectPath') : this.props.authRedirectPath;
      authRedirectPath = <Redirect to={finalRedirectPath} />;
      localStorage.removeItem('redirectPath');
    }

    let formMessage = null;
    if (this.props.confirmedRegistration) {
      formMessage = (
        <p className="user-form__message success">Tvoj korisnički nalog je uspešno verifikovan.<br/>Sada možeš pristupiti platformi - uloguj se!</p>
      );
    }
    if (this.props.error) {
      const errorMsg = (typeof this.props.error.message === 'object') ? this.props.error.message.error.message : this.props.error.message;
      formMessage = (
        <p className="user-form__message error">{ errorMsg }</p>
      );
    }

    return (
      <ReCaptcha>
        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 d-flex align-items-center justify-content-center flex-column user-form">
                {formMessage}
                <div className="user-form__box">
                  {authRedirectPath}
                  {form}
                </div>
              </div>
            </div>
          </div>
        </section>
      </ReCaptcha>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token != null,
    confirmedRegistration: state.auth.confirmedRegistration,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserLogin: (email, password) => dispatch(actions.userLogin(email, password)),
    onGetLoggedInUser: (token) => dispatch(getLoggedInUser(token)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);