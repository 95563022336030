import React, {Component, Fragment} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import PageHeader from '../layout/SinglePodcast/PageHeader';
import SocialShare from '../layout/UI/SocialShare/SocialShare';
import Spinner from "../layout/UI/Spinner/Spinner";
import CourseBox from "../layout/CourseBox/CourseBox";
import {differenceFromToday} from "../../shared/utility";
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/hompageacademy-fb-share-image.png";

const tagManagerArgs = {
  dataLayer: {
    page: 'Pojedinacni pokast'
  },
  dataLayerName: 'PageDataLayer'
};

class SinglePodcast extends Component {
  
  componentDidMount() {
    this.props.onFetchSinglePodcast(this.props.match.params.slug);
    this.props.onFetchPodcasts();
    TagManager.dataLayer(tagManagerArgs);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.props.onFetchSinglePodcast(this.props.match.params.slug);
      this.props.onFetchPodcasts();
    }
    if ((this.props.singlePodcast !== prevProps.singlePodcast) && this.props.singlePodcast.slug) {
      ReactGA.pageview(this.props.singlePodcast.slug);
    }
  }
  
  componentWillUnmount() {
    this.props.onSinglePodcastUnmount();
  }

  prevPathHandler = () => {
    this.props.onSetAuthRedirectPath(this.props.history.location.pathname);
  }

  render() {
    let podcast = <Spinner />;
    let pageHeader = null;
    let upSaleCourses = {};
    let podcastLink = null;
    let podcastSoundcloud = null;
    let ogImage = FbShareImage;
    let ogTitle = 'Podkast';
    let ogDescription = 'Homepage akademija';

    if (!this.props.loading) {
      podcastLink = process.env.PUBLIC_URL + "/svi-podkasti/" + this.props.singlePodcast.slug;

      /* UpSale Courses */
      if (this.props.singlePodcast.upSaleCourses) {
        upSaleCourses = Object.values(this.props.singlePodcast.upSaleCourses).map(course => {
          const secondsForCountdown = differenceFromToday(course.startDate);
          return (
              <div className="col-lg-6 col-xl-4 align-self-md-stretch align-self-auto pb-lg-0 pb-2" key={course.id}>
                <CourseBox
                    course={course}
                    secondsForCountdown={secondsForCountdown} />
              </div>
          )
        });
      }

      if (this.props.singlePodcast.audio) {
        podcastSoundcloud = (
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <h4 className="title">Audio snimak</h4>
              <ReactPlayer
                className='soundcloud-player'
                url={this.props.singlePodcast.audio}
                config={{
                  soundcloud: {
                    options: {
                      visual: false,
                      show_user: false,
                      color: "#FE9700"
                    }
                  }
                }}
                height="170px"
                width="100%"
              />
            </div>
          </div>
        );
      }
      
      podcast = (
        <section className="podcast-single">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                {this.props.singlePodcast.videoId ?
                    <div className="podcast-player">
                      <div className="podcast-player__box embed-responsive embed-responsive-16by9">
                        <Vimeo video={this.props.singlePodcast.videoId} />
                      </div>
                    </div> :
                    <div className="podcast-player--guest">
                      <div className="podcast-player--guest__wrapper">
                        <p className="podcast-player--guest__message">Potrebno je da se registruješ, a potom da se uloguješ kako bi mogao/la da pristupiš podkast videu.</p>
                        <div className="podcast-player--guest__buttons">
                          <Link to="/login" className="btn btn-primary" onClick={this.prevPathHandler}>Uloguj se</Link>
                          <Link to="/register" className="btn btn-outline-primary" onClick={this.prevPathHandler}>Registruj se</Link>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
            { podcastSoundcloud }
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 podcast-single__content">
                {ReactHtmlParser(this.props.singlePodcast.description)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div className="podcast-single__social">
                  <SocialShare
                    url={podcastLink}
                    title={this.props.singlePodcast.title}
                    showLabel={true}
                  />
                </div>
              </div>
            </div>

            { (Object.keys(upSaleCourses).length > 0) ?
              <div className="row no-gutters">
                <div className=" col-lg-10 offset-lg-1">
                  <h2 className="title">Povezani kursevi</h2>
                  <div className="row">
                    {upSaleCourses}
                  </div>
                </div>
              </div> : null
            }
            
          </div>
        </section>
      );
      pageHeader = <PageHeader singlePodcast={this.props.singlePodcast}/>;
      ogImage = this.props.singlePodcast.image ? this.props.singlePodcast.image : FbShareImage;
      //ogTitle = this.props.singlePodcast.ogTitle ? this.props.singlePodcast.ogTitle : this.props.singlePodcast.name;
      ogDescription = this.props.singlePodcast.ogDescription ? this.props.singlePodcast.ogDescription : this.props.singlePodcast.shortDescription;
    }
    
    return (
      <Fragment>
        <Helmet>
          <title>{ogTitle} - Homepage Academy</title>
          <meta name="description" content={ogDescription} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={ogDescription} />
        </Helmet>
        {pageHeader}
        {podcast}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loading: state.podcasts.loading,
    singlePodcast: state.podcasts.singlePodcast,
    podcasts: state.podcasts.podcasts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPodcasts: () => dispatch(actions.fetchPodcasts()),
    onFetchSinglePodcast: (slug) => dispatch(actions.fetchSinglePodcast(slug)),
    onSinglePodcastUnmount: () => dispatch(actions.singlePodcastUnmount()),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePodcast));