import React from 'react';
import {Link} from 'react-router-dom';
import {Accordion, Card} from "react-bootstrap";
import {formatTime, getLessonString} from '../../../shared/utility';

const courseModules = (props) => {

  const items = Object.keys(props.data).map(key => {
    const lessons = props.data[key].lessons;
    const lessonCount = lessons.length;
    const lessonsSpell = getLessonString(lessonCount);

    let moduleLessonsDuration = 0;

    let lessonsList = Object.keys(lessons).map(k => {
      moduleLessonsDuration += parseInt(lessons[k]['duration']);
      return(
        <div className="course-details__lesson d-flex align-items-start justify-content-end"  key={k}>
          <div className="course-details__lesson__number">{(parseInt(k) + 1)}.</div>
          <div className="course-details__lesson__name mr-auto">
            { (lessons[k]['free'] || (props.courseApproved && props.courseStarted)) ?
              <Link to={'/kurs/' + props.courseSlug + '/lekcija/' + lessons[k]['slug']}>{lessons[k]['name']}</Link> :
              <span>{lessons[k]['name']}</span>
            }
          </div>
          { (lessons[k]['free'] || (props.courseStarted && props.isAuthenticated && props.courseApproved)) ?
            <div className="course-details__lesson__time">
              {formatTime(lessons[k]['duration'])}
            </div>
          : null }
        </div>
      );
    });

    moduleLessonsDuration = formatTime(moduleLessonsDuration);

    return (
      <Card key={key}>
        <Accordion.Toggle as={Card.Header} eventKey={"module-" + key} className="course-details__module d-flex align-items-start justify-content-end">
          <div className="course-details__module__number">{ (parseInt(key) > 0) ? (parseInt(key) + '.') : '' }</div>
          <div className="course-details__module__name mr-auto">{props.data[key].name}</div>
          <div className="course-details__module__lectures">{(parseInt(key) > 0) ? (lessons.length + ' ' + lessonsSpell) : ''}</div>
          { (props.courseStarted && props.isAuthenticated && props.courseApproved && (parseInt(key) > 0)) ?
            <div className="course-details__module__time">{moduleLessonsDuration}</div>
          : null }
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={"module-" + key}>
          <Card.Body>
            {lessonsList}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  });

  return (
    <div className="course-details__modules">
      <h2 className="title">Moduli</h2>
      <Accordion defaultActiveKey={"module-1"} id="modulesList">
      {items}
      </Accordion>
    </div>
  );
};

export default courseModules;