import React, {Component, Fragment} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Spinner from "../layout/UI/Spinner/Spinner";
import {checkAxiosErrorFields, formatStateForForm, inputChangedFunction} from "../../shared/utility";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Reset lozinke potvrdjen'
  },
  dataLayerName: 'PageDataLayer'
};

class ResetPasswordConfirm extends Component {

  state = {
    form: {
      plainPassword: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'password',
          placeholder: 'Nova lozinka'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false,
    token: null
  }

  resetPasswordConfirmHandler = (event) => {
    event.preventDefault();
    //console.log('resetPasswordConfirmHandler TOKEN', this.state.token);
    this.props.onResetPasswordConfirm(this.state.form.plainPassword.value, this.state.token);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    const token = this.props.match.params.hash;
    this.setState({token: token});
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('reset-password-confirm');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error !== null) {
      const errorFields = this.props.error.data.error.data.fields;
      this.setState(checkAxiosErrorFields(errorFields, this.state));
    }
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);

    let form = (
      <Fragment>
        <h2 className="user-form__title">Unesi novu lozinku</h2>
        <form onSubmit={this.resetPasswordConfirmHandler}>
          <div className="row">
            {
              formElementsArray.map((el) => {
                return (
                  <div className="col-12" key={el.id}>
                    <Input
                      class={el.config.elementClasses}
                      elementType={el.config.elementType}
                      elementConfig={el.config.elementConfig}
                      value={el.config.value}
                      invalid={!el.config.valid}
                      shouldValidate={el.config.validation}
                      touched={el.config.touched}
                      errorMessage={el.config.errorMessage}
                      changed={(event) => this.inputChangedHandler(event, el.id)}/>
                  </div>
                )
              })
            }
            <div className="col-12 d-flex justify-content-center">
              <Button
                disabled={!this.state.formIsValid}
                btnType="btn-primary user-form__btn-submit"
                clicked={this.resetPasswordConfirmHandler}>Pošalji</Button>
            </div>
          </div>
        </form>
      </Fragment>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    let authRedirectPath = null;
    if (this.props.resetPasswordConfirmed) {
      authRedirectPath = <Redirect to='/login' />;
    }


    return (
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 d-flex align-items-center user-form">
              <div className="user-form__box">
                { authRedirectPath }
                { form }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    resetPasswordConfirmed: state.auth.resetPasswordConfirmed
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onResetPasswordConfirm: (plainPassword, token) => dispatch(actions.resetPasswordConfirm(plainPassword, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm);