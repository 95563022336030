import React, {Component, Fragment} from 'react';
import ReactHtmlParser from "react-html-parser";

class Page extends Component {

  render() {
    return (
      <Fragment>
        <div className="single-page__header">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="single-page__title">{this.props.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="single-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {this.props.children}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Page;