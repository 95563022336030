import React, {Fragment, useEffect, useRef, useState, useLayoutEffect} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import {Link} from 'react-router-dom';

import Countdown from "../../UI/Countdown/Countdown";
import CourseStatistics from "./CouseStatistics";
import ReactHtmlParser from "react-html-parser";
import SocialShare from "../../UI/SocialShare/SocialShare";

const courseCard = (props) => {
  const secondsForCountdown = parseInt(props.secondsForCountdown);
  let courseStatistics = null;
  if (props.singleCourse.instructors) {
    courseStatistics = (
      <CourseStatistics
        singleCourse={props.singleCourse}/>
    );
  }
  const courseLink = process.env.PUBLIC_URL + "/kurs/" + props.singleCourse.slug;
  const courseCardFixedClass = props.courseCardFixed ? " fixed" : "";

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 50;

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  const test_dimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  };

  const handleResize = () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    //console.log('courseCard handleResize');
  };

  const setCoursePath = () => {
    const coursePath = "/checkout/" + props.singleCourse.slug;
    props.setCoursePath(coursePath);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={"course-card"} ref={targetRef}>
      <div className={"course-card__inner" + courseCardFixedClass} style={{maxWidth: ((window.innerWidth < 992) ? 'none' : (dimensions.width - 16))}}>
        {props.singleCourse.previewVideo ? (
          <div className="course-card__thumb">
            <div className="embed-responsive embed-responsive-16by9">
              <Vimeo video={props.singleCourse.previewVideo}/>
            </div>
          </div>
        ) : (
          <div className="course-card__thumb">
            <img className="img-fluid" src={props.singleCourse.image} alt={props.singleCourse.title}/>
          </div>
        )}

        <div className="course-card__details">
          {props.singleCourse.discountPrice ? (
            <h4 className="course-card__price--discount"><span
              className="course-card__price--old">{props.singleCourse.price} {ReactHtmlParser('&euro;')}</span>{props.singleCourse.discountPrice} {ReactHtmlParser('&euro;')}
            </h4>
          ) : (
            <h4 className="course-card__price">{props.singleCourse.price} {ReactHtmlParser('&euro;')}</h4>
          )}
          <div className="course-card__price-info">(cena je izražena sa PDV-om)</div>
          {(!Number.isNaN(secondsForCountdown) && secondsForCountdown > 0) ? (
            <div className="course-card__counter">
              <h4 className="countdown__title">Kurs će biti dostupan za:</h4>
              <Countdown seconds={secondsForCountdown}/>
            </div>
          ) : null}

          <div className="course-card__buttons">
            {
              (props.singleCourse.approved && props.courseStarted) ? (
                props.firstLessonSlug ? (
                  <Link to={"/kurs/" + props.singleCourse.slug + "/lekcija/" + props.firstLessonSlug} className="btn btn-secondary">Pristupi kursu</Link>
                ) : null
              ) : null
            }
            
            {
              (!props.singleCourse.purchased && !props.singleCourse.approved) ? (
                <Link to={"/checkout/" + props.singleCourse.slug} className="btn btn-primary" onClick={setCoursePath}>Kupi odmah</Link>
              ) : null
            }

            {
              (props.singleCourse.purchased) ? (
                  <p><strong>Za ovaj kurs postoji aktivna porudžbina.</strong></p>
              ) : null
            }

          </div>

          {courseStatistics}
          <div className="course-card__share d-flex align-items-center justify-content-center">
            <SocialShare
              url={courseLink}
              title={props.singleCourse.title}
              showLabel={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default courseCard;