import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

/***************************
 FETCH PAID SLOTS
 **************************/
export const fetchPaidSlotsStart = () => {
  return {
    type: actionTypes.FETCH_PAID_SLOTS_START
  };
};
export const fetchPaidSlotsSuccess = (paidSlots) => {
  return {
    type: actionTypes.FETCH_PAID_SLOTS_SUCCESS,
    paidSlots: paidSlots
  }
};
export const fetchPaidSlotsFail = (error) => {
  return {
    type: actionTypes.FETCH_PAID_SLOTS_FAIL,
    error: error
  }
};
export const fetchPaidSlots = (slug) => {
  return dispatch => {
    dispatch(fetchPaidSlotsStart());
    const headers = getAxiosHeader();
    axios.get('/courses/' + slug + '/paid-slots', headers)
      .then(res => {
        //console.log('fetchPaidSlotsSuccess', res.data.data);
        dispatch(fetchPaidSlotsSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('fetchPaidSlotsFail', err);
        dispatch(fetchPaidSlotsFail(err));
      });
  }
};
export const fetchPaidSlotsUnmount = () => {
  return {
    type: actionTypes.FETCH_PAID_SLOTS_UNMOUNT
  };
};

/***************************
 POST PAID SLOT
 **************************/
export const postPaidSlotStart = () => {
  return {
    type: actionTypes.POST_PAID_SLOT_START
  };
};
export const postPaidSlotSuccess = (paidSlot) => {
  return {
    type: actionTypes.POST_PAID_SLOT_SUCCESS,
    paidSlot: paidSlot
  }
};
export const postPaidSlotFail = (error) => {
  return {
    type: actionTypes.POST_PAID_SLOT_FAIL,
    error: error
  }
};
export const postPaidSlot = (slug) => {
  return dispatch => {
    dispatch(postPaidSlotStart());
    const headers = getAxiosHeader();
    axios.post('/courses/' + slug + '/paid-slots', null, headers)
    .then(res => {
      //console.log('postPaidSlot success', res.data.data);
      dispatch(postPaidSlotSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('postPaidSlot error', err);
      dispatch(postPaidSlotFail(err));
    });
  }
};