import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import PageHeader from '../layout/Instructor/PageHeader';
import Spinner from "../layout/UI/Spinner/Spinner";

import * as actions from "../../store/actions";
import {differenceFromToday} from "../../shared/utility";
import CourseBox from "../layout/CourseBox/CourseBox";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Pojedinacni predavac'
  },
  dataLayerName: 'PageDataLayer'
};

class SingleInstructor extends Component {

  componentWillMount() {
    const instructorSlug = this.props.match.params.slug;
    this.props.onFetchSingleInstructor(instructorSlug);
    this.props.onFetchSingleInstructorCourses(instructorSlug);
  }
  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.singleInstructor !== prevProps.singleInstructor) && this.props.singleInstructor.slug) {
      ReactGA.pageview(this.props.singleInstructor.slug);
    }
  }
  componentWillUnmount() {
    this.props.onSingleInstructorUnmount();
    this.props.onSingleInstructorCoursesUnmount();
  }
  
  render() {
    let biography = <Spinner />;
    if (!this.props.loading) {
      biography = ReactHtmlParser(this.props.singleInstructor.biography);
    }

    /* *
    * Featured Courses
    * */
    const coursesCount = Object.keys(this.props.singleInstructorCourses).length;
    
    let instructorsCourses = <Spinner/>;
    if (!this.props.loading && coursesCount > 0) {
      let counter = 0;
      instructorsCourses = Object.values(this.props.singleInstructorCourses).map(course => {
        const secondsForCountdown = differenceFromToday(course.startDate);
        counter++;
        return (
          (counter < 3) ? (
            <div className="col-lg-6 align-self-lg-stretch align-self-auto pb-md-0 pb-2" key={course.id}>
              <CourseBox
                course={course}
                secondsForCountdown={secondsForCountdown} />
            </div>
          ) : null
        )
      });
      if (instructorsCourses.length === 0) {
        instructorsCourses = null;
      }
    }
    
    return (
      <Fragment>
        <PageHeader instructor={this.props.singleInstructor}/>
        <section className="instructor-bio">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div className="instructor-bio__social">
                  {(this.props.singleInstructor.fbLink && this.props.singleInstructor.fbLink !== '') ? (
                    <a href={this.props.singleInstructor.fbLink} className="instructor-bio__social-link" target="_blank"><i className="icon icon-social-facebook"></i></a>
                  ) : ''}
                  {(this.props.singleInstructor.instagramLink && this.props.singleInstructor.instagramLink !== '') ? (
                    <a href={this.props.singleInstructor.instagramLink} className="instructor-bio__social-link" target="_blank"><i className="icon icon-social-instagram"></i></a>
                  ) : ''}
                  {(this.props.singleInstructor.twLink && this.props.singleInstructor.twLink !== '') ? (
                    <a href={this.props.singleInstructor.twLink} className="instructor-bio__social-link" target="_blank"><i className="icon icon-social-twitter"></i></a>
                  ) : ''}
                  {(this.props.singleInstructor.inLink && this.props.singleInstructor.inLink !== '') ? (
                    <a href={this.props.singleInstructor.inLink} className="instructor-bio__social-link" target="_blank"><i className="icon icon-social-linkedin"></i></a>
                  ) : ''}
                </div>
                <h2 className="instructor-bio__title title">Biografija</h2>
                <div className="instructor-bio__content">
                  {biography}
                </div>
              </div>
            </div>

            {(instructorsCourses.length > 0) ? (
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 instructor-courses">
                  <h2 className="title">Kursevi predavača</h2>
                  <div className="row">
                    {instructorsCourses}
                  </div>
                </div>
              </div>
            ) : null}
            
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.instructor.loading,
    singleInstructor: state.instructor.singleInstructor,
    singleInstructorCourses: state.instructor.singleInstructorCourses,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleInstructor: (slug) => dispatch(actions.fetchSingleInstructor(slug)),
    onSingleInstructorUnmount: () => dispatch(actions.singleInstructorUnmount()),
    onFetchSingleInstructorCourses: (slug) => dispatch(actions.fetchSingleInstructorCourses(slug)),
    onSingleInstructorCoursesUnmount: () => dispatch(actions.singleInstructorCoursesUnmount())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleInstructor));