import React from 'react';

const circularProgress = (props) => {
  return(
    <div className="circular-progress" data-percentage={props.percentage}>
      <span className="circular-progress-left">
        <span className={"circular-progress-bar " + (props.passed ? "success" : "danger")}></span>
      </span>
      <span className="circular-progress-right">
        <span className={"circular-progress-bar " + (props.passed ? "success" : "danger")}></span>
      </span>
      <div className="circular-progress-value">
        <div>
          {props.percentage}%
        </div>
      </div>
    </div>
  );
};

export default circularProgress;