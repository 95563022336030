import {updateObject} from "../../../shared/utility";

export const fetchTestInfoStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchTestInfoSuccess = (state, action) => {
  return updateObject(state, {
    testInfo: action.testInfo,
    error: null,
    loading: false
  });
};
export const fetchTestInfoFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchTestInfoUnmount = (state, action) => {
  return updateObject(state, {
    testInfo: {
      enabled: false,
      payedSlotPrice: "0",
      numberOfFreeAttempts: 0,
      passingScorePercent: 0,
      courseName: null
    },
    error: null,
    loading: false
  });
};