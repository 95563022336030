import React, {Component, Fragment} from 'react'
import MainSlider from "../layout/MainSlider/MainSlider";
import Benefits from "../layout/Benefits/Benefits";
import HomeCoursesWidget from "../layout/HomeCoursesWidget/HomeCoursesWidget";
import HomepageTestimonialsWidget from "../layout/HomepageTestimonialsWidget/HomepageTestimonialsWidget";
import HomepageNewsWidget from "../layout/HomepageNewsWidget/HomepageNewsWidget";
import HomepageMailchimpSubscribe from "../layout/HomepageMailchimpSubscribe/HomepageMailchimpSubscribe";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import PartnersSlider from "../layout/PartnersSlider/index";

const tagManagerArgs = {
  dataLayer: {
    page: 'pocetna'
  },
  dataLayerName: 'PageDataLayer'
};

class Homepage extends Component {

  componentWillMount() {
    this.props.onFetchCourses();
    this.props.onFetchBlog();
    this.props.onFetchTestimonials();
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('/');
  }

  componentWillUnmount() {
    this.props.onFetchTestimonialsUnmount();
  }

  render() {

    return(
      <Fragment>
        <MainSlider/>
        <Benefits/>
        <PartnersSlider />
        <HomeCoursesWidget
          courses={this.props.courses}
          user={this.props.user}
          loading={this.props.loading} />
        <HomepageTestimonialsWidget
          testimonials={this.props.testimonials}
          loading={this.props.testimonialsLoading}
        />
        <HomepageNewsWidget
          blog={this.props.blog}
          loading={this.props.blogLoading}
        />
        <HomepageMailchimpSubscribe />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    courses: state.course.courses,
    user: state.auth.user,
    blog: state.blog.blog,
    blogLoading: state.blog.loading,
    testimonials: state.testimonials.testimonials,
    testimonialsLoading: state.testimonials.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCourses: () => dispatch(actions.fetchCourses()),
    onFetchBlog: () => dispatch(actions.fetchBlog()),
    onFetchTestimonials: () => dispatch(actions.fetchTestimonials()),
    onFetchTestimonialsUnmount: () => dispatch(actions.fetchTestimonialsUnmount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);