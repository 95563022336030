import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const naturalPersonOrderStart = () => {
  return {
    type: actionTypes.NATURAL_PERSON_ORDER_START
  };
};
export const naturalPersonOrderSuccess = (order) => {
  return {
    type: actionTypes.NATURAL_PERSON_ORDER_SUCCESS,
    order: order
  }
};
export const naturalPersonOrderFail = (error) => {
  return {
    type: actionTypes.NATURAL_PERSON_ORDER_FAIL,
    error: error
  }
};
export const naturalPersonOrder = (order, id, productType, loggedIn) => {
  return dispatch => {
    dispatch(naturalPersonOrderStart());
    const headers = getAxiosHeader();
    const data = {
      personalOrderDetails: order,
      [productType]: id
    };
    const url = loggedIn ? '/orders/personal' : '/public-orders/personal';
    axios.post(url, data, headers)
    .then(res => {
      //console.log('naturalPersonOrderSuccess', res.data.data);
      dispatch(naturalPersonOrderSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('naturalPersonOrderFail error', err.response.data);
      dispatch(naturalPersonOrderFail(err.response.data));
    });
  }
};

export const legalEntityOrderStart = () => {
  return {
    type: actionTypes.LEGAL_ORDER_START
  };
};
export const legalEntityOrderSuccess = (order) => {
  return {
    type: actionTypes.LEGAL_ORDER_SUCCESS,
    order: order
  }
};
export const legalEntityOrderFail = (error) => {
  return {
    type: actionTypes.LEGAL_ORDER_FAIL,
    error: error
  }
};
export const legalEntityOrder = (order, id, productType, loggedIn) => {
  return dispatch => {
    dispatch(legalEntityOrderStart());
    const headers = getAxiosHeader();
    const data = {
      companyOrderDetails: order,
      [productType]: id
    };
    const url = loggedIn ? '/orders/company' : '/public-orders/company';
    axios.post(url, data, headers)
      .then(res => {
        //console.log('legalEntityOrderSuccess', res.data.data);
        dispatch(legalEntityOrderSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('legalEntityOrderFail error', err.response.data);
        dispatch(legalEntityOrderFail(err.response.data));
      });
  }
};

export const orderUnmount = () => {
    return {
        type: actionTypes.ORDER_UNMOUNT
    };
};

export const rejectOrderStart = () => {
  return {
    type: actionTypes.REJECT_ORDER_START
  };
};
export const rejectOrderSuccess = (order) => {
  return {
    type: actionTypes.REJECT_ORDER_SUCCESS,
    order: order
  }
};
export const rejectOrderFail = (error) => {
  return {
    type: actionTypes.REJECT_ORDER_FAIL,
    error: error
  }
};

export const rejectOrder = (orderId) => {
  return dispatch => {
    dispatch(rejectOrderStart());
    const headers = getAxiosHeader();
    axios.post('/orders/' + orderId + '/expired', null, headers)
        .then(res => {
          //console.log('rejectOrderSuccess', res.data.data);
          dispatch(rejectOrderSuccess(res.data.data));
        })
        .catch(err => {
          //console.log('rejectOrderFail', err.response.data);
          dispatch(rejectOrderFail(err.response.data));
        });
  }
}
