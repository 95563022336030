import * as actionTypes from '../../actions/actionTypes';

import { fetchTestimonialsStart, fetchTestimonialsSuccess, fetchTestimonialsFail} from './testimonials';

const initialState = {
  testimonials: [],
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_TESTIMONIALS_START:
      return fetchTestimonialsStart(state, action);
    case actionTypes.FETCH_TESTIMONIALS_SUCCESS:
      return fetchTestimonialsSuccess(state, action);
    case actionTypes.FETCH_TESTIMONIALS_FAIL:
      return fetchTestimonialsFail(state, action);

    default:
      return state;
  }
};

export default reducer;