import * as actionTypes from '../../actions/actionTypes';

import { contactFormStart, contactFormSuccess, contactFormFail, contactFormUnmount} from './contactForm';
import { requestCourseStart, requestCourseSuccess, requestCourseFail, requestCourseUnmount} from './requestCourse';

const initialState = {
  response: [],
  error: null,
  loading: false,
  formSent: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.CONTACT_FORM_START:
      return contactFormStart(state, action);
    case actionTypes.CONTACT_FORM_SUCCESS:
      return contactFormSuccess(state, action);
    case actionTypes.CONTACT_FORM_FAIL:
      return contactFormFail(state, action);
    case actionTypes.CONTACT_FORM_UNMOUNT:
      return contactFormUnmount(state, action);

    case actionTypes.REQUEST_COURSE_START:
      return requestCourseStart(state, action);
    case actionTypes.REQUEST_COURSE_SUCCESS:
      return requestCourseSuccess(state, action);
    case actionTypes.REQUEST_COURSE_FAIL:
      return requestCourseFail(state, action);
    case actionTypes.REQUEST_COURSE_UNMOUNT:
      return requestCourseUnmount(state, action);

    default:
      return state;
  }
};

export default reducer;