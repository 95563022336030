import React, {Fragment} from 'react';
import Countdown from "../UI/Countdown/Countdown";
import {getTestAttemptRemainingTime} from "../../../shared/utility";

const headerTest = (props) => {

  const remainingTime = getTestAttemptRemainingTime(props.startTime, props.duration);
  
  let testRemainingTime = null;
  if (remainingTime < 0) {
    testRemainingTime = <span className="header-test__message">Test je istekao!</span>;
  } else {
    testRemainingTime = (<Fragment>
      <span className="header-test__message">Vreme trajanja testa</span>
      <Countdown
        seconds={remainingTime}
        layout={'test'}
      />
    </Fragment>);
  }
  
  return (
    <div className="header-test">    
      <div className="js--navbar fixed-top navbar navbar-expand-lg navbar-dark bg-dark navbar-test">
        <div className="container justify-content-center align-items-center">
          {testRemainingTime}
        </div>
      </div>
      <Countdown
        seconds={remainingTime}
        start={parseInt(props.duration)}
        layout="progress"
      />
    </div>
  );
}

export default headerTest;