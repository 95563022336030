import * as actionTypes from '../../actions/actionTypes';

import { fetchInstructorsStart, fetchInstructorsSuccess, fetchInstructorsFail} from './instructors';
import { fetchSingleInstructorStart, fetchSingleInstructorSuccess, fetchSingleInstructorFail, singleInstructorUnmount} from './singleInstructor';
import { fetchSingleInstructorCoursesStart, fetchSingleInstructorCoursesSuccess, fetchSingleInstructorCoursesFail, singleInstructorCoursesUnmount} from './singleInstructorCourses';

const initialState = {
  instructors: [],
  singleInstructor: [],
  singleInstructorCourses: [],
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_INSTRUCTORS_START:
      return fetchInstructorsStart(state, action);
    case actionTypes.FETCH_INSTRUCTORS_SUCCESS:
      return fetchInstructorsSuccess(state, action);
    case actionTypes.FETCH_INSTRUCTORS_FAIL:
      return fetchInstructorsFail(state, action);

    case actionTypes.FETCH_SINGLE_INSTRUCTOR_START:
      return fetchSingleInstructorStart(state, action);
    case actionTypes.FETCH_SINGLE_INSTRUCTOR_SUCCESS:
      return fetchSingleInstructorSuccess(state, action);
    case actionTypes.FETCH_SINGLE_INSTRUCTOR_FAIL:
      return fetchSingleInstructorFail(state, action);
    case actionTypes.SINGLE_INSTRUCTOR_UNMOUNT:
      return singleInstructorUnmount(state, action);
      
    case actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_START:
      return fetchSingleInstructorCoursesStart(state, action);
    case actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_SUCCESS:
      return fetchSingleInstructorCoursesSuccess(state, action);
    case actionTypes.FETCH_SINGLE_INSTRUCTOR_COURSES_FAIL:
      return fetchSingleInstructorCoursesFail(state, action);
    case actionTypes.SINGLE_INSTRUCTOR_COURSES_UNMOUNT:
      return singleInstructorCoursesUnmount(state, action);

    default:
      return state;
  }
};

export default reducer;