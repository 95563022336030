import React, {Component} from 'react';
import {connect} from "react-redux";

import {
    formatStateForForm,
    inputChangedFunction,
    formatUserForForm,
    checkAxiosErrorFields,
    updateObject,
    setPlainPasswordAsOptionalField
} from "../../../shared/utility";
import * as actions from "../../../store/actions";

import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import PaymentType from "../UI/PaymentType/PaymentType";
import CheckoutTotal from "../UI/CheckoutTotal/CheckoutTotal";
import CheckboxTos from "../UI/Checkbox/CheckboxTos";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../UI/Spinner/Spinner";

class NaturalPersonForm extends Component {
    state = {
        form: {
            name: {
                order: 0,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'full',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Ime i prezime'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            email: {
                order: 1,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'full',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            plainPassword: {
                order: 2,
                elementType: 'input',
                elementClasses: 'form-control',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Lozinka',
                    autoComplete: 'current-password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            phone: {
                order: 3,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'half',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Broj telefona'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            country: {
                order: 4,
                elementType: 'select',
                elementClasses: 'form-control',
                elementWidth: 'half',
                elementConfig: {
                    placeholder: 'Country',
                    options: [
                        {value: '1', displayValue: 'Serbia'},
                        {value: '2', displayValue: 'Other'}
                    ]
                },
                value: '1',
                validation: {},
                valid: true,
                errorMessage: null,
                touched: false
            },
            city: {
                order: 5,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'half',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Grad'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            postCode: {
                order: 6,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'half',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Poštanski broj'
                },
                value: '',
                validation: {
                    required: true,
                    exact: 5
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            address: {
                order: 7,
                elementType: 'input',
                elementClasses: 'form-control',
                elementWidth: 'full',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Adresa'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: null,
                touched: false
            },
            organization: {
                order: 8,
                elementType: 'hidden',
                elementClasses: 'form-control',
                elementWidth: 'full',
                elementConfig: {
                    type: 'hidden',
                    placeholder: 'Organizacija'
                },
                value: '',
                validation: {
                    required: false
                },
                valid: true,
                errorMessage: null,
                touched: false
            }
        },
        formIsValid: false,
        paymentType: null,
        tosAccepted: false
    }

    NaturalPersonOrderHandler = (event) => {
        event.preventDefault();
        if (this.props.productId) {
            const orderData = {};
            for (let formElementIdentified in this.state.form) {
                orderData[formElementIdentified] = this.state.form[formElementIdentified].value;
            }
            orderData["paymentType"] = this.state.paymentType;
            this.props.onNaturalPersonOrder(orderData, this.props.productId, this.props.productType, this.props.loggedIn);
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
    }

    paymentTypeHandler = (type) => {
        this.setState(updateObject(this.state, {paymentType: type}));
    }

    tosAcceptedHandler = () => {
        this.setState(updateObject(this.state, {tosAccepted: !this.state.tosAccepted}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Form fields error mapping
        if (prevProps.error !== this.props.error && this.props.error !== null) {
            if (this.props.error.error.data) {
                this.setState(checkAxiosErrorFields(this.props.error.error.data.fields.personalOrderDetails, this.state));   
            } else if (parseInt(this.props.error.error.code) === 401) {
                this.setState(checkAxiosErrorFields({email: this.props.error.error.message}, this.state));
            }
        }

        if ((this.props.user !== prevProps.user) && this.props.loggedIn) {
            this.setState(formatUserForForm(this.state, this.props.user));
        }
    }

    componentDidMount() {
        if (this.props.loggedIn) {

            // Make plainPassword field optional for loggedIn users
            let stateClone = setPlainPasswordAsOptionalField(this.state);

            // Update form with user values
            let formattedUserFormState = formatUserForForm(stateClone, this.props.user);

            // Update state
            this.setState(formattedUserFormState);

        }
    }

    render() {
        const formElementsArray = formatStateForForm(this.state.form);
        let submitButton = <Spinner />
        if (!this.props.loading) {
            submitButton = <Button
                disabled={!this.state.formIsValid || !this.state.paymentType || !this.state.tosAccepted}
                btnType="btn-primary user-form__btn-submit"
                clicked={this.NaturalPersonOrderHandler}>Poruči</Button>;
        }
        return (
            <form onSubmit={this.NaturalPersonOrderHandler}>
                <div className="row">
                    <div className={'col-md-6'}>
                        <h4 className={'user-form__widget-title'}>Vaši podaci:</h4>
                        <div className="row">
                            {
                                formElementsArray.map((el) => {
                                    el.config.elementType = (['email', 'phone'].includes(el.id) && this.props.loggedIn) ? 'hidden' : el.config.elementType;
                                    el.config.elementConfig.type = (['email', 'phone'].includes(el.id) && this.props.loggedIn) ? 'hidden' : el.config.elementConfig.type;
                                    el.config.elementWidth = (['country'].includes(el.id) && this.props.loggedIn) ? 'full' : el.config.elementWidth;
                                    return ((el.id !== 'plainPassword' || !this.props.loggedIn) ?
                                            <div
                                                className={el.config.elementType === 'hidden' ? 'd-none' : ((el.config.elementWidth === 'half') ? 'col-12 col-lg-6' : 'col-12')}
                                                key={el.id}>
                                                <Input
                                                    class={el.config.elementClasses}
                                                    elementType={el.config.elementType}
                                                    elementConfig={el.config.elementConfig}
                                                    value={el.config.value ? el.config.value : ''}
                                                    invalid={!el.config.valid}
                                                    shouldValidate={el.config.validation}
                                                    touched={el.config.touched}
                                                    errorMessage={el.config.errorMessage}
                                                    changed={(event) => this.inputChangedHandler(event, el.id)}/>
                                            </div> : null
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={'col-md-6 d-flex align-items-end flex-column'}>
                        <PaymentType
                            client="naturalPerson"
                            checked={this.state.paymentType}
                            changed={(type) => this.paymentTypeHandler(type)}
                        />
                        <CheckoutTotal
                            class={'mt-auto w-100'}
                            productPrice={this.props.productPrice}
                            currency={ReactHtmlParser('&euro;')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CheckboxTos
                            client="naturalPerson"
                            checked={this.state.tosAccepted}
                            changed={this.tosAcceptedHandler}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 text-center">
                        { submitButton }
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.order.loading,
        error: state.order.errorNatural,
        user: state.auth.user,
        loggedIn: Object.keys(state.auth.user).length > 0
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNaturalPersonOrder: (orderData, productId, productType, loggedIn) => dispatch(actions.naturalPersonOrder(orderData, productId, productType, loggedIn)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NaturalPersonForm);