import {updateObject} from "../../../shared/utility";

export const fetchSinglePodcastStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSinglePodcastSuccess = (state, action) => {
  return updateObject(state, {
    singlePodcast: action.singlePodcast,
    error: null,
    loading: false
  });
};
export const fetchSinglePodcastFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const singlePodcastUnmount = (state, action) => {
  return updateObject(state, {
    singlePodcast: [],
    error: null,
    loading: false
  });
};