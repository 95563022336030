import React from 'react';
import Vimeo from "@u-wave/react-vimeo";

const aboutUsDirector = (props) => (
  <section className="about-director">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 d-flex align-items-start align-items-md-stretch">
          <div className="row no-gutters">
            <div className="col-lg-7">
              <div className="embed-responsive embed-responsive-16by9">
                <Vimeo video="447133782" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-director__text d-flex flex-column justify-content-center">
                <h3>Mi radimo, živimo i volimo digital!</h3>
                <p>Neprekidno učenje, testiranje novih kompetencija i ostvarivanje odličnih rezultata je nešto po čemu je Homepage prepoznatljiv i što prenosimo i na našu Akademiju. Ovo je temelj na osnovu kojeg biramo oblasti, teme i predavače koji dele svoje ekspertize.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default aboutUsDirector;