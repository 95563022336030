import React from 'react';
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {lessonUrlGenerator} from "../../../shared/utility";

const headerTestIntro = (props) => {
  return (
    <div className="header-test-intro">    
      <div className="js--navbar fixed-top navbar navbar-expand-lg navbar-light navbar-test bg-light">
        <div className="container">
          <Link className="header-test-intro__backlink" to={lessonUrlGenerator(props.courseSlug, props.lessonSlug)}>{ReactHtmlParser('&lt nazad na lekciju')}</Link>
        </div>
      </div>
    </div>
  );
}

export default headerTestIntro;