import React from 'react';
import { Link } from "react-router-dom";
import { formatDate } from '../../../shared/utility';
import SocialShare from "../UI/SocialShare/SocialShare";

const homepageNewsWidgetItemMain = (props) => {
  const blogDate = new Date(props.blog.createdAt);
  const blogUrl = process.env.PUBLIC_URL + "/novosti/" + props.blog.slug;
  return(
    <div className="main-news-item">
      <div className="main-news-item__img-box">
        <Link className="main-news-item__img-box__link" to={"/novosti/" + props.blog.slug}>
          <img className="main-news-item__img img-fluid" src={props.blog.image} alt={props.blog.name} />
        </Link>
      </div>
      <div className="main-news-item__details">
        <h3 className="news-item__title">
          <Link to={"/novosti/" + props.blog.slug}>{props.blog.name}</Link>
        </h3>
        <div className="news-item__text">{props.blog.excerpt}</div>
        <div className="news-item__date">{formatDate(blogDate)}</div>
        <div className="news-item__footer d-flex align-items-center justify-content-end">
          <Link className="read-more" to={"/novosti/" + props.blog.slug}>Opširnije</Link>
          <SocialShare
            url={blogUrl}
            title={props.blog.name}
            showLabel={false}
          />
        </div>
      </div>
    </div>
  );
};

export default homepageNewsWidgetItemMain;