import React from 'react';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

const lessonAbout = (props) => {
  return(
    <div className="lesson-details__about">
      <h2 className="title">O lekciji</h2>
      {ReactHtmlParser(props.description)}
      <div className="d-flex justify-content-end">
        {/*<Link className="lesson-details__more" to="#">više <i className="icon icon-arrow-up rotate-45"></i></Link>*/}
      </div>
    </div>
  );
};

export default lessonAbout;