import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import ReactHtmlParser from 'react-html-parser';
import PageHeader from '../layout/PageHeader/PageHeader';
import BlogItem from '../layout/Blog/BlogItem';
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-novosti.png";

const tagManagerArgs = {
  dataLayer: {
    page: 'Sve novosti'
  },
  dataLayerName: 'PageDataLayer'
};

class Blog extends Component {

  componentDidMount() {
    this.props.onFetchBlog();
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('novosti');
  }

  render() {
    let blog = <Spinner />;
    if (!this.props.loading) {
      let counter = 0;
      const propsBlog = Object.keys(this.props.blog);
      blog = propsBlog.map(key => {
        counter++;
        return (
          <Fragment key={key}>
            <BlogItem blog={this.props.blog[key]} />
            {(counter < propsBlog.length) ? (
              <hr className = "news-item__delimiter"/>
            ) : null }
          </Fragment>
        )
      });
      if (blog.length === 0) {
        blog = ReactHtmlParser('<p>Sekcija novosti je trenutno prazna.</p>');
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>Novosti - Homepage Academy</title>
          <meta name="description" content="Budi u toku" />
          <meta property="og:title" content="Saznaj više" />
          <meta property="og:description" content="Budi u toku" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>

        <PageHeader class={"news"}>
          Novosti
        </PageHeader>
        
        <section className="news-list">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                { blog }
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.blog.loading,
    blog: state.blog.blog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchBlog: () => dispatch(actions.fetchBlog())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);