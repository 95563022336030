import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {getTestAttemptRemainingTime, updateObject, lessonUrlGenerator} from "../../shared/utility";
import * as actions from "../../store/actions";
import TestResults from "../layout/TestResults/TestResults";
import TestQuestion from '../layout/Test/TestQuestion';
import Spinner from "../layout/UI/Spinner/Spinner";

class Test extends Component {
  
  state = {
    countDown: 0,
    submitted: false,
    givenQuestions: [],
    totalQuestionsCount: null
  }

  countDownTimer = null;

  redirectToCourseIfError = () => {
    //console.log('redirectToCourseIfError', this.props.testError);
    if (this.props.testError) {
      this.props.history.push(lessonUrlGenerator(this.props.match.params.course, this.props.match.params.lesson));
    }
  }
  
  testSubmitHandler = (e) => {
    e.preventDefault();
    this.submitTestAnswers();
    
    clearInterval(this.countDownTimer);
  };
  
  submitTestAnswers = () => {
    const answers = [...this.state.givenQuestions].map(item => {
      return {
        'question_id': item.id,
        'answers_ids': item.values
      }
    });
    this.props.onSubmitTest(this.props.test.testAttempt.id, answers);
    this.setState(updateObject(this.state, {
      submitted: true
    }));
  };

  questionReplyHandler = (questionId, answerId, checked, multiple) =>  {
    let clonedGivenQuestions = [...this.state.givenQuestions];
    clonedGivenQuestions = clonedGivenQuestions.map(q => {
      if (q.id == questionId) {
        if (multiple) {
          if (checked) {
            q.values.push(answerId);
          } else {
            q.values = q.values.filter(item => item != answerId); 
          }
        } else {
          q.values = [answerId];
        }
      }
      return q;
    });
    this.setState(updateObject(this.state, {
      givenQuestions: clonedGivenQuestions
    }));
  };
  
  autoSubmitCountDown = () => {
    this.countDownTimer = setInterval(() => {
            
      const remainingCount = this.state.countDown;
      const newCountDown = remainingCount - 1;
      this.setState(updateObject(this.state, {
        countDown: newCountDown
      }));
      
      if (newCountDown === 0) {
        this.submitTestAnswers();
        clearInterval(this.countDownTimer);
      }
    }, 1000);
  };
  
  componentDidMount() {
    this.props.onFetchTest(this.props.match.params.course);
    this.props.onFetchTestInfo(this.props.match.params.course);
    this.props.onFetchTestAttempts(this.props.match.params.course);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.test !== this.props.test && this.props.test.testAttempt) {
      let questionsCounter = 0;
      const stateGivenQuestions = this.props.test.testAttempt.givenQuestions.map(module => {
        const questions = module.limitedQuestions.map(question => {
          questionsCounter++;
          let correct = null;
          if (this.props.test.testAttempt.log) {
            const answerCorrect = this.props.test.testAttempt.log.filter(item => item.question_id == question.id);
            //console.log(answerCorrect[0].question_id, answerCorrect[0].correct);
            correct = answerCorrect[0].correct;
          }
          return {
            order: questionsCounter,
            id: question.id,
            multiple: question.multiple,
            text: question.text,
            hint: question.hint ? question.hint : null,
            answers: question.answers,
            correct: (this.props.test.testAttempt.log ? correct : null),
            values: []
          }
        });
        return questions;
      });

      const remainingTime = getTestAttemptRemainingTime(this.props.test.testAttempt.startTime, this.props.test.duration);
            
      this.setState(updateObject(this.state,{
        countDown: remainingTime,
        givenQuestions: stateGivenQuestions.flat(2),
        totalQuestionsCount: questionsCounter
      }));
      
      if (remainingTime > 0 && !this.state.submitted) {
        this.autoSubmitCountDown(remainingTime);
      }

      // If test is submitted after given time 
      if (this.props.test.testAttempt.endTime && this.props.test.testAttempt.correctQuestionsCount === null) {
        this.props.history.push(lessonUrlGenerator(this.props.match.params.course, this.props.match.params.lesson));
      }
      
      this.props.onFetchTestInfo(this.props.match.params.course);
      this.props.onFetchTestAttempts(this.props.match.params.course);
    }
    
    if (this.props.testError !== prevProps.testError) {
      this.redirectToCourseIfError();
    }
  }

  componentWillUnmount() {
    this.props.onFetchTestUnmount();
  }
  
  render() {
    let givenQuestions = <Spinner/>;

    const remainingTime = getTestAttemptRemainingTime(this.props.test.testAttempt.startTime, this.props.test.duration);
    //console.log('re-render remainingTime', remainingTime);
    if ((this.state.givenQuestions.length > 0) && !this.props.testLoading) {
      givenQuestions = this.state.givenQuestions.map(question => {
        return <TestQuestion
          key={question.id}
          id={question.id}
          multiple={question.multiple}
          ordinal={question.order}
          question={question.text}
          hint={question.hint}
          answers={question.answers}
          replied={(questionId, answerId, checked, multiple) => this.questionReplyHandler(questionId, answerId, checked, multiple)}
          correct={question.correct}
          readOnly={!isNaN(parseInt(this.props.test.testAttempt.scorePercent))}
        />;
      });
    }
    
    if (!isNaN(parseInt(this.props.test.testAttempt.scorePercent))) {
      window.scrollTo(0, 0);
    }
    
    return (
      <section className="test">
        <div className="container">
          <div className="row">
            {(!isNaN(parseInt(this.props.test.testAttempt.scorePercent))) ? (
              <div className="col-lg-8 offset-lg-2 text-center test-results">
                <TestResults
                  testAttempt={this.props.test.testAttempt}
                  totalQuestionsCount={this.state.totalQuestionsCount}
                  courseSlug={this.props.match.params.course}
                  lessonSlug={this.props.match.params.lesson}
                  courseName={this.props.testInfo.courseName}
                  isFree={this.props.testInfo.free}
                  numberOfRemainingFreeAttempts = {this.props.numberOfRemainingFreeAttempts}
                />
              </div>
            ) : null}
            <div className="col-lg-6 offset-lg-3">
              {givenQuestions}
              {(this.state.givenQuestions.length > 0 && isNaN(parseInt(this.props.test.testAttempt.scorePercent)) && !this.props.testLoading && (remainingTime > 0)) ?
                <Link to="/test-results" className="btn btn-lg btn-primary btn--submit-test" onClick={this.testSubmitHandler}>Pošalji odgovore</Link>
                : null
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    test: state.test.test,
    testError: state.test.error,
    testLoading: state.test.loading,
    testInfo: state.test.testInfo,
    numberOfRemainingFreeAttempts: state.test.testInfo.numberOfFreeAttempts - state.test.testAttempts.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchTest: (courseSlug) => dispatch(actions.fetchTest(courseSlug)),
    onFetchTestUnmount: () => dispatch(actions.fetchTestUnmount()),
    onSubmitTest: (attemptId, answers) => dispatch(actions.submitTest(attemptId, answers)),
    onFetchTestInfo: (courseSlug) => dispatch(actions.fetchTestInfo(courseSlug)),
    onFetchTestAttempts: (courseSlug) => dispatch(actions.fetchTestAttempts(courseSlug))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);