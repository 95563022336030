import {updateObject} from "../../../shared/utility";

export const lessonWatchedStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};
export const lessonWatchedSuccess = (state, action) => {
  const updateMeta = action.lessonMeta;
  const updateLesson = updateObject(state.lesson, {meta: updateMeta});
  return updateObject(state, {
    lesson: updateLesson,
    error: null,
  });
};
export const lessonWatchedFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

export const courseModulesUpdateLessonWatched = (state, action) => {
  const lessonId = action.lessonId;
  let updateCourseModules = {
    ...state.courseModules
  }
  for (let [keyModule, module] of Object.entries(state.courseModules)) {
    //console.log(module);
    let updateModule = {
      ...module
    }
    for (let [keyLesson, lesson] of Object.entries(module.lessons)) {
      if (lesson.id === lessonId) {
        const updateMeta = updateObject(lesson.meta, {watched: true});
        const updateLesson = updateObject(lesson, {meta: updateMeta});
        const updateLessons = updateObject(module.lessons, {[keyLesson]: updateLesson});
        updateModule = updateObject(updateModule, {lessons: updateLessons});
        //console.log(module, '::', updateModule);
      }
    }
    updateCourseModules = updateObject(updateCourseModules, {[keyModule]: updateModule});
  }
//  return state;
  return updateObject(state, {
    courseModules: updateCourseModules
  });
};
