import * as actionTypes from "../actionTypes";
import axios from "../../../axios";

/*****************************/
/* Reset Password Confirm */
/*****************************/

export const resetPasswordConfirmStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_CONFIRM_START
  };
};
export const resetPasswordConfirmSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_CONFIRM_SUCCESS
  };
};
export const resetPasswordConfirmFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_CONFIRM_FAIL,
    error: error
  };
};
export const resetPasswordConfirm = (plainPassword, confirmationToken) => {
  return dispatch => {
    dispatch(resetPasswordConfirmStart());
    axios.post('/users/reset-password', {
      plainPassword: plainPassword,
      confirmationToken: confirmationToken
      })
      .then(response => {
        //console.log('resetPasswordConfirm AXIOS response ', response);
        dispatch(resetPasswordConfirmSuccess());
      })
      .catch(err => {
        //console.log('resetPasswordConfirm AXIOS ERROR', err.response);
        dispatch(resetPasswordConfirmFail(err.response));
      });
  }
};