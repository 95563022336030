import React, {Component, Fragment} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";

import PageHeader from '../layout/PageHeader/PageHeader';
import Intro from '../layout/Instructors/Intro';
import Spinner from "../layout/UI/Spinner/Spinner";
import {Link} from "react-router-dom";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-predavaci.png";
import {Helmet} from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    page: 'Svi predavaci'
  },
  dataLayerName: 'PageDataLayer'
};

class Instructors extends Component {

  componentDidMount() {
    this.props.onFetchInstructors();
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('predavaci');
  }

  render() {
    let instructors = <Spinner />;
    if (!this.props.loading) {
      instructors = Object.keys(this.props.instructors).map(key => {
        return (
          <div className="col-md-6 col-xl-3 align-self-md-stretch align-self-auto mb-3 mb-md-5" key={key}>
            <div className="instructor text-center d-flex flex-column align-items-center justify-content-start">
              <img
                className="instructor__img"
                src={this.props.instructors[key].image}
                alt={this.props.instructors[key].name}/>
              <h3 className="instructor__name">{this.props.instructors[key].name}</h3>
              <h4 className="instructor__position">
                {this.props.instructors[key].title}
                <span className="instructor__company">{this.props.instructors[key].company}</span>
              </h4>
              <div className="instructor__text">
                {this.props.instructors[key].shortDescription}
              </div>
              <Link className="instructor__read-more btn btn-link mt-auto"
                 to={"/predavac/" + this.props.instructors[key].slug}>Više</Link>
            </div>
          </div>
        )
      });
    }

    return (
      <Fragment>
        <Helmet>
          <title>Predavači - Homepage Academy</title>
          <meta name="description" content="Uči od eksperata" />
          <meta property="og:title" content="Upoznaj naše predavače" />
          <meta property="og:description" content="Uči od eksperata" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>

        <PageHeader subtitle={"Znanje za bolje poslovanje"} class={"instructors"}>
          Homepage Academy
        </PageHeader>
        
        <Intro/>
        <section className="instructors">
          <div className="container">
            <div className="row">
              { instructors }
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.instructor.loading,
    instructors: state.instructor.instructors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchInstructors: () => dispatch(actions.fetchInstructors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Instructors);