import {updateObject} from "../../../shared/utility";

export const fetchSingleLessonStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleLessonSuccess = (state, action) => {
  return updateObject(state, {
    lesson: action.lesson,
    error: null,
    loading: false
  });
};
export const fetchSingleLessonFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const singleLessonUnmount = (state, action) => {
  return updateObject(state, {
    lesson: [],
    error: null,
    loading: false
  });
};