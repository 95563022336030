import React from 'react';
import { Link } from "react-router-dom";
import { formatDate } from '../../../shared/utility';
import ReactHtmlParser from 'react-html-parser';

const pageHeader = (props) => {
  const podcastDate = new Date(props.singlePodcast.createdAt);
  return(
    <div className="news-single-header">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Link className="news-single-header__backlink" to="/svi-podkasti">{ReactHtmlParser('&lt nazad na sve podkaste')}</Link>
            <h1 className="news-single-header__title">{props.singlePodcast.name}</h1>
            <p className="news-single-header__author">{props.singlePodcast.guest}</p>
            <p className="news-single-header__date">{formatDate(podcastDate)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageHeader;