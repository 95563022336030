import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import * as actions from "../../store/actions";

import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import Spinner from "../layout/UI/Spinner/Spinner";

const tagManagerArgs = {
  dataLayer: {
    page: 'Porudzbina uspesna'
  },
  dataLayerName: 'PageDataLayer'
};

class CheckoutSuccess extends Component {

  componentDidMount() {
    if (this.props.orderSuccess) {
      TagManager.dataLayer(tagManagerArgs);
      ReactGA.pageview('checkout-success');
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user !== prevProps.user) {
      this.props.history.push('/login');
    }
  }
  componentWillUnmount() {
    this.props.onOrderUnmount();
  }

  render() {
    let content = <Spinner/>;
    if (this.props.orderSuccess && this.props.user) {
      const docType = (this.props.orderType === 'natural') ? 'Uplatnicu' : 'Predračun';
      content = (
        <Fragment>
          <p className="user-form__message success text-left">Porudžbina je uspešna!</p>
          <p className="user-form__message success text-left">{docType} i informacije o načinu plaćanja poslali smo ti na email adresu kojom je izvšena registracija na platformu.</p>
          <p className="user-form__message success text-left">{docType} možeš preuzeti i <a href={this.props.order.invoice} target="_blank">OVDE</a>.</p>
        </Fragment>
      );
    }

    return (
      <section className="checkout">
        <div className="container">
          <div className="row">
            <div
              className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex align-items-center justify-content-center user-form">
              <div className="user-form__box">
                { content }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderSuccess: state.order.orderSuccess,
    order: state.order.order,
    orderType: state.order.orderType,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onOrderUnmount: () => dispatch(actions.orderUnmount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);