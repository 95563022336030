import React from 'react';

const RadioButton = (props) => {
    return (
        <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name={props.name}
                   id={props.id}
                   value={props.value}
                   onChange={props.changed}
                   checked={props.checked}
            />
            <label className="form-check-label" htmlFor={props.id}>
                {props.text}
            </label>
        </div>
    );
};

export default RadioButton;