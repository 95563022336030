import React from 'react';
import useWindowDimensions from "../../../hoc/hooks/useWindowDimensions";

const lessonAttachment = (props) => {
  const { height, width } = useWindowDimensions();
  const labelText = (width > 768) ? 'Preuzmi materijal iz lekcije' : 'materijal';
  return (
    <a className="lesson-player__option__link" href={props.file + "?token=" + props.token} target="_blank">
      <i className="icon icon-download lesson-player__icon lesson-player__icon--download"></i> <span className="lesson-player__option__span">{labelText}</span>
    </a>
  );
}

export default lessonAttachment;