import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const socialShare = (props) => {
  const shareLink = (e) => {
    e.preventDefault();
    navigator.share({
      title: props.title,
      url: props.url,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  };
  
  return (
    (navigator.share) ? (
      <ul className="social-share-mobile">
        <li>
          <a className="course-card__share__link d-flex align-items-center justify-content-center" href="#" onClick={(event) => shareLink(event)}>
            <i className="icon icon-share"></i>
            {(props.showLabel) ? ReactHtmlParser('<span>PODELI</span>') : ''}
          </a>
        </li>
      </ul>
    ) : (
      <ul className="social-share">
        <li>
          <a className="course-card__share__link d-flex align-items-center justify-content-center" href="#">
            <i className="icon icon-share"></i>
            {(props.showLabel) ? ReactHtmlParser('<span>PODELI</span>') : ''}
          </a>
        </li>
  
        <li>
          <a href={"https://www.facebook.com/sharer/sharer.php?u=" + props.url} target="_blank">
            <i className="icon icon-social-facebook"></i>
          </a>
        </li>
        <li>
          <a href={"https://twitter.com/intent/tweet?text=" + props.url} target="_blank">
            <i className="icon icon-social-twitter"></i>
          </a>
        </li>
        <li>
          <a
            href={"https://www.linkedin.com/shareArticle?mini=true&url=" + props.url + "&title=" + props.title + "&summary=&source="}
            target="_blank">
            <i className="icon icon-social-linkedin"></i>
          </a>
        </li>
      </ul>
    )
  );
};

export default socialShare;