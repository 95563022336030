import React, {Fragment, Component} from 'react';
import {NavLink} from "react-router-dom";

class UserMenuItems extends Component {
  render() {
    return (
      <Fragment>
        <NavLink className="dropdown-item" to="/favorite-lessons" onClick={this.props.clicked}><i className="icon icon-heart-empty"></i> Omiljene lekcije</NavLink>
        <NavLink className="dropdown-item" to="/my-courses" onClick={this.props.clicked}><i className="icon icon-my-courses"></i> Moji kursevi</NavLink>
        <NavLink className="dropdown-item" to="/change-profile" onClick={this.props.clicked}><i className="icon icon-profile"></i> Profil</NavLink>
        <NavLink className="dropdown-item" to="/change-password" onClick={this.props.clicked}><i className="icon icon-settings"></i> Promeni lozinku</NavLink>
        <NavLink className="dropdown-item" to="/logout"><i className="icon icon-logout"></i> Odjavi se</NavLink>
      </Fragment>
    );
  }
}

export default UserMenuItems;