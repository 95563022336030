import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Tab, Nav} from "react-bootstrap";
import {Link} from 'react-router-dom';

import * as actions from "../../store/actions";

import Spinner from "../layout/UI/Spinner/Spinner";
import LegalEntityForm from "../layout/Checkout/LegalEntityForm";
import NaturalPersonForm from "../layout/Checkout/NaturalPersonForm";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Poruci'
  },
  dataLayerName: 'PageDataLayer'
};

class Checkout extends Component {
  state = {
    checkoutFormShown: false,
  }

  tabButtonSelect = () => {
    if (!this.state.checkoutFormShown) {
      this.setState({
        checkoutFormShown: true
      });
    }
  }

  prevPathHandler = () => {
    const path = '/checkout/' + ((this.props.match.params.type && this.props.match.params.type == 'package')
        ? this.props.singleCoursePackage.slug + "/package"
        : this.props.singleCourse.slug);
    this.props.onSetAuthRedirectPath(path);
  };

  componentWillMount() {
    if(this.props.match.params.type && this.props.match.params.type == 'package') {
      this.props.onFetchSingleCoursePackage(this.props.match.params.slug);
    } else {
      this.props.onFetchSingleCourse(this.props.match.params.slug);
    }
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('checkout');
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevProps.state) {
      window.scrollTo(0, 110);
    }
    if (this.props.orderSuccess !== prevProps.orderSuccess) {
      if (this.props.orderSuccess && !this.props.orderLoading) {
        if (this.props.order.paymentType == 'card') {
          this.props.history.push('/checkout-card');
        } else {
          this.props.history.push('/checkout-success'); 
        }
      }
    }
    // Check is course already approved, if so redirect to it
    if (this.props.singleCourse.approved || this.props.singleCourse.purchased) {
      this.props.history.push('/kurs/' + this.props.singleCourse.slug);
    }
    if (this.props.singleCoursePackage.approved || this.props.singleCoursePackage.purchased) {
      this.props.history.push('/paket-kurseva/' + this.props.singleCoursePackage.slug);
    }
  }

  render() {
    let checkoutTabNavClasses = "checkout__tab-nav align-items-center justify-content-center";
    if (this.state.checkoutFormShown) {
      checkoutTabNavClasses += " active";
    }

    let checkoutLogin = !this.state.checkoutFormShown && !this.props.loggedIn ? <p className="checkout__login">Imaš nalog? <Link to={'/login'} onClick={this.prevPathHandler}>Uloguj se</Link></p> : null;

    let checkoutTabs = <Spinner/>;

    if (!this.props.courseLoading) {
      const productType = this.props.match.params.type == 'package' ? 'package' : 'course';
      const productId = productType === 'package' ? this.props.singleCoursePackage.id : this.props.singleCourse.id;
      const productPrice = productType === 'package' ? this.props.singleCoursePackage.price : this.props.singleCourse.discountPrice ? this.props.singleCourse.discountPrice : this.props.singleCourse.price;
      checkoutTabs = (
        <Fragment>
          <h4 className="user-form__subtitle">Kupovina { (productType === 'package') ? 'paketa' : 'kursa' }</h4>
          <h2 className="user-form__title">{ (productType === 'package') ? this.props.singleCoursePackage.name : this.props.singleCourse.name }</h2>

          <Tab.Container className="checkout-tab">

            <Nav
              variant="pills"
              className={checkoutTabNavClasses}
              onSelect={this.tabButtonSelect}>
              <Nav.Item>
                <Nav.Link eventKey="fizicko">Fizičko lice</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pravno">Pravno lice</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="checkout__tab-content">
              <Tab.Pane eventKey="fizicko">
                <NaturalPersonForm
                  productPrice={productPrice}
                  productId={productId}
                  productType={productType}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="pravno">
                <LegalEntityForm
                  productPrice={productPrice}
                  productId={productId}
                  productType={productType}
                />
              </Tab.Pane>
            </Tab.Content>

          </Tab.Container>
          {checkoutLogin}
        </Fragment>
      );
    }
    
    return (
      <section className="checkout">
        <div className="container">
          <div className="row">
            <div
              className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 d-flex align-items-center justify-content-center user-form">
              <div className="user-form__box">
                { checkoutTabs }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    courseLoading: state.course.loading,
    orderLoading: state.order.loading,
    singleCourse: state.course.singleCourse,
    singleCoursePackage: state.course.singleCoursePackage,
    orderSuccess: state.order.orderSuccess,
    order: state.order.order,
    orderType: state.order.orderType,
    user: state.auth.user,
    loggedIn: Object.keys(state.auth.user).length > 0
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleCourse: (slug) => dispatch(actions.fetchSingleCourse(slug)),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchSingleCoursePackage: (slug) => dispatch(actions.fetchSingleCoursePackage(slug))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);