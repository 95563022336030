/*****************************/
/* User me REDUCER functions */
/*****************************/
import { updateObject } from '../../../shared/utility';

export const getLoggedInUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    error: null
  });
};
export const getLoggedInUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
};