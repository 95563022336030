import React, {Fragment} from 'react';
import CourseBox from "./CourseBox";
import {differenceFromToday, getCourseString} from "../../../shared/utility";

const CoursePackageBoxes = (props) => {
    let output = null;
    if (Object.keys(props.courses).length > 0) {
        let courses = Object.values(props.courses).map(course => {
            const secondsForCountdown = differenceFromToday(course.course.startDate);
            return (
                <div className={"col-md-6 align-self-md-stretch align-self-auto pb-md-0 pb-2 mb-3"} key={course.id}>
                    <CourseBox
                        course={course.course}
                        secondsForCountdown={secondsForCountdown} />
                </div>
            )
        });
        const coursesCount = Object.keys(props.courses).length;
        const coursesCountSpell = getCourseString(coursesCount);
        output = <Fragment>
                    <div className={"col-12 mb-3"}>
                        <h3>{coursesCount} {coursesCountSpell} u ovom paketu</h3>
                    </div>
                    {courses}
                </Fragment>;
    }
    return output;
};

export default CoursePackageBoxes;