import React from 'react';
import {Modal} from "react-bootstrap";
import {formatDate} from "../../../shared/utility";

const paymentInfoModal = (props) => {

    const hide = () => {
        props.hide();
    }

    const icon = 'icon icon-' + ((props.paymentProcessorState === 'success') ? 'status-active' : 'status-fail');
    let successPaymentMessage;
    if (props.productType === 'Paket') {
        successPaymentMessage = 'Kursevima iz kupljenog paketa možete pristupiti iz svog Homepage Academy naloga. Potvrdu o plaćenom kursu smo vam prosledili na mejl adresu kojom ste registrovani na platformi.';
    } else {
        successPaymentMessage = props.courseStarted ?
            'Kursu možete pristupiti iz svog Homepage Academy naloga. Potvrdu o plaćenom kursu smo vam prosledili na mejl adresu kojom ste registrovani na platformi.' :
            'Kurs će biti dostupan na Homepage Academy platformi od ' + formatDate(new Date(props.startDate)) + ' Od tog datuma ćete biti u prilici da pristupite modulima i lekcijama iz svog naloga ili sa stranice kursa.';
    }
    const errorPaymentMessage = 'Vaš račun nije zadužen. Najčešći uzrok neuspešne kupovine je pogrešno unet broj kartice, sigurnosonog koda ili datum isteka. Pokušajte ponovo, a u slučaju uzastopnih grešaka pozovite vašu banku.';
    const errorTitle = (props.paymentProcessorState === 'success') ? 'Vaša kupovina je uspešna!' : 'Neuspešna kupovina!';
    const errorMessage = (props.paymentProcessorState === 'success') ? successPaymentMessage : errorPaymentMessage;
    const transactionDetails = (Object.keys(props.orderInfo).length ?
        <div className={'payment-processor-status-modal__order-details'}>
            <h4 className={'checkout--card__icon checkout--card__icon--cart'}>Detalji porudžbine</h4>
            <p><span>{props.productType}:</span> {props.courseName}</p>
            <p><span>Ukupna cena:</span> {props.orderInfo.amount}{props.orderInfo.currencySign}</p>
            <p><span>Broj porudžbine:</span> {props.orderInfo.orderId}</p>
            <p><span>Broj transakcije:</span> {props.orderInfo.transactionId}</p>
            <p><span>Datum transakcije:</span> {formatDate(new Date(props.orderInfo.date))}</p>
            <p><span>Auth code:</span> {props.orderInfo.authenticationCode}</p>
            <p><span>Trgovac:</span> Homepage doo, Kisačka 47, 21000 Novi Sad, PIB: 107609128</p>
        </div> :
        <div className={'payment-processor-status-modal__order-details'}>
            <h5>{(props.productType === 'Paket') ? 'Kursevima iz paketa' : 'Kursu'} možeš pristupiti tako što ćeš svoj <strong>nalog potvrditi klikom na verifikacioni link koji ti je poslat na email adresu</strong> (kojom si se registrovao/la) i ulogovati se.</h5>
        </div>
    );
    return (
        <Modal
            show={props.paymentProcessorState !== null}
            onHide={hide}
            size={'lg'}
            dialogClassName={'payment-processor-status-modal'}
            centered
        >
            <Modal.Header closeButton className={'d-flex'}>
                <div className={'modal-title__wrapper d-flex flex-column align-items-center justify-content-center'}>
                    <i className={icon}></i>
                    <Modal.Title>{ errorTitle }</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>{errorMessage}</p>
                {transactionDetails}
            </Modal.Body>
        </Modal>  
    );
};

export default paymentInfoModal;