import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchInstructorsStart = () => {
  return {
    type: actionTypes.FETCH_INSTRUCTORS_START
  };
};
export const fetchInstructorsSuccess = (instructors) => {
  return {
    type: actionTypes.FETCH_INSTRUCTORS_SUCCESS,
    instructors: instructors
  }
};
export const fetchInstructorsFail = (error) => {
  return {
    type: actionTypes.FETCH_INSTRUCTORS_FAIL,
    error: error
  }
};
export const fetchInstructors = () => {
  return dispatch => {
    dispatch(fetchInstructorsStart());

    const headers = getAxiosHeader();
    axios.get('/instructors', headers)
    .then(res => {
      //console.log('fetchInstructors res', res.data.data);
      dispatch(fetchInstructorsSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('fetchInstructors error', err);
      dispatch(fetchInstructorsFail(err));
    });
  }
};