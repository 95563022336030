import React, { Fragment } from 'react';
import {Link} from "react-router-dom";
import TestResultsIcons from "./TestResultsIcons";
import TestResultsTitle from "./TestResultsTitle";
import ReactHtmlParser from "react-html-parser";
import TestResultsBox from "./TestResultsBox";
import CircularProgress from "../UI/CircularProgress/CircularProgress";
import {ProgressBar} from "react-bootstrap";
import TestResultsCert from "./TestResultsCert";
import {getFreeAttemptsString, lessonUrlGenerator, NEXT_AVAILABLE_TEST_ATTEMPT_DAYS} from "../../../shared/utility";

const testResults = (props) => {
  const testResultCertBoxMessageSuccess = ReactHtmlParser('Preuzmi sertifikat o položenom testu');
  const freeAttemptsString = getFreeAttemptsString(props.numberOfRemainingFreeAttempts === 1);
  const testResultCertBoxMessageFail = !props.isFree ? ((props.numberOfRemainingFreeAttempts > 0) ? 'Imate još ' + props.numberOfRemainingFreeAttempts + ' ' + freeAttemptsString : 'Nemate više besplatnih pokušaja') : ('Test možeš ponovo polagati za ' + NEXT_AVAILABLE_TEST_ATTEMPT_DAYS + ' dana');
  const testAttemptMessageFailed = (props.numberOfRemainingFreeAttempts > 0) ?
    'Test možeš ponovo polagati za ' + NEXT_AVAILABLE_TEST_ATTEMPT_DAYS + ' dana. Potrudi se da odslušaš sve lekcije kursa kako bi naredno testiranje bilo uspešno i kako bi dobio/la sertifikat o završenom kursu "<a href="' + lessonUrlGenerator(props.courseSlug, props.lessonSlug) + '">' + props.courseName + '</a>".' :
    'Novo testiranje će ti biti omogućeno nakon što registrujemo tvoju uplatu. Potrudi se da odslušaš sve lekcije kursa kako bi naredno testiranje bilo uspešno i kako bi dobio/la sertifikat o završenom  kursu "<a href="' + lessonUrlGenerator(props.courseSlug, props.lessonSlug) + '">' + props.courseName + '</a>".';
  const testAttemptMessageSuccess = 'Nadamo se da ćeš stečeno znanje uspešno koristiti u svom daljem profesionalnom razvoju. Ukoliko želiš da nadogradiš svoje znanje u nekoj drugoj oblasti, pogledaj i ostale <a href="/kursevi">aktuelne kurseve</a> Homepage akademije. Hvala ti na poverenju!';
  return(
    <Fragment>
      <TestResultsIcons passed={props.testAttempt.passed} />
      <TestResultsTitle passed={props.testAttempt.passed} />

      <div className="row test-results__boxes">
        <div className="col-lg-4">
          <TestResultsBox title={ReactHtmlParser("Procenat tačnosti<br/>odgovora")}>
            <CircularProgress passed={props.testAttempt.passed} percentage={props.testAttempt.scorePercent}/>
          </TestResultsBox>
        </div>
        <div className="col-lg-4">
          <TestResultsBox title={ReactHtmlParser("Broj ukupnih pitanja/broj tačnih odgovora")}>
            <span className="test-results__box__questions-result">{props.testAttempt.correctQuestionsCount}/{props.totalQuestionsCount}</span>
            <ProgressBar variant={props.testAttempt.passed ? "success" : "danger"} now={props.testAttempt.scorePercent} />
          </TestResultsBox>
        </div>
        <div className="col-lg-4">
          <TestResultsBox title={props.testAttempt.passed ? testResultCertBoxMessageSuccess : testResultCertBoxMessageFail}>
            <TestResultsCert
              passed={props.testAttempt.passed} 
              cert={props.testAttempt.certificate}
              title={props.testAttempt.passed ? testResultCertBoxMessageSuccess : testResultCertBoxMessageFail}
            />
          </TestResultsBox>
        </div>
      </div>

      <div className="test-results__message">
        { ReactHtmlParser(props.testAttempt.passed ? testAttemptMessageSuccess : testAttemptMessageFailed) }
      </div>
      <Link className="test-results__backlink btn btn-primary btn-lg" to={lessonUrlGenerator(props.courseSlug, props.lessonSlug)}>{ReactHtmlParser('&lt Povratak na kurs')}</Link>
      <h3 className="test-results__subtitle">Rezultati testa:</h3>
    </Fragment>
  );
};

export default testResults;