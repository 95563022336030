import React, {useRef, useState} from 'react';
import { Overlay } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";

const infoTooltip = (props) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const tooltipMessage = props.message;
    return (
        <>
            <Button variant="link" className="info-tooltip__button" ref={target} onClick={() => setShow(!show)}>
                <i className="icon icon-info"></i>
            </Button>
            <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                    <Tooltip className="info-tooltip__overlay" {...props}>
                        {tooltipMessage}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
};

export default infoTooltip;