import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const lessonWatchedStart = () => {
  return {
    type: actionTypes.LESSON_WATCHED_START
  };
};
export const lessonWatchedSuccess = (lessonMeta) => {
  return {
    type: actionTypes.LESSON_WATCHED_SUCCESS,
    lessonMeta: lessonMeta
  }
};
export const lessonWatchedFail = (error) => {
  return {
    type: actionTypes.LESSON_WATCHED_FAIL,
    error: error
  }
};
export const lessonWatched = (slug) => {
  return dispatch => {
    dispatch(lessonWatchedStart());
    const headers = getAxiosHeader();
    axios.post('/lessons/' + slug + '/watched', null, headers)
      .then(res => {
        //console.log('lessonWatched success', res.data.data);
        dispatch(lessonWatchedSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('lessonWatched error', err);
        dispatch(lessonWatchedFail(err));
      });
  }
};

export const courseModulesUpdateLessonWatched = (lessonId) => {
  return {
    type: actionTypes.COURSE_MODULES_UPDATE_LESSON_META,
    lessonId: lessonId
  }
};
