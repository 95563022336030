/***************************/
/* Login REDUCER functions */
/***************************/
import { updateObject } from '../../../shared/utility';

export const loginStart = (state, action) => {
  return updateObject(state, {
    token: null,
    error: null,
    loading: true,
    confirmedRegistration: false,
  });
};
export const loginSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false
  });
};
export const loginFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};