import React, {Fragment} from 'react';
import BlogItem from "../Blog/BlogItem";

const relatedBlog = (props) => {
  const blogCount = Object.keys(props.blog).length;
  let blog = null;
  let result = null;
  if (blogCount > 1) {
    let counter = 0;
    blog = Object.values(props.blog).map(blogItem => {
      counter++;
      return (
        (props.singleBlogId !== blogItem.id) ? (
          <Fragment key={blogItem.id}>
            <BlogItem blog={blogItem} />
            {(counter < blogCount) ? (
              <hr className = "news-item__delimiter"/>
            ) : null }
          </Fragment>
        ) : null
      )
    });
    if (blog.length > 0) {
      result = (
        <div className="news-single__related">
          <h3>Povezane vesti</h3>
          {blog}
        </div>
      );
    }
  }

  return result;
};

export default relatedBlog;