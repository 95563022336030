/***************************/
/* Change password ACTIONS */
/***************************/

import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START
  };
};
export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS
  }
};
export const changePasswordFail = (error) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL,
    error: error
  }
};
export const changePassword = (oldPassword, plainPassword, token) => {
  return dispatch => {
    dispatch(changePasswordStart());
    const headers = getAxiosHeader();
    const data = {
      oldPassword: oldPassword,
      plainPassword: plainPassword
    };
    axios.put('/users/me/change-password', data, headers)
      .then(response => {
        //console.log('changePassword AXIOS response ', response);
        dispatch(changePasswordSuccess());
      })
      .catch(err => {
        //console.log('changePassword AXIOS catch err ', err.response.status);
        dispatch(changePasswordFail(err.response));
      });
  }
};