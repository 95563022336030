import React, {Component} from 'react';
import {connect} from "react-redux";

import CourseBox from "../layout/CourseBox/CourseBox";
import Spinner from "../layout/UI/Spinner/Spinner";

import UserAccount from "../../hoc/Layouts/UserAccount";
import * as actions from "../../store/actions";
import {differenceFromToday} from "../../shared/utility";
import {Link} from "react-router-dom";

class MyCourses extends Component {
  componentDidMount() {
    this.props.onFetchMyCourses();
  }

  render() {
    let courses = <Spinner />;
    if (!this.props.loading) {
      courses = Object.values(this.props.myCourses).map(course => {
        const secondsForCountdown = differenceFromToday(course.startDate);
        return (
          <div className="col-md-6 align-self-md-stretch align-self-auto pb-md-0 pb-2 mb-3" key={course.id}>
            <CourseBox
              hidePrice={true}
              course={course}
              secondsForCountdown={secondsForCountdown} />
          </div>
        )
      });
      if (courses.length === 0) {
        courses = (
          <div className="text-center text-lg-left">
            <p>Nema aktivnih mojih kurseva</p>
            <Link to={ "/kursevi" } className="btn btn-primary">Pogledaj aktuelne kurseve</Link>
          </div>
        );
      }
    }

    return (
      <UserAccount>
        <h2 className="user-account__main__title">Moji kursevi</h2>
        <div className="user-account__main__content">
          <div className="row">
            { courses }
          </div>
        </div>
      </UserAccount>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    myCourses: state.course.myCourses,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchMyCourses: () => dispatch(actions.fetchMyCourses())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses);