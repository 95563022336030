import {updateObject} from "../../../shared/utility";

export const fetchCoursePackagesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};
export const fetchCoursePackagesSuccess = (state, action) => {
    return updateObject(state, {
        coursePackages: action.coursePackages,
        error: null,
        loading: false
    });
};
export const fetchCoursePackagesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};