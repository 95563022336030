import React, {Fragment} from 'react';
import ReactHtmlParser from "react-html-parser";

const CheckoutTotal = (props) => {
    let widgetClasses = [props.class];
    widgetClasses.push('widget__checkout-total');
    const currency = props.currency ?? ReactHtmlParser('&euro;');
    return (
        <div className={widgetClasses.join(' ')}>
            <h4 className="user-form__widget-title underline">Cena sa PDV-om:</h4>
            <div className="checkout__total">{ parseFloat(props.productPrice).toFixed(2) + currency }</div>
        </div>
    );
};

export default CheckoutTotal;