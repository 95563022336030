import * as actionTypes from "../actionTypes";
import axios from "../../../axios";

/*****************************/
/* User Registration Confirm */
/*****************************/

export const userRegistrationConfirmStart = () => {
  return {
    type: actionTypes.REGISTRATION_CONFIRM_START
  };
};
export const userRegistrationConfirmSuccess = () => {
  return {
    type: actionTypes.REGISTRATION_CONFIRM_SUCCESS
  };
};
export const userRegistrationConfirmFail = (error) => {
  return {
    type: actionTypes.REGISTRATION_CONFIRM_FAIL,
    error: error
  };
};
export const userRegistrationConfirm = (confirmationToken) => {
  return dispatch => {
    dispatch(userRegistrationConfirmStart());
    axios.post('/users/confirm', {confirmationToken: confirmationToken})
      .then(response => {
        //console.log('userRegistrationConfirm AXIOS response ', response);
        dispatch(userRegistrationConfirmSuccess());
      })
      .catch(err => {
        //console.log('userRegistrationConfirm AXIOS ERROR', err.response, err.response.status);
        dispatch(userRegistrationConfirmFail(err.response.status));
      });
  }
};