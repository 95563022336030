import React, {Component} from 'react';
import {connect} from "react-redux";

import Spinner from "../layout/UI/Spinner/Spinner";

import UserAccount from "../../hoc/Layouts/UserAccount";
import * as actions from "../../store/actions";

class FavoriteLessons extends Component {
  componentDidMount() {
    this.props.onFetchFavoriteLessons();
  }

  render() {
    let favoriteLessons = <Spinner />;
    if (!this.props.loading) {
      favoriteLessons = Object.values(this.props.favoriteLessons).map(lesson => {
        return (
          <div className="col-12" key={lesson.id}>
            <a className="favorite-lessons__link" href={"/kurs/" + lesson.module.course.slug + "/lekcija/" + lesson.slug}>{lesson.name}</a>
          </div>
        )
      });
      if (favoriteLessons.length === 0) {
        favoriteLessons = (
          <div className="col-12 d-flex align-items-center start">
            <h3 className="text-center">Nemate označenih omiljenih lekcija</h3>
          </div>
        );
      }
    }

    return (
      <UserAccount>
        <h2 className="user-account__main__title">Omiljene lekcije</h2>
        <div className="user-account__main__content">
          <div className="row favorite-lessons">
            { favoriteLessons }
          </div>
        </div>
      </UserAccount>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    favoriteLessons: state.course.favoriteLessons
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchFavoriteLessons: () => dispatch(actions.fetchFavoriteLessons())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteLessons);