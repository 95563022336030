import React, {Component, Fragment} from 'react';
import { ProgressBar } from "react-bootstrap";
import {getDayString, getSecondString, getMinuteString, getHourString} from "../../../../shared/utility";

class Countdown extends Component {
  state = {
    start: 0,
    count: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    percentage: 100
  }

  getTime = (sec) => {
    const days = Math.floor(sec / 86400);
    const hours = Math.floor((sec - (days * 86400)) / 3600);
    const minutes = Math.floor((sec - (days * 86400) - (hours * 3600)) / 60);
    const seconds = sec - (days * 86400) - (hours * 3600) - (minutes * 60);
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    }
  }
  
  getPercentage = (start, current) => {
    return parseInt(current)*100/parseInt(start);
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      const remainingCount = this.state.count;
      if (remainingCount === 0) {
        clearInterval(this.timer);
      }
      const {days, hours, minutes, seconds} = this.getTime(remainingCount);
      const percentage = this.getPercentage(this.state.start, remainingCount);
      const newCount = remainingCount - 1;
      const newState = {
        ...this.state,
        count: newCount >= 0 ? newCount : 0,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
      if (this.state.start > 0) {
        newState.percentage = percentage;
      }
      this.setState(newState);
    }, 1000);
  }

  componentDidMount() {
    const seconds = this.props.seconds;
    const newState = {
      ...this.state,
      count: seconds
    };
    if (this.props.start && this.props.start > 0) {
      newState.start = this.props.start;
    }
    this.setState(newState);
    this.startCountdown();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const layout = (this.props.layout) ? this.props.layout : null;
    let countdown = null;
    if (this.state.seconds >= 0) {
      switch (layout) {
        case 'header' :
          countdown = (
            <Fragment>
              <i className="icon icon-hourglass"></i>
              <div className="countdown-header d-flex justify-content-center align-items-center">
                <div className="countdown-header__box"><span className="countdown-header__number">{this.state.days}</span>d</div>
                <div className="countdown-header__box"><span className="countdown-header__number">{this.state.hours}</span>h</div>
                <div className="countdown-header__box"><span className="countdown-header__number">{this.state.minutes}</span>min</div>
              </div>
            </Fragment>
          );
          break;
        case 'test' :
          countdown = (
            <div className="countdown-test d-flex justify-content-center">
              <span className="countdown-test__number">{this.state.hours.toString().padStart(2, '0')}</span><span className="countdown-test__delimeter">:</span>
              <span className="countdown-test__number">{this.state.minutes.toString().padStart(2, '0')}</span><span className="countdown-test__delimeter">:</span>
              <span className="countdown-test__number">{this.state.seconds.toString().padStart(2, '0')}</span>
            </div>
          );
          break;
        case 'progress' :
          countdown = (
            <div className="countdown-progress">
              <ProgressBar now={this.state.percentage} animated />
            </div>
          );
          break;
        default :
          countdown = (
            <div className="countdown">
              <div className="countdown__box"><span className="countdown__number">{this.state.days}</span><span
                className="countdown__label">{getDayString(this.state.days)}</span></div>
              <div className="countdown__box"><span className="countdown__number">{this.state.hours}</span><span
                className="countdown__label">{getHourString(this.state.hours)}</span></div>
              <div className="countdown__box"><span className="countdown__number">{this.state.minutes}</span><span
                className="countdown__label">{getMinuteString(this.state.minutes)}</span></div>
              <div className="countdown__box"><span className="countdown__number">{this.state.seconds}</span><span
                className="countdown__label">{getSecondString(this.state.seconds)}</span></div>
            </div>
          );
          break;
      }
    }
    return countdown;
  }
}

export default Countdown;