import {updateObject} from "../../../shared/utility";

/***************************
 FETCH PAID SLOTS
 **************************/
export const fetchPaidSlotsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchPaidSlotsSuccess = (state, action) => {
  return updateObject(state, {
    paidSlots: action.paidSlots,
    error: null,
    loading: false
  });
};
export const fetchPaidSlotsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchPaidSlotsUnmount = (state, action) => {
  return updateObject(state, {
    paidSlots: null,
    error: null,
    loading: false
  });
};

/***************************
 POST PAID SLOT
 **************************/
export const postPaidSlotStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};
export const postPaidSlotSuccess = (state, action) => {
  return updateObject(state, {
    paidSlot: action.paidSlot,
    error: null,
  });
};
export const postPaidSlotFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};