/**********************************/
/* Registration REDUCER functions */
/**********************************/
import { updateObject } from '../../../shared/utility';

export const registrationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const registrationSuccess = (state, action) => {
  return updateObject(state, {
    // user: state.user.concat(newUser),
    user: action.user,
    error: null,
    loading: false
  });
};
export const registrationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const registrationUnmount = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
  });
};