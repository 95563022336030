import React, { Component } from 'react';
import UserMenuItems from "../../UserMenuItems/UserMenuItems";
import { Dropdown } from "react-bootstrap";

class UserDropdown  extends Component {
  state = {
    isOpen: false
  }

  dropdownSelected = () => {
    this.setState({isOpen: false});
  }

  dropdownToggled = () => {
    this.setState((prevState) => {
      return {isOpen: !prevState.isOpen}
    });
  }

  render() {
    //console.log('Render UserDropdown', this.state.isOpen);
    const nameInitial = this.props.user.name ? this.props.user.name.charAt(0) : '';

    return (
      this.props.user.name ? 
      <Dropdown show={this.state.isOpen} className="user-menu d-none d-md-block" onToggle={this.dropdownToggled}>
        <Dropdown.Toggle variant="link" className="user-menu__button" id="dropdownUserMenu">
          <span className="user-menu__avatar">{nameInitial}</span>
          <span className="user-menu__name d-none d-md-block">{this.props.user.name}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-right">
          <div className="user-menu__user">
            <span className="user-menu__avatar">{nameInitial}</span>
            <div className="user-menu__details">
              <span className="user-menu__name user-menu__name--mobile">{this.props.user.name}</span>
              <span className="user-menu__email">{this.props.user.email}</span>
            </div>
          </div>
          <UserMenuItems clicked={this.dropdownSelected}/>
        </Dropdown.Menu>
      </Dropdown> : null
    )
  }
}

export default UserDropdown;