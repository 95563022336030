import React from 'react';

const testResultsBox = (props) => {
  return (
    <div className="test-results__box">
      {props.children}
      <p className="test-results__box__title">{props.title}</p>
    </div>
  );
}

export default testResultsBox;