import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import {applyMiddleware, compose, createStore, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import "./shared/exteranal";

import App from "./components/App";

import authReducer from "./store/reducers/auth/auth";
import courseReducer from "./store/reducers/course/course";
import orderReducer from "./store/reducers/order/order";
import instructorReducer from "./store/reducers/instructor/index";
import blogReducer from "./store/reducers/blog/index";
import emailFormReducer from "./store/reducers/emailForm/index";
import testimonialsReducer from "./store/reducers/testimonials/index";
import cookieReducer from "./store/reducers/cookie/index";
import siteInfoReducer from "./store/reducers/siteInfo/index";
import podcastsReducer from "./store/reducers/podcast/index";
import testReducer from "./store/reducers/test/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  course: courseReducer,
  order: orderReducer,
  instructor: instructorReducer,
  blog: blogReducer,
  emailForm: emailFormReducer,
  testimonials: testimonialsReducer,
  cookie: cookieReducer,
  siteInfo: siteInfoReducer,
  podcasts: podcastsReducer,
  test: testReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

/* Google Tag Manager initialization */
const tagManagerArgs = {
  gtmId: 'GTM-M78L88K',
  dataLayerName: 'PageDataLayer'
}
TagManager.initialize(tagManagerArgs);

/* Google Analytics initialization */
ReactGA.initialize('UA-30238296-5');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'));
