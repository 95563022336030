import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const setLessonNotesStart = () => {
  return {
    type: actionTypes.SET_LESSON_NOTES_START
  };
};
export const setLessonNotesSuccess = (lessonMeta) => {
  return {
    type: actionTypes.SET_LESSON_NOTES_SUCCESS,
    lessonMeta: lessonMeta
  }
};
export const setLessonNotesFail = (error) => {
  return {
    type: actionTypes.SET_LESSON_NOTES_FAIL,
    error: error
  }
};
export const setLessonNotes = (slug, note) => {
  return dispatch => {
    dispatch(setLessonNotesStart());
    const headers = getAxiosHeader();
    const data = {
      note: note
    };
    axios.post('/lessons/' + slug + '/note', data, headers)
      .then(res => {
        //console.log('setLessonNotes success', res.data.data);
        dispatch(setLessonNotesSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('setLessonNotes error', err);
        dispatch(setLessonNotesFail(err));
      });
  }
};
