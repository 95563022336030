import React, {Component, Fragment} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';

import LessonAttachment from "../layout/Lesson/LessonAttachment";
import LessonBookmark from "../layout/Lesson/LessonBookmark";
import LessonWatched from "../layout/Lesson/LessonWatched";
import LessonOptionsMobile from "../layout/Lesson/LessonOptionsMobile";
import LessonPlayer from "../layout/Lesson/LessonPlayer";
import LessonNotes from "../layout/Lesson/LessonNotes";
import LessonAbout from "../layout/Lesson/LessonAbout";
import LessonInstructors from "../layout/Lesson/LessonInstructors";
import LessonTestBox from "../layout/Lesson/LessonTestBox";
import ModulesAndLessonsAccordion from "../layout/Lesson/ModulesAndLessonsAccordion";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Spinner from "../layout/UI/Spinner/Spinner";
import {updateObject, differenceFromToday, getNextLesson, formatSecondsToDays, NEXT_AVAILABLE_TEST_ATTEMPT_SECONDS, NEXT_AVAILABLE_TEST_ATTEMPT_DAYS} from "../../shared/utility";
import CourseBox from "../layout/CourseBox/CourseBox";

const WAIT_INTERVAL = 1500; // Typing delay before saving notes interval
const ENTER_KEY = 13;

class Lesson extends Component {

  state = {
    note: '',
    noteCollapsed: false,
    hasVideo: false,
    testButtonLoading: false
  }

  watchedHandler = (slug) => {
    this.props.onLessonWatched(slug);
  };
  
  nextLessonHandler = (slug) => {
    const nextLesson = getNextLesson(slug, this.props.courseModules);
    //console.log('nextLesson', nextLesson);
    if (nextLesson) {
      const redirectLink = "/kurs/" + this.props.singleCourse.slug + "/lekcija/" + nextLesson;
      //console.log('redirectLink', redirectLink);
      const history = this.props.history;
      setTimeout(function () {
        history.push(redirectLink);
      }, 1500);
    }
  };
  
  favoriteHandler = (slug) => {
    this.props.onLessonFavorite(slug);
  };

  noteChangedHandler = (note) => {
    clearTimeout(this.timer);
    this.setState(updateObject(this.state, {note: note}));
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  triggerChange = () => {
    //console.log('triggerChange with delay: ', this.state.note, this.props.match.params.slug);
    this.props.onSetLessonNotes(this.props.match.params.slug, this.state.note);
  };

  noteCollapsedHandler = (opened) => {
    //console.log('noteCollapsedHandler', opened);
    this.setState(updateObject(this.state, {noteCollapsed: opened}));
  };

  prevPathHandler = () => {
    this.props.onSetAuthRedirectPath(this.props.history.location.pathname);
  };

  createInvoiceHandler = () => {
    this.setState(updateObject(this.state, {testButtonLoading: true}));
    this.props.onPostPaidSlot(this.props.match.params.kurs);
  };
  
  getExistingPaidSlot = (paidSlots) => {
    let invoiceLink = null;
    //console.log('getExistingPaidSlot', paidSlots);
    if (paidSlots) {
      if (paidSlots.length > 0 && paidSlots[paidSlots.length-1].status === 0) {
        invoiceLink = paidSlots[paidSlots.length-1].invoice;
      }
    }
    return invoiceLink;
  };

  getNumberOfConfirmedPaidSlots = (paidSlots) => {
    let numberOfPaidSlots = 0;
    if (!!paidSlots) {
      numberOfPaidSlots = paidSlots.reduce((total, slot) => {
        if (slot.status === 1) {
          total += 1;
        }
        return total;
      }, 0);
    }
    return numberOfPaidSlots;
  };
  
  componentDidMount() {
    const isUserLoggedIn = (localStorage.getItem('token') !== null);
    const courseSlug = this.props.match.params.kurs;
    const lessonSlug = this.props.match.params.slug;
    if (isUserLoggedIn) {
      this.props.onFetchSingleLesson(lessonSlug);
    } else {
      this.props.onFetchSingleLessonShared(lessonSlug);
    }
    this.props.onFetchCourseModules(courseSlug);
    this.props.onFetchSingleCourse(courseSlug);
    this.props.onFetchCourseAccess(courseSlug);
    this.props.onFetchCourses();
    this.props.onFetchTestInfo(courseSlug);
    this.props.onFetchTestAttempts(courseSlug);
    this.props.onFetchPaidSlots(courseSlug);
    this.timer = null;
    
    if ((this.props.match.params.slug === "null") || (!this.props.match.params.slug)) {
      const redirectLink = this.props.match.params.kurs ? "/kurs/" + this.props.match.params.kurs : "/kursevi";
      this.props.history.push(redirectLink);
    }
    window.scrollTo(0, 0);
    this.setState(updateObject(this.state, {testButtonLoading: false}));
  }

  componentDidUpdate(prevProps, prevState) {
    const courseSlug = this.props.match.params.kurs;
    const lessonSlug = this.props.match.params.slug;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.onFetchSingleLesson(lessonSlug);
      this.props.onFetchCourseModules(courseSlug);
      this.props.onFetchSingleCourse(courseSlug);
      this.setState(updateObject(this.state, {note: ''}));
    }
    
    if (this.props.lesson.meta !== prevProps.lesson.meta) {
      if (this.props.lesson.meta.watched) {
        this.props.onCourseModulesUpdateLessonWatched(this.props.lesson.id);
      }
      if (this.props.lesson.meta.note) {
        //this.setState(updateObject(this.state, {note: this.props.lesson.meta.note}));
        this.state.note = this.props.lesson.meta.note;
      }
    }
    
    if (this.props.lesson.videoId !== prevProps.lesson.videoId && this.props.lesson.videoId) {
      this.setState(updateObject(this.state, {hasVideo: true}));
    }

    if (this.props.paidSlot !== prevProps.paidSlot) {
      this.props.onFetchPaidSlots(courseSlug);
      if (this.props.paidSlot.invoice) {
        window.open(this.props.paidSlot.invoice, '_blank');
      }
    }

    if (this.props.testLoading !== prevProps.testLoading) {
      this.setState(updateObject(this.state, {testButtonLoading: this.props.testLoading}));
    }
  }

  componentWillUnmount() {
    this.props.onSingleLessonUnmount();
    this.props.onFetchCourseAccessUnmount();
    this.props.onFetchPaidSlotsUnmount();
    this.props.onFetchTestInfoUnmount();
    this.props.onFetchCourseModulesUnmount();
  }

  render() {
    const mainLessonSlug = this.props.match.params.slug;
    const lessonCount = Object.keys(this.props.lesson).length;
    const coursesCount = Object.keys(this.props.courses).length;
    const singleCourseCount = Object.keys(this.props.singleCourse).length;
    const courseStarted = differenceFromToday(this.props.singleCourse.startDate) < 0;

    /* *
    * Test Box Status variable
    * */
    let lessonTestBoxStatus = "run";
    let invoiceUrl = null;
    let daysUntilNextTestAttempts = null;
    const numberOfConfirmedPaidSlots = this.getNumberOfConfirmedPaidSlots(this.props.paidSlots);
    //console.log('this.props.testInfo.numberOfFreeAttempts-this.props.testAttempts.count', this.props.testInfo.numberOfFreeAttempts-this.props.testAttempts.count, Math.abs(parseInt(this.props.testAttempts.lastAttemptFinishedBefore)), NEXT_AVAILABLE_TEST_ATTEMPT_SECONDS);
    if(this.props.testAttempts.passed) {
      lessonTestBoxStatus = "passed";
    } else if(this.props.testAttempts.hasInProgress) {
      lessonTestBoxStatus = "continue";
    } else if(!this.props.testAttempts.hasInProgress && this.props.testAttempts.count >= (this.props.testInfo.numberOfFreeAttempts + numberOfConfirmedPaidSlots)) {
      lessonTestBoxStatus = "buy";
      invoiceUrl = this.getExistingPaidSlot(this.props.paidSlots);
    } else if(
      this.props.testInfo.numberOfFreeAttempts-this.props.testAttempts.count >= 1 &&
      Math.abs(parseInt(this.props.testAttempts.lastAttemptFinishedBefore)) < NEXT_AVAILABLE_TEST_ATTEMPT_SECONDS
    ) {
      daysUntilNextTestAttempts = Math.abs(formatSecondsToDays(Math.abs(parseInt(this.props.testAttempts.lastAttemptFinishedBefore)) - NEXT_AVAILABLE_TEST_ATTEMPT_SECONDS));
      lessonTestBoxStatus = "waiting";
    }

    /* *
    * Featured Courses
    * */
    let featuredCourses = <Spinner/>;
    if (!this.props.loading && coursesCount > 0 && singleCourseCount > 0) {
      const singleCoursId = this.props.singleCourse.id;
      let featuredCourseCounter = 0;
      //console.log('Lesson courses', Object.values(this.props.courses), "singleCoursId: ", singleCoursId);
      featuredCourses = Object.values(this.props.courses).map(course => {
        const secondsForCountdown = differenceFromToday(course.startDate);
        featuredCourseCounter++;
        return (
          (singleCoursId !== course.id && featuredCourseCounter <= 3) ? (
            <div className="col-md-6 col-xl-4 align-self-md-stretch align-self-auto pb-md-0 pb-2" key={course.id}>
              <CourseBox
                course={course}
                secondsForCountdown={secondsForCountdown} />
            </div>
          ) : null
        )
      });
      if (featuredCourses.length === 0) {
        featuredCourses = null;
      }
    }

    /* *
    * Single Lesson
    * */
    let singleLesson = <Spinner/>;

    if (!this.props.loading) {
      if (Array.isArray(this.props.lesson) && this.props.lesson.length === 0) {
        singleLesson = <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="mt-5 mb-5">Tražena lekcija nije dostupna.</p>
            </div>
          </div>
        </div>;
      } else {
        singleLesson = <Fragment>
          <div className="container">
            <div className="row no-gutters">

              <div className="col-md-8">
                {this.state.hasVideo ?
                  <div className="lesson-player">
                    <LessonPlayer
                      videoId={this.props.lesson.videoId}
                      watched={(slug) => this.watchedHandler(slug)}
                      nextLesson={(slug) => this.nextLessonHandler(slug)}
                      lessonSlug={mainLessonSlug}
                    />
                    {(this.props.singleCourse.approved && courseStarted) ? (
                      <div className="lesson-player__options d-none d-md-flex">
                        <Fragment>
                          <div className="lesson-player__option">
                            <LessonWatched
                              checked={this.props.lesson.meta.watched}
                              lessonSlug={mainLessonSlug}
                              watched={(slug) => this.watchedHandler(slug)}
                            />
                          </div>

                          <div className="lesson-player__option">
                            <LessonBookmark
                              checked={this.props.lesson.meta.favorite}
                              lessonSlug={mainLessonSlug}
                              favorite={(slug) => this.favoriteHandler(slug)}
                            />
                          </div>

                          {(this.props.lesson.additionalFiles) ? (
                            <div className="lesson-player__option">
                              <LessonAttachment
                                file={this.props.lesson.additionalFiles}
                                token={this.props.token}
                              />
                            </div>
                          ) : null}
                        </Fragment>
                      </div>
                    ) : null}
                  </div> :
                  <div className="lesson-player--guest">
                    <div className="lesson-player--guest__wrapper">
                      <p className="lesson-player--guest__message">Potrebno je da se registruješ, a potom da se uloguješ kako bi mogao/la da pristupiš free lekciji.</p>
                      <div className="lesson-player--guest__buttons">
                        <Link to="/login" className="btn btn-primary" onClick={this.prevPathHandler}>Uloguj se</Link>
                        <Link to="/register" className="btn btn-outline-primary" onClick={this.prevPathHandler}>Registruj se</Link>
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    {(lessonCount > 0 && this.props.singleCourse.approved && courseStarted) ?
                      <LessonNotes
                        notes={this.state.note}
                        noteCollapsed={this.state.noteCollapsed}
                        noteChanged={(note) => this.noteChangedHandler(note)}
                        toggleNoteCollapsed={(opened) => this.noteCollapsedHandler(opened)}
                      />
                      : null}

                    {lessonCount > 0 ?
                      <LessonAbout description={this.props.lesson.description}/>
                      : null}

                    {lessonCount > 0 ?
                      <LessonInstructors instructors={this.props.lesson.instructors}/>
                      : null}

                    { this.props.testInfo.enabled ?
                      <LessonTestBox
                        status={lessonTestBoxStatus}
                        courseSlug={this.props.match.params.kurs}
                        lessonSlug={this.props.match.params.slug}
                        certificateUrl={this.props.testAttempts.certificate}
                        testAttemptsCount={this.props.testAttempts.count}
                        testLoading = {this.state.testButtonLoading}
                        isFree = {this.props.testInfo.free}
                        invoiceUrl={invoiceUrl}
                        createInvoice={this.createInvoiceHandler}
                        numberOfRemainingFreeAttempts={this.props.numberOfRemainingFreeAttempts}
                        daysUntilNextTestAttempts={daysUntilNextTestAttempts}
                      /> : null }
                      
                  </div>
                </div>
              </div>

              {!this.props.courseModulesLoading ?
                <div className="col-md-4">
                  <ModulesAndLessonsAccordion courseModules={this.props.courseModules}
                                              currentLesson={this.props.lesson.id}
                                              courseSlug={this.props.match.params.kurs}
                                              watched={(slug) => this.watchedHandler(slug)}
                                              courseApproved={this.props.singleCourse.approved}
                                              courseStarted={courseStarted}
                  />
                </div>
                : null }
            </div>

            {(featuredCourses.length > 0) ? (
              <div className="row no-gutters">
                <div className="col-lg-10 single-lesson__featured">
                  <h2 className="title">Ostali kursevi</h2>
                  <div className="row">
                    {featuredCourses}
                  </div>
                </div>
              </div>
            ) : null}
            <LessonOptionsMobile
              lessonSlug={mainLessonSlug}
              lessonWatchedChecked={this.props.lesson.meta.watched}
              lessonWatched={(slug) => this.watchedHandler(slug)}
              lessonFavoriteChecked={this.props.lesson.meta.favorite}
              lessonFavorite={(slug) => this.favoriteHandler(slug)}
              lessonAdditionalFiles = {this.props.lesson.additionalFiles}
              token = {this.props.token}
            />
          </div>
        </Fragment>;
      }
    }
    return (
      <div className="single-lesson">
          {singleLesson}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null,
    token: state.auth.token,
    loading: state.course.loading,
    courseModulesLoading: state.course.courseModulesLoading,
    lesson: state.course.lesson,
    courseModules: state.course.courseModules,
    courses: state.course.courses,
    user: state.auth.user,
    singleCourse: state.course.singleCourse,
    testLoading: state.test.loading,
    testInfo: state.test.testInfo,
    testAttempts: state.test.testAttempts,
    paidSlot: state.test.paidSlot,
    paidSlots: state.test.paidSlots,
    numberOfRemainingFreeAttempts: state.test.testInfo.numberOfFreeAttempts - state.test.testAttempts.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSingleLesson: (slug) => dispatch(actions.fetchSingleLesson(slug)),
    onSingleLessonUnmount: () => dispatch(actions.singleLessonUnmount()),
    onFetchSingleLessonShared: (slug) => dispatch(actions.fetchSingleLessonShared(slug)),
    onSingleLessonSharedUnmount: () => dispatch(actions.singleLessonSharedUnmount()),
    onLessonWatched: (slug) => dispatch(actions.lessonWatched(slug)),
    onLessonFavorite: (slug) => dispatch(actions.lessonFavorite(slug)),
    onFetchCourseModules: (slug) => dispatch(actions.fetchCourseModules(slug)),
    onFetchSingleCourse: (slug) => dispatch(actions.fetchSingleCourse(slug)),
    onFetchCourseAccess: (slug) => dispatch(actions.fetchCourseAccess(slug)),
    onFetchCourseAccessUnmount: () => dispatch(actions.fetchCourseAccessUnmount()),
    onFetchCourses: () => dispatch(actions.fetchCourses()),
    onCourseModulesUpdateLessonWatched: (lessonId) => dispatch(actions.courseModulesUpdateLessonWatched(lessonId)),
    onSetLessonNotes: (slug, note) => dispatch(actions.setLessonNotes(slug, note)),
    onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchTestInfo: (slug) => dispatch(actions.fetchTestInfo(slug)),
    onFetchTestInfoUnmount: () => dispatch(actions.fetchTestInfoUnmount()),
    onFetchTestAttempts: (slug) => dispatch(actions.fetchTestAttempts(slug)),
    onPostPaidSlot: (slug) => dispatch(actions.postPaidSlot(slug)),
    onFetchPaidSlots: (slug) => dispatch(actions.fetchPaidSlots(slug)),
    onFetchPaidSlotsUnmount: () => dispatch(actions.fetchPaidSlotsUnmount()),
    onFetchCourseModulesUnmount: () => dispatch(actions.fetchCourseModulesUnmount())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lesson));