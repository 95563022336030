import React, {Component, Fragment} from 'react'
import PageHeader from "../layout/PageHeader/PageHeader";
import AboutUsIntro from "../layout/AboutUs/AboutUsIntro";
import AboutUsMissionVision from "../layout/AboutUs/AboutUsMissionVision";
import AboutUsDirector from "../layout/AboutUs/AboutUsDirector";
import {Helmet} from "react-helmet";
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-onama.png";

const tagManagerArgs = {
  event: 'pageView',
  dataLayer: {
    page: 'o-nama'
  },
  dataLayerName: 'PageDataLayer'
};

class AboutUs extends Component {
  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('o-nama');
  }

  render() {
    return(
      <Fragment>
        <Helmet>
          <title>O nama - Homepage Academy</title>
          <meta name="description" content="Upoznaj Homepage akademiju" />
          <meta property="og:title" content="Saznaj više" />
          <meta property="og:description" content="Upoznaj Homepage akademiju" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>

        <PageHeader subtitle={"Znanje za bolje poslovanje!"} class={"about"}>
          Homepage Academy
        </PageHeader>
        
        <AboutUsIntro/>
        <AboutUsDirector/>
        <AboutUsMissionVision/>
      </Fragment>
    );
  }
}

export default AboutUs;