import React, { Fragment } from 'react';
import TempMobilSliderImage from "../../../assets/images/home-slide-mobile.jpg";
import {Link} from "react-router-dom";

const mainSlider = () => (
  <Fragment>
    {/* Slider Desktop */}
    <div className="slider d-none d-md-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-1">
            <h1 className="slider__title">Homepage akademija</h1>
            <h3 className="slider__subtitle">Znanje za bolje poslovanje!</h3>
            <div className="d-flex">
              <Link to="/kursevi" className="btn btn-primary btn-lg mr-md-3 slider__button">Kursevi</Link>
              <Link to="/paketi-kurseva" className="btn btn-primary btn-lg slider__button">Paketi</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Slider Mobile */}
    <div className="slider-mobile d-md-none">
      <img className="img-fluid slider-mobile__img" src={ TempMobilSliderImage } alt="Homepage Academy"/>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="slider-mobile__title">Homepage akademija</h1>
              <h3 className="slider-mobile__subtitle">Znanje za bolje poslovanje!</h3>
              <div className="row">
                <div className="col-12">
                  <Link to="/kursevi" className="btn btn-primary btn-lg slider-mobile__button mb-4">Kursevi</Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to="/paketi-kurseva" className="btn btn-primary btn-lg slider__button mb-4">Paketi</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Fragment>
);

export default mainSlider;