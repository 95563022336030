import {updateObject} from "../../../shared/utility";

export const fetchSingleInstructorStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleInstructorSuccess = (state, action) => {
  return updateObject(state, {
    singleInstructor: action.singleInstructor,
    error: null,
    loading: false
  });
};
export const fetchSingleInstructorFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const singleInstructorUnmount = (state, action) => {
  return updateObject(state, {
    singleInstructor: [],
    error: null,
    loading: false
  });
};