import React from 'react';

const aboutUsIntro = (props) => (
  <section className="about-intro">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1 d-flex align-items-start align-items-md-center justify-content-center flex-column">
          <div className="about-intro__text text-md-center">
            <p>Homepage akademija je naš doprinos digitalnoj transformaciji marketinške struke, privrede Srbije, a posredno i celokupnog društva. Verujemo da na taj način stvaramo bolje poslovno okruženje za sve nas.</p>
            <p>Znanja koja stičeš kroz Homepage akademiju su raznolika, ali imaju zajednički činilac - zasnovana su na iskustvu predavača stečenom kroz rad na realnim i konkretnim projektima. Svaki modul i svaka lekcija donose, kroz praksu proveren, način rada i principe razmišljanja koje je moguće odmah testirati u svom radnom okruženju.</p>
            <p>Homepage akademija kroz intuitivnu platformu omogućava jednostavan pristup svakoj lekciji, lako učenje, uspešnu i samostalnu primenu stečenog znanja, kao i kreiranje zajednice polaznika koji su u prilici da, međusobno i sa predavačima, razmenjuju stečena znanja i iskustva.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default aboutUsIntro;