import React, {Component, Fragment} from 'react';
import { ReCaptcha } from 'react-recaptcha-google';

class ReCaptchaHoc extends Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token");
  }
  onLoadRecaptcha = () => {
    if (this.captchaHomepageAcademy) {
      this.captchaHomepageAcademy.reset();
      this.captchaHomepageAcademy.execute();
    }
  }
  
  componentDidMount() {
    if (this.captchaHomepageAcademy) {
      //console.log("started, just a second...")
      this.captchaHomepageAcademy.reset();
      this.captchaHomepageAcademy.execute();
    }
  }

  render() {
    let googleCaptcha = null;
    if (window.recaptcha) {
      googleCaptcha = <ReCaptcha
        ref={(el) => {this.captchaHomepageAcademy = el;}}
        size="invisible"
        render="explicit"
        sitekey="6LfH7b0ZAAAAADdLnrJwit2sChRyyOhdVtRYB4HO"
        onloadCallback={this.onLoadRecaptcha}
        verifyCallback={this.verifyCallback}
      />;
    }

    return (
      <Fragment>
        {googleCaptcha}
        {this.props.children}
      </Fragment>
    );
  }
}

export default ReCaptchaHoc;