import React, {useState, useEffect} from 'react';
import { Modal, Button } from "react-bootstrap";

const modalBox = (props) => {
  
  const handleClose = () => {
    props.close();
  }
  
  const handleSubmit = () => {
    props.submit();
  }
  
  return(
    <Modal
      size="lg"
      show={props.show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="d-flex flex-column align-items-center">
        {props.subtitle ?
          (<h5 className="modal-subtitle">{props.subtitle}</h5>) :
          null
        }
        <Modal.Title className="text-center">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">{props.children}</Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="primary" size="sm" onClick={handleSubmit}>
          Započni
        </Button>
        <Button variant="link" size="sm" onClick={handleClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default modalBox;