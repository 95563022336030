import {updateObject} from "../../../shared/utility";

export const contactFormStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    formSent: false
  });
};
export const contactFormSuccess = (state, action) => {
  return updateObject(state, {
    response: action.response,
    error: null,
    loading: false,
    formSent: true
  });
};
export const contactFormFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    formSent: false
  });
};
export const contactFormUnmount = (state, action) => {
  return updateObject(state, {
    response: [],
    error: null,
    loading: false,
    formSent: false
  });
};