import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {inputChangedFunction, formatStateForForm, checkAxiosErrorFields} from "../../shared/utility";

import UserAccount from "../../hoc/Layouts/UserAccount";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Promena lozinke'
  },
  dataLayerName: 'PageDataLayer'
};

class ChangePassword extends Component {

  state = {
    form: {
      oldPassword: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'password',
          placeholder: 'Stara lozinka'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      plainPassword: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'password',
          placeholder: 'Nova lozinka'
        },
        value: '',
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  changePasswordHandler = (event) => {
    event.preventDefault();
    //console.log('changePasswordHandler');
    this.props.onChangePassword(this.state.form.oldPassword.value, this.state.form.plainPassword.value, this.props.token);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('change-password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error !== null) {
      const errorFields = this.props.error.data.error.data.fields;
      this.setState(checkAxiosErrorFields(errorFields, this.state));
    }
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);

    let form = (
      <form onSubmit={this.changePasswordHandler}>
          {
            formElementsArray.map((el) => {
              return (
                <div className="row" key={el.id}>
                  <div className="col-md-8 col-lg-6">
                    <Input
                      class={el.config.elementClasses}
                      elementType={el.config.elementType}
                      elementConfig={el.config.elementConfig}
                      value={el.config.value}
                      invalid={!el.config.valid}
                      shouldValidate={el.config.validation}
                      touched={el.config.touched}
                      errorMessage={el.config.errorMessage}
                      changed={(event) => this.inputChangedHandler(event, el.id)}/>
                  </div>
                </div>
              )
            })
          }
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <Button
              disabled={!this.state.formIsValid}
              btnType="btn-primary user-form__btn-submit"
              clicked={this.changePasswordHandler}>Sačuvaj</Button>
          </div>
        </div>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    let formMessage = null;
    if (this.props.passwordChanged) {
      formMessage = (
        <p className="user-form__message success text-left">Lozinka je uspešno promenjena.</p>
      );
    }

    return (
      <UserAccount>
        <h2 className="user-account__main__title">Promeni lozinku</h2>
        <div className="user-account__main__content">
          { formMessage }
          { form }
        </div>
      </UserAccount>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.auth.loading,
    error: state.auth.error,
    passwordChanged: state.auth.passwordChanged,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangePassword: (oldPassword, plainPassword, token) => dispatch(actions.changePassword(oldPassword, plainPassword, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);