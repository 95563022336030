import {updateObject} from "../../../shared/utility";

export const fetchSingleCoursePackageStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};
export const fetchSingleCoursePackageSuccess = (state, action) => {
    return updateObject(state, {
        singleCoursePackage: action.singleCoursePackage,
        error: null,
        loading: false
    });
};
export const fetchSingleCoursePackageFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

export const singleCoursePackageUnmount = (state, action) => {
    return updateObject(state, {
        singleCoursePackage: [],
        error: null,
        loading: false
    });
};