import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const fetchMyCoursesStart = () => {
  return {
    type: actionTypes.FETCH_MY_COURSES_START
  };
};
export const fetchMyCoursesSuccess = (myCourses) => {
  return {
    type: actionTypes.FETCH_MY_COURSES_SUCCESS,
    myCourses: myCourses
  }
};
export const fetchMyCoursesFail = (error) => {
  return {
    type: actionTypes.FETCH_MY_COURSES_FAIL,
    error: error
  }
};
export const fetchMyCourses = () => {
  return dispatch => {
    dispatch(fetchMyCoursesStart());
    const headers = getAxiosHeader();
    axios.get('/users/me/courses', headers)
    .then(res => {
      //console.log('fetchMyCourses res', res);
      dispatch(fetchMyCoursesSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('fetchMyCourses error', err);
      dispatch(fetchMyCoursesFail(err));
    });
  }
};