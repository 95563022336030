import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const fetchSingleLessonStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_START
  };
};
export const fetchSingleLessonSuccess = (lesson) => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_SUCCESS,
    lesson: lesson
  }
};
export const fetchSingleLessonFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_LESSON_FAIL,
    error: error
  }
};
export const fetchSingleLesson = (slug) => {
  return dispatch => {
    dispatch(fetchSingleLessonStart());
    const headers = getAxiosHeader();
    axios.get('/lessons/' + slug, headers)
    .then(res => {
      //console.log('fetchSingleLesson', res.data.data);
      dispatch(fetchSingleLessonSuccess(res.data.data));
    })
    .catch(err => {
      //console.log('fetchSingleLesson error', err);
      dispatch(fetchSingleLessonFail(err));
    });
  }
};

export const singleLessonUnmount = () => {
  return {
    type: actionTypes.SINGLE_LESSON_UNMOUNT
  };
};