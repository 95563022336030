import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import {getAxiosHeader} from "../../../shared/utility";

export const contactFormStart = () => {
  return {
    type: actionTypes.CONTACT_FORM_START
  };
};
export const contactFormSuccess = (response) => {
  return {
    type: actionTypes.CONTACT_FORM_SUCCESS,
    response: response
  }
};
export const contactFormFail = (error) => {
  return {
    type: actionTypes.CONTACT_FORM_FAIL,
    error: error
  }
};
export const contactForm = (formData) => {
  return dispatch => {
    dispatch(contactFormStart());
    const headers = getAxiosHeader();
    axios.post('/util/contact', formData, headers)
      .then(response => {
        //console.log('contactForm AXIOS response ', response.data.data);
        dispatch(contactFormSuccess(response.data.data));
      })
      .catch(err => {
        //console.log('contactForm AXIOS catch err ', err);
        dispatch(contactFormFail(err));
      });
  }
};

export const contactFormUnmount = () => {
  return {
    type: actionTypes.CONTACT_FORM_UNMOUNT
  };
};