import React from 'react';
import AboutUsMissionImg from "../../../assets/images/o-nama-misija.jpg";
import AboutUsVisionImg from "../../../assets/images/o-nama-vizija.jpg";

const aboutUsMissionVision = (props) => (
  <section className="mision-vision">
    <div className="row no-gutters bgd--gray">
      <div className="col-lg-6 d-flex justify-content-end align-items-center text-lg-right">
        <div className="mision-vision__wrapper mision-vision__wrapper--odd">
          <h2>Misija</h2>
          <div className="mision-vision__description">
            Naša misija je da u okviru Homepage akademije okupimo one koji su svoje znanje dokazali kroz njegovu praktičnu primenu u oblasti marketinga, digitala, preduzetništva i srodnih oblasti, te da to znanje učinimo dostupnim putem intuitivne platforme koja omogućava jednostavan pristup svakoj lekciji, lako učenje, uspešnu i samostalnu primenu stečenog znanja.
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <img className="img-fluid" src={AboutUsMissionImg} alt="Homepage Academy misija" />
      </div>
    </div>

    <div className="row no-gutters">
      <div className="col-lg-6 d-flex justify-content-start align-items-center order-lg-2">
        <div className="mision-vision__wrapper mision-vision__wrapper--even">
          <h2>Vizija</h2>
          <div className="mision-vision__description">
            Naša vizija je digitalno transformisano društvo i <strong>dostupnost znanja</strong> kroz učenje od dokazanih stručnjaka u svojim oblastima, digitalnih eksperata i preduzetnika. Prepoznatljivost Homepage akademije kao partnera koji prati i donosi najnovije trendove i znanja iz oblasti marketinga, digitala, preduzetništva i srodnih oblasti.
          </div>
        </div>
      </div>
      <div className="col-lg-6 order-lg-1">
        <img className="img-fluid" src={AboutUsVisionImg} alt="Homepage Academy vizija"/>
      </div>
    </div>
  </section>
);

export default aboutUsMissionVision;