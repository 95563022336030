import React, {Component, Fragment} from 'react'
import { Redirect, Link } from "react-router-dom";
import {checkAxiosErrorFields, formatStateForForm, inputChangedFunction} from "../../shared/utility";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Reset lozinke'
  },
  dataLayerName: 'PageDataLayer'
};

class ResetPassword extends Component {
  state = {
    form: {
      email: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  resetPasswordHandler = (event) => {
    event.preventDefault();
    this.props.onResetPassword(this.state.form.email.value);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('reset-password');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error && this.props.error !== null) {
      const errorFields = {
        email: this.props.error.data.error.message
      };
      this.setState(checkAxiosErrorFields(errorFields, this.state));
    }
  }
  
  componentWillUnmount() {
    this.props.onResetPasswordUnmount();
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);

    let form = (
      <Fragment>
        <h2 className="user-form__title">Resetuj lozinku</h2>
        <form onSubmit={this.resetPasswordHandler}>
          <div className="row">
            {
              formElementsArray.map((el) => {
                return (
                  <div className="col-12" key={el.id}>
                    <Input
                      class={el.config.elementClasses}
                      elementType={el.config.elementType}
                      elementConfig={el.config.elementConfig}
                      value={el.config.value}
                      invalid={!el.config.valid}
                      shouldValidate={el.config.validation}
                      touched={el.config.touched}
                      errorMessage={el.config.errorMessage}
                      changed={(event) => this.inputChangedHandler(event, el.id)}/>
                  </div>
                )
              })
            }
            <div className="col-12 d-flex justify-content-center">
              <Button
                disabled={!this.state.formIsValid}
                btnType="btn-primary user-form__btn-submit"
                clicked={this.resetPasswordHandler}>Resetuj</Button>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center flex-column">
              <Link className="user-form__link--reset" to="/login">Nazad na prijavu</Link>
            </div>
          </div>
        </form>
      </Fragment>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    let authRedirectPath = null;
    if (this.props.isAuthenticated) {
      authRedirectPath = <Redirect to={this.props.authRedirectPath} />;
    }

    let formMessage = null;
    if (this.props.resetPasswordSent) {
      formMessage = (
        <p className="user-form__message success">Link za resetovanje lozinke je poslat na email adresu koju ste uneli.</p>
      );
    }

    return (
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 d-flex align-items-center flex-column user-form">
              {formMessage}
              <div className="user-form__box">
                {authRedirectPath}
                {form}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token != null,
    resetPasswordSent: state.auth.resetPasswordSent,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetPassword: (email) => dispatch(actions.resetPassword(email)),
    onResetPasswordUnmount: (email) => dispatch(actions.resetPasswordUnmount(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);