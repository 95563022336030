import {updateObject} from "../../../shared/utility";

export const submitTestStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const submitTestSuccess = (state, action) => {
  return updateObject(state, {
    test: updateObject(state.test, {
      testAttempt: action.testAttempt
    }),
    error: null,
    loading: false
  });
};
export const submitTestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
