import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';
import { fetchSingleLessonStart, fetchSingleLessonSuccess, fetchSingleLessonFail, singleLessonUnmount} from './lesson';
import { fetchSingleLessonSharedStart, fetchSingleLessonSharedSuccess, fetchSingleLessonSharedFail, singleLessonSharedUnmount} from './lessonShared';
import { lessonWatchedStart, lessonWatchedSuccess, lessonWatchedFail, courseModulesUpdateLessonWatched} from './lessonWatched';
import { lessonFavoriteStart, lessonFavoriteSuccess, lessonFavoriteFail,
        fetchFavoriteLessonsStart, fetchFavoriteLessonsSuccess, fetchFavoriteLessonsFail} from './lessonFavorite';
import { setLessonNotesStart, setLessonNotesSuccess, setLessonNotesFail} from './lessonNotes';
import { fetchCoursePackagesStart, fetchCoursePackagesSuccess, fetchCoursePackagesFail } from "./coursePackage";
import { fetchSingleCoursePackageStart, fetchSingleCoursePackageSuccess, fetchSingleCoursePackageFail, singleCoursePackageUnmount} from './singleCoursePackage';

const initialState = {
  courses: [],
  coursePackages: [],
  myCourses: [],
  favoriteLessons: [],
  singleCourse: [],
  singleCoursePackage: [],
  courseAccess: [],
  courseModules: [],
  lesson: [],
  loading: false,
  courseModulesLoading: false,
  error: false,
  jumpToLesson: false
}

export const fetchCoursesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchCoursesSuccess = (state, action) => {
  return updateObject(state, {
    courses: action.courses,
    error: null,
    loading: false
  });
};
export const fetchCoursesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const fetchMyCoursesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchMyCoursesSuccess = (state, action) => {
  return updateObject(state, {
    myCourses: action.myCourses,
    error: null,
    loading: false
  });
};
export const fetchMyCoursesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const fetchSingleCourseStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchSingleCourseSuccess = (state, action) => {
  return updateObject(state, {
    singleCourse: action.course,
    error: null,
    loading: false
  });
};
export const fetchSingleCourseFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchSingleCourseUnmount = (state, action) => {
  return updateObject(state, {
    singleCourse: [],
    error: null,
    loading: false
  });
};


export const fetchCourseAccessStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchCourseAccessSuccess = (state, action) => {
  return updateObject(state, {
    courseAccess: action.courseAccess,
    error: null,
    loading: false
  });
};
export const fetchCourseAccessFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchCourseAccessUnmount = (state, action) => {
  return updateObject(state, {
    courseAccess: [],
    error: null,
    loading: false
  });
};

export const jumpToLesson = (state, action) => {
  return updateObject(state, {
    jumpToLesson: true
  });
};
export const jumpToLessonRevert = (state, action) => {
  return updateObject(state, {
    jumpToLesson: false
  });
};

export const fetchCourseModulesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    courseModulesLoading: true
  });
};
export const fetchCourseModulesSuccess = (state, action) => {
  return updateObject(state, {
    courseModules: action.courseModules,
    error: null,
    courseModulesLoading: false
  });
};
export const fetchCourseModulesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    courseModulesLoading: false
  });
};
export const fetchCourseModulesUnmount = (state, action) => {
  return updateObject(state, {
    courseModules: [],
    error: null,
    courseModulesLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COURSES_START:
      return fetchCoursesStart(state, action);
    case actionTypes.FETCH_COURSES_SUCCESS:
      return fetchCoursesSuccess(state, action);
    case actionTypes.FETCH_COURSES_FAIL:
      return fetchCoursesFail(state, action);

    case actionTypes.FETCH_MY_COURSES_START:
      return fetchMyCoursesStart(state, action);
    case actionTypes.FETCH_MY_COURSES_SUCCESS:
      return fetchMyCoursesSuccess(state, action);
    case actionTypes.FETCH_MY_COURSES_FAIL:
      return fetchMyCoursesFail(state, action);

    case actionTypes.FETCH_SINGLE_COURSE_START:
      return fetchSingleCourseStart(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_SUCCESS:
      return fetchSingleCourseSuccess(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_FAIL:
      return fetchSingleCourseFail(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_UNMOUNT:
      return fetchSingleCourseUnmount(state, action);

    case actionTypes.FETCH_COURSE_ACCESS_START:
      return fetchCourseAccessStart(state, action);
    case actionTypes.FETCH_COURSE_ACCESS_SUCCESS:
      return fetchCourseAccessSuccess(state, action);
    case actionTypes.FETCH_COURSE_ACCESS_FAIL:
      return fetchCourseAccessFail(state, action);
    case actionTypes.FETCH_COURSE_ACCESS_UNMOUNT:
      return fetchCourseAccessUnmount(state, action);

    case actionTypes.JUMP_TO_LESSON:
      return jumpToLesson(state, action);
    case actionTypes.JUMP_TO_LESSON_REVERT:
      return jumpToLessonRevert(state, action);

    case actionTypes.FETCH_COURSE_MODULES_START:
      return fetchCourseModulesStart(state, action);
    case actionTypes.FETCH_COURSE_MODULES_SUCCESS:
      return fetchCourseModulesSuccess(state, action);
    case actionTypes.FETCH_COURSE_MODULES_FAIL:
      return fetchCourseModulesFail(state, action);
    case actionTypes.FETCH_COURSE_MODULES_UNMOUNT:
      return fetchCourseModulesUnmount(state, action);

    case actionTypes.FETCH_SINGLE_LESSON_START:
      return fetchSingleLessonStart(state, action);
    case actionTypes.FETCH_SINGLE_LESSON_SUCCESS:
      return fetchSingleLessonSuccess(state, action);
    case actionTypes.FETCH_SINGLE_LESSON_FAIL:
      return fetchSingleLessonFail(state, action);
    case actionTypes.SINGLE_LESSON_UNMOUNT:
      return singleLessonUnmount(state, action);


    case actionTypes.FETCH_SINGLE_LESSON_SHARED_START:
      return fetchSingleLessonSharedStart(state, action);
    case actionTypes.FETCH_SINGLE_LESSON_SHARED_SUCCESS:
      return fetchSingleLessonSharedSuccess(state, action);
    case actionTypes.FETCH_SINGLE_LESSON_SHARED_FAIL:
      return fetchSingleLessonSharedFail(state, action);
    case actionTypes.SINGLE_LESSON_SHARED_UNMOUNT:
      return singleLessonSharedUnmount(state, action);

    case actionTypes.LESSON_WATCHED_START:
      return lessonWatchedStart(state, action);
    case actionTypes.LESSON_WATCHED_SUCCESS:
      return lessonWatchedSuccess(state, action);
    case actionTypes.LESSON_WATCHED_FAIL:
      return lessonWatchedFail(state, action);
    case actionTypes.COURSE_MODULES_UPDATE_LESSON_META:
      return courseModulesUpdateLessonWatched(state, action);

    case actionTypes.LESSON_FAVORITE_START:
      return lessonFavoriteStart(state, action);
    case actionTypes.LESSON_FAVORITE_SUCCESS:
      return lessonFavoriteSuccess(state, action);
    case actionTypes.LESSON_FAVORITE_FAIL:
      return lessonFavoriteFail(state, action);

    case actionTypes.FETCH_FAVORITE_LESSONS_START:
      return fetchFavoriteLessonsStart(state, action);
    case actionTypes.FETCH_FAVORITE_LESSONS_SUCCESS:
      return fetchFavoriteLessonsSuccess(state, action);
    case actionTypes.FETCH_FAVORITE_LESSONS_FAIL:
      return fetchFavoriteLessonsFail(state, action);

    case actionTypes.SET_LESSON_NOTES_START:
      return setLessonNotesStart(state, action);
    case actionTypes.SET_LESSON_NOTES_SUCCESS:
      return setLessonNotesSuccess(state, action);
    case actionTypes.SET_LESSON_NOTES_FAIL:
      return setLessonNotesFail(state, action);

    case actionTypes.FETCH_COURSE_PACKAGES_START:
      return fetchCoursePackagesStart(state, action);
    case actionTypes.FETCH_COURSE_PACKAGES_SUCCESS:
      return fetchCoursePackagesSuccess(state, action);
    case actionTypes.FETCH_COURSE_PACKAGES_FAIL:
      return fetchCoursePackagesFail(state, action);
      
    case actionTypes.FETCH_SINGLE_COURSE_PACKAGE_START:
      return fetchSingleCoursePackageStart(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_PACKAGE_SUCCESS:
      return fetchSingleCoursePackageSuccess(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_PACKAGE_FAIL:
      return fetchSingleCoursePackageFail(state, action);
    case actionTypes.FETCH_SINGLE_COURSE_PACKAGE_UNMOUNT:
      return singleCoursePackageUnmount(state, action);

    default:
      return state;
  }
};

export default reducer;