import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";


/***************************
 FETCH TEST ATTEMPTS
 **************************/
export const fetchTestAttemptsStart = () => {
  return {
    type: actionTypes.FETCH_TEST_ATTEMPTS_START
  };
};
export const fetchTestAttemptsSuccess = (testAttempts) => {
  return {
    type: actionTypes.FETCH_TEST_ATTEMPTS_SUCCESS,
    testAttempts: testAttempts
  }
};
export const fetchTestAttemptsFail = (error) => {
  return {
    type: actionTypes.FETCH_TEST_ATTEMPTS_FAIL,
    error: error
  }
};
export const fetchTestAttempts = (slug) => {
  return dispatch => {
    dispatch(fetchTestAttemptsStart());
    const headers = getAxiosHeader();
    axios.get('/courses/' + slug + '/test-attempts', headers)
      .then(res => {
        //console.log('fetchTestAttemptsSuccess', res.data.data);
        dispatch(fetchTestAttemptsSuccess(res.data.data));
      })
      .catch(err => {
        //console.log('fetchTestAttemptsFail', err);
        dispatch(fetchTestAttemptsFail(err));
      });
  }
};
export const fetchTestAttemptsUnmount = () => {
  return {
    type: actionTypes.FETCH_TEST_ATTEMPTS_UNMOUNT
  };
};