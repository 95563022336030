import React, {Component, Fragment} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  dataLayer: {
    page: 'Registracija potrvrdjena'
  },
  dataLayerName: 'PageDataLayer'
};

class RegistrationConfirm extends Component {

  state = {
    confirmationToken: null
  }

  componentWillMount() {
    //console.log('RegistrationConfirm componentWillMount');
    const confirmationToken = this.props.match.params.hash;
    this.setState({confirmationToken: confirmationToken});
  }

  componentDidMount() {
    //console.log('RegistrationConfirm componentDidMount', this.state.confirmationToken);
    if(this.state.confirmationToken) {
      this.props.onUserRegistrationConfirm(this.state.confirmationToken);
    }
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('registration-confirm');
  }

  render() {
    //console.log('RegistrationConfirm render', this.props.confirmedRegistration);

    let redirectTo = null;
    let message = null;

    if (this.props.confirmedRegistration) {
      redirectTo = <Redirect to="/login" />;
    } else if (this.props.error) {
      message = (
        <Fragment>
          <h2 className="user-form__title">Potvrda registracije</h2>
          <p className="user-form__message error">
            { this.props.error } Link za potvrdu registracije nije validan
          </p>
        </Fragment>
      );
    }

    if (this.props.loading) {
      message = <Spinner/>;
    }

    return (
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 d-flex align-items-center user-form">
              <div className="user-form__box">
                  { redirectTo }
                  { message }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
      loading: state.auth.loading,
      error: state.auth.error,
      confirmedRegistration: state.auth.confirmedRegistration
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUserRegistrationConfirm: (confirmationToken) => dispatch(actions.userRegistrationConfirm(confirmationToken))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationConfirm);