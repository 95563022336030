import React, {Fragment} from 'react';
import {Carousel} from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

const courseTestimonials = (props) => {
  const items = Object.keys(props.data).map(key => {
    return (
      <Carousel.Item className="carousel-item course-testimonial" key={key}>
        <div className="d-lg-flex text-center text-lg-left">
          <img className="course-testimonial__img" src={props.data[key].image} alt={props.data[key].name}/>
            <div className="course-testimonial__details">
              <h2 className="course-testimonial__name">{props.data[key].name}</h2>
              <h4 className="course-testimonial__title">{props.data[key].title}</h4>
              <div className="course-testimonial__text">
                {ReactHtmlParser(props.data[key].content)}
              </div>
            </div>
        </div>
      </Carousel.Item>
    )
  });

  const prevIcon = (
    <Fragment>
      <span className="carousel-control-prev-icon d-flex align-items-center justify-content-center"
            aria-hidden="true">
        <i className="icon icon-arrow-left"></i>
      </span>
      <span className="sr-only">Previous</span>
    </Fragment>
  );
  const nextIcon = (
    <Fragment>
      <span className="carousel-control-next-icon d-flex align-items-center justify-content-center"
            aria-hidden="true">
        <i className="icon icon-arrow-right"></i>
      </span>
      <span className="sr-only">Next</span>
    </Fragment>
  );
  return (items.length > 0) ? (
    <div className="course-details__testimonials">
      <Carousel prevIcon={prevIcon} nextIcon={nextIcon} indicators={false} id="carouselTestimonials" className="course-testimonials-carousel">
        {items}
      </Carousel>
    </div>
  ) : null;
};

export default courseTestimonials;