import React, {Component, Fragment} from 'react';

import PageHeader from '../layout/Contact/PageHeader';
import ContactDetails from '../layout/Contact/ContactDetails';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {formatStateForForm, inputChangedFunction} from "../../shared/utility";
import Input from "../layout/UI/Input/Input";
import Button from "../layout/UI/Button/Button";
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-kontakt.png";

const tagManagerArgs = {
  event: 'pageView',
  dataLayer: {
    page: 'Contact',
    pagePath: 'contact'
  },
  dataLayerName: 'PageDataLayer'
};

class Contact extends Component {
  state = {
    form: {
      name: {
        order: 0,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'text',
          placeholder: 'Ime i prezime'
        },
        value: '',
        validation: {
          required: true,
          minLength: 3
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      email: {
        order: 1,
        elementType: 'input',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'email',
          placeholder: 'Email'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      },
      message: {
        order: 2,
        elementType: 'textarea',
        elementClasses: 'form-control',
        elementConfig: {
          type: 'textarea',
          placeholder: 'Poruka'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        errorMessage: null,
        touched: false
      }
    },
    formIsValid: false
  }

  ContactFormHandler = (event) => {
    event.preventDefault();
    const formData = {};
    for (let formElementIdentified in this.state.form) {
      formData[formElementIdentified] = this.state.form[formElementIdentified].value;
    }
    //console.log('ContactFormHandler: ', formData);
    this.props.onContactForm(formData);
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState(inputChangedFunction(event.target.value, inputIdentifier, this.state));
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('kontakt');
  }

  componentWillUnmount() {
    this.props.onContactFormUnmount();
  }

  render() {
    const formElementsArray = formatStateForForm(this.state.form);

    let form = (
      <form onSubmit={this.ContactFormHandler}>
        <div className="row">
          {
            formElementsArray.map((el) => {
              return (
                <div className="col-12" key={el.id}>
                  <Input
                    class={el.config.elementClasses}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    value={el.config.value}
                    invalid={!el.config.valid}
                    shouldValidate={el.config.validation}
                    touched={el.config.touched}
                    errorMessage={el.config.errorMessage}
                    changed={(event) => this.inputChangedHandler(event, el.id)}/>
                </div>
              )
            })
          }
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              disabled={!this.state.formIsValid}
              btnType="btn-primary user-form__btn-submit"
              clicked={this.ContactFormHandler}>Pošalji</Button>
          </div>
        </div>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner/>;
    }

    if (this.props.formSent) {
      form = <p className="user-form__message success text-left">Tvoja poruka je uspešno poslata.</p>;
    }

    return (
      <Fragment>
        <Helmet>
          <title>Kontakt - Homepage Academy</title>
          <meta name="description" content="Tu smo za tebe" />
          <meta property="og:title" content="Kontaktiraj nas" />
          <meta property="og:description" content="Tu smo za tebe" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>
        <PageHeader/>
        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="contact-box">
                  <div className="row no-gutters">
                    <div className="col-lg-6 order-lg-2 d-flex align-self-lg-stretch">
                      <div className="contact-box__form">
                        <h2 className="contact-box__title">Pošalji poruku:</h2>
                        {form}
                      </div>
                    </div>
                    <div className="col-lg-6 order-lg-1">
                      <ContactDetails/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.emailForm.loading,
    error: state.emailForm.error,
    formSent: state.emailForm.formSent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onContactForm: (formData) => dispatch(actions.contactForm(formData)),
    onContactFormUnmount: () => dispatch(actions.contactFormUnmount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);