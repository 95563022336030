import React, {Component} from 'react';
import Button from "../UI/Button/Button";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import { Link } from "react-router-dom";

class GDPR extends Component {

  confirmCookie = (e) => {
    e.preventDefault();
    this.props.onConfirmCookie();
  }

  render() {
    const cookieShow = !this.props.cookie ? ' shown' : '';
    return (
      <div className={"gdpr" + cookieShow}>
        <div className="wrap">
          <div className="text">
            <h3>Zdravo!</h3>
            <p>Važno je da znaš da koristimo kolačiće u svrhu unapređenja tvog korisničkog iskustva. Ukoliko želiš da nastaviš boravak na ovoj platformi, razumećemo da prihvataš <Link
                to="/uslovi-koriscenja">Opšti uslovi poslovanja</Link> i <Link to={'/politika-privatnosti'}>Politiku privatnosti</Link>.
            </p>
          </div>
          <Button
            type="button"
            btnType="btn btn-outline-primary gdpr__button"
            clicked={this.confirmCookie}>Prihvatam</Button>
          <svg className="gdpr__triangle" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"
               height="500" width="500">
            <polygon points="0,0 0,500 500,500" fill="currentColor"></polygon>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cookie: state.cookie.cookie
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onConfirmCookie: () => dispatch(actions.cookieConfirm())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GDPR);