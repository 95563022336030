import React from 'react';

const courseDetailsCounter = (props) => {
  const items = Object.keys(props.data).map(key => {
    return (
      <div className="counter-item" key={key}>
        <div className="counter-item__number">{key}</div>
        <div className="counter-item__text">{props.data[key]}</div>
      </div>
    )
  });
  return (
    <div className="course-details__counter d-flex justify-content-md-around align-items-center">
      {items}
    </div>
  );
};

export default courseDetailsCounter;