import {updateObject} from "../../../shared/utility";

export const fetchBlogStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchBlogSuccess = (state, action) => {
  return updateObject(state, {
    blog: action.blog,
    error: null,
    loading: false
  });
};
export const fetchBlogFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};