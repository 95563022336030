import React, {useState, Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';

const input = (props) => {
    const [passwordShow, setPasswordShow] = useState(true);

    let iconClasses = ['icon']
    let inputElement = null;
    let inputClasses = [props.class];

    if ((props.invalid && props.shouldValidate && props.touched) || props.errorMessage) {
        inputClasses.push("error");
    }

    const passwordEyeHandler = event => {
        event.preventDefault();

        const newPasswordShow = passwordShow;
        setPasswordShow(!newPasswordShow);
    };

    switch(props.elementType) {
        case ('input') :
            let elementConfig = {...props.elementConfig};
            delete elementConfig.type;

            const eyeIcon = passwordShow ? 'icon-eye' : 'icon-eye-blocked';
            iconClasses.push(eyeIcon);

            inputElement = <Fragment>
                            <input
                                className={inputClasses.join(' ')}
                                {...elementConfig}
                                type={(props.elementConfig.type !== 'password') ? 'text' : (passwordShow ? 'password' : 'text')}
                                value={props.value}
                                onChange={props.changed}/>
                            { (props.elementConfig.type === "password") ? (
                              <button type="button" className="btn--password-eye" onClick={passwordEyeHandler}><i className={iconClasses.join(' ')}></i></button>
                            ) : null }
                            </Fragment>;

            break;
        case ('hidden') :
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                type={'hidden'}
                value={props.value}
                onChange={props.changed}/>;
            break;    
        case ('textarea') :
            inputElement = <textarea
                            className={inputClasses.join(' ')}
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}/>;
            break;
        case ('select') :
            const options = props.elementConfig.options.map((opt) => {
                return (<option key={opt.value} value={opt.value}>{opt.displayValue}</option>)
            });
            inputElement = (<select
                            className={inputClasses.join(' ')}
                            value={props.value}
                            onChange={props.changed}>
                                {options}
                            </select>);
            break;
        default :
            inputElement = <input
                            className={inputClasses.join(' ')}
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}/>;
    }

    return (
        <div className="form-group">
            { props.errorMessage ?
              ReactHtmlParser('<label class="error-message">' + props.errorMessage + '</label>')
              : null }
            {inputElement}
        </div>
    );
};

export default input;