import React from 'react';
import Swiper from 'react-id-swiper';
import {Autoplay} from 'swiper';
import partners_01_a1 from "../../../assets/images/partners/partners_01_a1.jpg";
import partners_02_avon from "../../../assets/images/partners/partners_02_avon.jpg";
import partners_03_infostud from "../../../assets/images/partners/partners_03_infostud.jpg";
import partners_04_nordeus from "../../../assets/images/partners/partners_04_nordeus.jpg";
import partners_05_jaffa from "../../../assets/images/partners/partners_05_jaffa.jpg";
import partners_06_laguna from "../../../assets/images/partners/partners_06_laguna.jpg";
import partners_07_delhaize from "../../../assets/images/partners/partners_07_delhaize.jpg";
import partners_08_barcino_tours from "../../../assets/images/partners/partners_08_barcino_tours.jpg";
import partners_09_ns_seme from "../../../assets/images/partners/partners_09_ns_seme.jpg";
import partners_10_nis from "../../../assets/images/partners/partners_10_nis.jpg";
import partners_11_brave_giant from "../../../assets/images/partners/partners_11_brave_giant.jpg";
import partners_12_4_zida from "../../../assets/images/partners/partners_12_4_zida.jpg";
import partners_13_httpool from "../../../assets/images/partners/partners_13_httpool.jpg";
import partners_14_msm from "../../../assets/images/partners/partners_14_msm.jpg";
import partners_15_apv from "../../../assets/images/partners/partners_15_apv.jpg";
import partners_cedis from "../../../assets/images/partners/partners_cedis.jpg";
import partners_clever_choice from "../../../assets/images/partners/partners_clever_choice.jpg";
import partners_cloud_media from "../../../assets/images/partners/partners_cloud_media.jpg";
import partners_craft from "../../../assets/images/partners/partners_craft.jpg";
import partners_crta from "../../../assets/images/partners/partners_crta.jpg";
import partners_css from "../../../assets/images/partners/partners_css.jpg";
import partners_dvi from "../../../assets/images/partners/partners_dvi.jpg";
import partners_evoke from "../../../assets/images/partners/partners_evoke.jpg";
import partners_flexis from "../../../assets/images/partners/partners_flexis.jpg";
import partners_galerija_podova from "../../../assets/images/partners/partners_galerija_podova.jpg";
import partners_glam_up from "../../../assets/images/partners/partners_glam_up.jpg";
import partners_golden_group from "../../../assets/images/partners/partners_golden_group.jpg";
import partners_interactive from "../../../assets/images/partners/partners_interactive.jpg";
import partners_kuca_cuvarkuca from "../../../assets/images/partners/partners_kuca_cuvarkuca.jpg";
import partners_media_plan from "../../../assets/images/partners/partners_media_plan.jpg";
import partners_mint_pharm from "../../../assets/images/partners/partners_mint_pharm.jpg";
import partners_mvp from "../../../assets/images/partners/partners_mvp.jpg";
import partners_nauci_me from "../../../assets/images/partners/partners_nauci_me.jpg";
import partners_nova_solutions from "../../../assets/images/partners/partners_nova_solutions.jpg";
import partners_novaston from "../../../assets/images/partners/partners_novaston.jpg";
import partners_ns_hotels from "../../../assets/images/partners/partners_ns_hotels.jpg";
import partners_nura from "../../../assets/images/partners/partners_nura.jpg";
import partners_omd from "../../../assets/images/partners/partners_omd.jpg";
import partners_ora from "../../../assets/images/partners/partners_ora.jpg";
import partners_prestige from "../../../assets/images/partners/partners_prestige.jpg";
import partners_prime from "../../../assets/images/partners/partners_prime.jpg";
import partners_radun_avia from "../../../assets/images/partners/partners_radun_avia.jpg";
import partners_rnids from "../../../assets/images/partners/partners_rnids.jpg";
import partners_sport_vision from "../../../assets/images/partners/partners_sport_vision.jpg";
import partners_studio_pixel from "../../../assets/images/partners/partners_studio_pixel.jpg";
import partners_swa from "../../../assets/images/partners/partners_swa.jpg";
import partners_to_loznica from "../../../assets/images/partners/partners_to_loznica.jpg";
import partners_vertigo from "../../../assets/images/partners/partners_vertigo.jpg";
import partners_vizija from "../../../assets/images/partners/partners_vizija.jpg";
import partners_zira from "../../../assets/images/partners/partners_zira.jpg";

const partnersSlider = () => {

    const partners = [
        {name: "A1", file: partners_01_a1},
        {name: "Avon", file: partners_02_avon},
        {name: "Infostud", file: partners_03_infostud},
        {name: "Nordeus", file: partners_04_nordeus},
        {name: "Jaffa", file: partners_05_jaffa},
        {name: "Laguna", file: partners_06_laguna},
        {name: "Delhaize", file: partners_07_delhaize},
        {name: "Barcino Tours", file: partners_08_barcino_tours},
        {name: "NS Seme", file: partners_09_ns_seme},
        {name: "Nis", file: partners_10_nis},
        {name: "Brave Giant", file: partners_11_brave_giant},
        {name: "4 Zida", file: partners_12_4_zida},
        {name: "HTTPool", file: partners_13_httpool},
        {name: "MSM", file: partners_14_msm},
        {name: "APV", file: partners_15_apv},
        {name: "cedis", file: partners_cedis},
        {name: "clever_choice", file: partners_clever_choice},
        {name: "cloud_media", file: partners_cloud_media},
        {name: "craft", file: partners_craft},
        {name: "crta", file: partners_crta},
        {name: "css", file: partners_css},
        {name: "dvi", file: partners_dvi},
        {name: "evoke", file: partners_evoke},
        {name: "flexis", file: partners_flexis},
        {name: "galerija_podova", file: partners_galerija_podova},
        {name: "glam_up", file: partners_glam_up},
        {name: "golden_group", file: partners_golden_group},
        {name: "interactive", file: partners_interactive},
        {name: "kuca_cuvarkuca", file: partners_kuca_cuvarkuca},
        {name: "media_plan", file: partners_media_plan},
        {name: "mint_pharm", file: partners_mint_pharm},
        {name: "mvp", file: partners_mvp},
        {name: "nauci_me", file: partners_nauci_me},
        {name: "nova_solutions", file: partners_nova_solutions},
        {name: "novaston", file: partners_novaston},
        {name: "ns_hotels", file: partners_ns_hotels},
        {name: "nura", file: partners_nura},
        {name: "omd", file: partners_omd},
        {name: "ora", file: partners_ora},
        {name: "prestige", file: partners_prestige},
        {name: "prime", file: partners_prime},
        {name: "radun_avia", file: partners_radun_avia},
        {name: "rnids", file: partners_rnids},
        {name: "sport_vision", file: partners_sport_vision},
        {name: "studio_pixel", file: partners_studio_pixel},
        {name: "swa", file: partners_swa},
        {name: "to_loznica", file: partners_to_loznica},
        {name: "vertigo", file: partners_vertigo},
        {name: "vizija", file: partners_vizija},
        {name: "zira", file: partners_zira}
    ];

    const params = {
        slidesPerView: 15,
        spaceBetween: 12,
        loop: true,
        slidesPerGroup: 5,
        autoplay: {
            delay: 3200,
            pauseOnMouseEnter: false
        },
        modules: [Autoplay],
        breakpoints: {
            1024: {
                slidesPerView: 15,
                spaceBetween: 12
            },
            768: {
                slidesPerView: 8,
                spaceBetween: 10
            },
            640: {
                slidesPerView: 6,
                spaceBetween: 10
            },
            320: {
                slidesPerView: 4,
                spaceBetween: 10
            }
        }
    }
    return (
        <section className="partners-slider">
            <div className="container">
                <h2 className="title">Kompanije koje su nam ukazale poverenje</h2>
                <Swiper {...params}>
                    {partners.map((item, index) => (
                        <img key={item.file} src={item.file} alt={item.name}/>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default partnersSlider;