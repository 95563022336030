import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchSinglePodcastStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_PODCAST_START
  };
};
export const fetchSinglePodcastSuccess = (singlePodcast) => {
  return {
    type: actionTypes.FETCH_SINGLE_PODCAST_SUCCESS,
    singlePodcast: singlePodcast
  }
};
export const fetchSinglePodcastFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_PODCAST_FAIL,
    error: error
  }
};
export const fetchSinglePodcast = (slug) => {
  return dispatch => {
    dispatch(fetchSinglePodcastStart());
    const headers = getAxiosHeader();
    axios.get('/podcasts/' + slug, headers)
      .then(res => {
        //console.log('fetchSinglePodcast', res.data.data);
        dispatch(fetchSinglePodcastSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchSinglePodcastFail(err));
      });
  }
};

export const singlePodcastUnmount = () => {
  return {
    type: actionTypes.SINGLE_PODCAST_UNMOUNT
  };
};