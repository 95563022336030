import * as actionTypes from '../../actions/actionTypes';
import { fetchTestStart, fetchTestSuccess, fetchTestFail, fetchTestUnmount} from './fetchTest';
import { submitTestStart, submitTestSuccess, submitTestFail} from './submitTest';
import { fetchTestInfoStart, fetchTestInfoSuccess, fetchTestInfoFail, fetchTestInfoUnmount} from './fetchTestInfo';
import { fetchTestAttemptsStart, fetchTestAttemptsSuccess, fetchTestAttemptsFail, fetchTestAttemptsUnmount} from './fetchTestAttempts';
import { postPaidSlotStart, postPaidSlotSuccess, postPaidSlotFail, fetchPaidSlotsStart, fetchPaidSlotsSuccess, fetchPaidSlotsFail, fetchPaidSlotsUnmount} from './paidSlots';

const initialState = {
  test: {
    id: null,
    enabled: false,
    passingScorePercent: null,
    title: null,
    numberOfFreeAttempts: null,
    duration: null,
    testModules: {},
    testAttempt: {}
  },
  testInfo: {
    enabled: false,
    payedSlotPrice: "0",
    numberOfFreeAttempts: 0,
    passingScorePercent: 0,
    courseName: null
  },
  testAttempts: {
    count: 0,
    hasInProgress: false,
    lastAttemptDate: null,
    passed: false,
    certificate: null
  },
  paidSlot: null,
  paidSlots: null,
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_TEST_START:
      return fetchTestStart(state, action);
    case actionTypes.FETCH_TEST_SUCCESS:
      return fetchTestSuccess(state, action);
    case actionTypes.FETCH_TEST_FAIL:
      return fetchTestFail(state, action);
    case actionTypes.FETCH_TEST_UNMOUNT:
      return fetchTestUnmount(state, action);

    case actionTypes.SUBMIT_TEST_START:
      return submitTestStart(state, action);
    case actionTypes.SUBMIT_TEST_SUCCESS:
      return submitTestSuccess(state, action);
    case actionTypes.SUBMIT_TEST_FAIL:
      return submitTestFail(state, action);

    case actionTypes.FETCH_TEST_INFO_START:
      return fetchTestInfoStart(state, action);
    case actionTypes.FETCH_TEST_INFO_SUCCESS:
      return fetchTestInfoSuccess(state, action);
    case actionTypes.FETCH_TEST_INFO_FAIL:
      return fetchTestInfoFail(state, action);
    case actionTypes.FETCH_TEST_INFO_UNMOUNT:
      return fetchTestInfoUnmount(state, action);

    case actionTypes.FETCH_TEST_ATTEMPTS_START:
      return fetchTestAttemptsStart(state, action);
    case actionTypes.FETCH_TEST_ATTEMPTS_SUCCESS:
      return fetchTestAttemptsSuccess(state, action);
    case actionTypes.FETCH_TEST_ATTEMPTS_FAIL:
      return fetchTestAttemptsFail(state, action);
    case actionTypes.FETCH_TEST_ATTEMPTS_UNMOUNT:
      return fetchTestAttemptsUnmount(state, action);


    case actionTypes.FETCH_PAID_SLOTS_START:
      return fetchPaidSlotsStart(state, action);
    case actionTypes.FETCH_PAID_SLOTS_SUCCESS:
      return fetchPaidSlotsSuccess(state, action);
    case actionTypes.FETCH_PAID_SLOTS_FAIL:
      return fetchPaidSlotsFail(state, action);
    case actionTypes.FETCH_PAID_SLOTS_UNMOUNT:
      return fetchPaidSlotsUnmount(state, action);
      
    case actionTypes.POST_PAID_SLOT_START:
      return postPaidSlotStart(state, action);
    case actionTypes.POST_PAID_SLOT_SUCCESS:
      return postPaidSlotSuccess(state, action);
    case actionTypes.POST_PAID_SLOT_FAIL:
      return postPaidSlotFail(state, action);

    default:
      return state;
  }
};

export default reducer;