import {updateObject} from "../../../shared/utility";

export const setLessonNotesStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};
export const setLessonNotesSuccess = (state, action) => {
  const updateMeta = action.lessonMeta;
  const updateLesson = updateObject(state.lesson, {meta: updateMeta});
  return updateObject(state, {
    lesson: updateLesson,
    error: null,
  });
};
export const setLessonNotesFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
};