import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchSingleCoursePackageStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_COURSE_PACKAGE_START
    };
};
export const fetchSingleCoursePackageSuccess = (singleCoursePackage) => {
    return {
        type: actionTypes.FETCH_SINGLE_COURSE_PACKAGE_SUCCESS,
        singleCoursePackage: singleCoursePackage
    }
};
export const fetchSingleCoursePackageFail = (error) => {
    return {
        type: actionTypes.FETCH_SINGLE_COURSE_PACKAGE_FAIL,
        error: error
    }
};
export const fetchSingleCoursePackage = (slug) => {
    return dispatch => {
        dispatch(fetchSingleCoursePackageStart());
        const headers = getAxiosHeader();
        axios.get('/packages/' + slug, headers)
            .then(res => {
                //console.log('action fetchSingleCoursePackageSuccess', res.data.data);
                dispatch(fetchSingleCoursePackageSuccess(res.data.data));
            })
            .catch(err => {
                //console.log(err);
                dispatch(fetchSingleCoursePackageFail(err));
            });
    }
};

export const singleCoursePackageUnmount = () => {
    return {
        type: actionTypes.FETCH_SINGLE_COURSE_PACKAGE_UNMOUNT
    };
};