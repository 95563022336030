import {updateObject} from "../../../shared/utility";

export const fetchTestStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchTestSuccess = (state, action) => {
  return updateObject(state, {
    test: action.test,
    error: null,
    loading: false
  });
};
export const fetchTestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};
export const fetchTestUnmount = (state, action) => {
  return updateObject(state, {
    test: {
      id: null,
      enabled: false,
      passingScorePercent: null,
      title: null,
      numberOfFreeAttempts: null,
      duration: null,
      testModules: {},
      testAttempt: {}
    },
    error: null,
    loading: false
  });
};