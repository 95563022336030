import React from 'react';
import {Link} from "react-router-dom";
import CourseBox from "../CourseBox/CourseBox";
import Spinner from "../UI/Spinner/Spinner";
import { differenceFromToday } from "../../../shared/utility";

const homeCoursesWidget = (props) => {
  let courses = <Spinner />;
  if (!props.loading) {
    courses = props.courses.map(course => {
      const secondsForCountdown = differenceFromToday(course.startDate);
      return (
        <div className="col-lg-4 align-self-md-stretch align-self-auto pb-3" key={course.id}>
          <CourseBox
            course={course}
            secondsForCountdown={secondsForCountdown} />
        </div>
      )
    });
    if (courses.length === 0) {
      courses = (
        <div className="col-md-6 col-xl-9 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Nema aktivnih kurseva</h3>
        </div>
      );
    }
  }

  return(
    <section className="courses courses--home">
      <div className="container">
        <h2 className="title">Dostupni kursevi</h2>
        <Link to="/kursevi" className="read-all">svi kursevi</Link>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row courses__list">
              {courses}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default homeCoursesWidget;