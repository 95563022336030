import React, {Component} from 'react';
import FooterLogo from "./FooterLogo/FooterLogo";
import BackToTop from "./BackToTop/BackToTop";
import {Link} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 footer__social d-flex align-items-center justify-content-around justify-content-md-center order-md-2">
              <a href="https://www.facebook.com/homepageakademija/" className="social__link" target="_blank"><i className="icon icon-social-facebook"></i></a>
              <a href="https://www.instagram.com/homepageacademy" className="social__link" target="_blank"><i className="icon icon-social-instagram"></i></a>
              <a href="https://www.youtube.com/channel/UCQTsdd9iDRCG692CHiFwc5g" className="social__link" target="_blank"><i className="icon icon-social-youtube"></i></a>
              <a href="https://www.linkedin.com/company/homepageakademija/" className="social__link" target="_blank"><i className="icon icon-social-linkedin"></i></a>
            </div>
            <div
              className="col-md-4 footer__links d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-end order-md-3">
              <Link to="/uslovi-koriscenja" className="footer__link">Opšti uslovi poslovanja</Link> <Link to="/politika-privatnosti" className="footer__link">Politika privatnosti</Link>
            </div>
            <FooterLogo/>
          </div>
          <BackToTop/>
        </div>
      </footer>
    );
  }
}


export default Footer;