import {updateObject} from "../../../shared/utility";

export const fetchTestimonialsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};
export const fetchTestimonialsSuccess = (state, action) => {
  return updateObject(state, {
    testimonials: action.testimonials,
    error: null,
    loading: false
  });
};
export const fetchTestimonialsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const fetchTestimonialsUnmount = (state, action) => {
  return updateObject(state, {
    testimonials: [],
    error: null,
    loading: false
  });
};