import React from 'react';
import {Link} from "react-router-dom";
import UserDropdown from "./UserDropdown/UserDropdown";
import LogoDark from "./Logo/LogoDark";
import Countdown from "../UI/Countdown/Countdown";
import { Nav } from "react-bootstrap";
import {differenceFromToday} from "../../../shared/utility";
import ReactHtmlParser from 'react-html-parser';

const headerDark = (props) => {

  const checkStartDate = differenceFromToday(props.singleCourse.startDate);
  const courseStarted = checkStartDate < 0;
  const courseStatus = courseStarted ?
    ReactHtmlParser('<i class="icon icon-status-active"></i><span>Aktivan</span>') :
    ReactHtmlParser('<i class="icon icon-status-panding"></i><span>U pripremi</span>');

  let courseRemainingTimeBlock = null;
  if (props.courseAccess) {
    const courseRemainingTime = differenceFromToday(props.courseAccess.endDate);
    if (props.courseAccess.enabled && courseStarted) {
      courseRemainingTimeBlock = (
        <div className="header-dark__course-timer d-none d-md-flex align-items-center">
          <Countdown seconds={courseRemainingTime} layout="header"/>
        </div>
      );
    }
  }

  return (
    <div className={props.classes}>
      <Nav className="js--navbar navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">

          <LogoDark/>

          <h1 className="header-dark__title mr-auto">
            <Link to={"/kurs/" + props.singleCourse.slug}>{props.singleCourse.name}</Link>
          </h1>

          <div className="header-dark__course-status active d-none d-md-flex align-items-center">
            {courseStatus}
          </div>

          {courseRemainingTimeBlock}

          <UserDropdown user={props.user} />
        </div>
      </Nav>
    </div>
  );
};

export default headerDark;