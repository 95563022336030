import React from 'react';
import LessonWatched from "./LessonWatched";
import LessonBookmark from "./LessonBookmark";
import LessonAttachment from "./LessonAttachment";

const lessonOptionsMobile = (props) => {
  
  const lessonWatched = () => {
    props.lessonWatched(slug)
  };  
  const favoriteHandler = () => {
    props.favoriteHandler(slug)
  };
  
  return (
    <div className="lesson-player__options--mobile fixed-bottom d-md-none">
      <div className="row">
        <div className="col-4">
          <LessonWatched
            checked={props.lessonWatchedChecked}
            lessonSlug={props.lessonSlug}
            watched={(slug) => lessonWatched(slug)}
          />
        </div>
        <div className="col-4">
            <LessonBookmark
              checked={props.lessonFavoriteChecked}
              lessonSlug={props.lessonSlug}
              favorite={(slug) => favoriteHandler(slug)}
            />
        </div>
        <div className="col-4">
          {(props.lessonAdditionalFiles) ? (
            <LessonAttachment
              file={props.lessonAdditionalFiles}
              token={props.token}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default lessonOptionsMobile;