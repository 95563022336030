import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import ReactHtmlParser from 'react-html-parser';
import PageHeader from '../layout/PageHeader/PageHeader';
import PodcastItem from '../layout/Podcasts/PodcastItem';
import Spinner from "../layout/UI/Spinner/Spinner";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import FbShareImage from "../../assets/images/homepageacademy-facebook-share-novosti.png";

const tagManagerArgs = {
  dataLayer: {
    page: 'Svi podkasti'
  },
  dataLayerName: 'PageDataLayer'
};

class Podcast extends Component {

  componentDidMount() {
    this.props.onFetchPodcasts();
    TagManager.dataLayer(tagManagerArgs);
    ReactGA.pageview('podkasti');
  }

  render() {
    let podcasts = <Spinner />;
    if (!this.props.loading) {
      const propsPodcasts = Object.keys(this.props.podcasts);
      podcasts = propsPodcasts.map(key => {
        return (
          <div className="col-md-4 col-xl-3" key={key}>
            {<PodcastItem podcast={this.props.podcasts[key]} />}
          </div>
        )
      });
      if (podcasts.length === 0) {
        podcasts = ReactHtmlParser('<p>Podkast sekcija je trenutno prazna.</p>');
      }
    }

    return (
      <Fragment> 
        {/* <Helmet>
          <title>Novosti - Homepage Academy</title>
          <meta name="description" content="Budi u toku" />
          <meta property="og:title" content="Saznaj više" />
          <meta property="og:description" content="Budi u toku" />
          <meta property="og:image" content={FbShareImage} />
        </Helmet>*/}
        
        <PageHeader>
          Svi Podkasti
        </PageHeader>
        
        <section className="podcast-list">
          <div className="container">
            <div className="row">
              { podcasts }
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.podcasts.loading,
    podcasts: state.podcasts.podcasts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPodcasts: () => dispatch(actions.fetchPodcasts())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Podcast);