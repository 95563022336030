import * as actionTypes from '../actionTypes';
import axios from "../../../axios";
import { getAxiosHeader } from "../../../shared/utility";

export const fetchSingleBlogStart = () => {
  return {
    type: actionTypes.FETCH_SINGLE_BLOG_START
  };
};
export const fetchSingleBlogSuccess = (singleBlog) => {
  return {
    type: actionTypes.FETCH_SINGLE_BLOG_SUCCESS,
    singleBlog: singleBlog
  }
};
export const fetchSingleBlogFail = (error) => {
  return {
    type: actionTypes.FETCH_SINGLE_BLOG_FAIL,
    error: error
  }
};
export const fetchSingleBlog = (slug) => {
  return dispatch => {
    dispatch(fetchSingleBlogStart());
    const headers = getAxiosHeader();
    axios.get('/blogs/' + slug, headers)
      .then(res => {
        //console.log('fetchSingleBlog', res.data.data);
        dispatch(fetchSingleBlogSuccess(res.data.data));
      })
      .catch(err => {
        //console.log(err);
        dispatch(fetchSingleBlogFail(err));
      });
  }
};

export const singleBlogUnmount = () => {
  return {
    type: actionTypes.SINGLE_BLOG_UNMOUNT
  };
};