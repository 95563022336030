import React from 'react';
import RadioButton from "../RadioButton/RadioButton";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

const PaymentType = (props) => {
    const paymentTypes = {
        check: {
            value: 'check',
            text: 'Uplatnica',
            id: 'checkboxPaymentTypeCheck'
        },
        card: {
            value: 'card',
            text: 'Kartica',
            id: 'checkboxPaymentTypeCard'
        }
    };
    const paymentTypeHandler = (e) => {
        props.changed(e.target.value);
    };

    // for (const [key, value] of Object.entries(paymentTypes)) {
    //     paymentForm += <div className={'col-12'}>
    //             <div className={'radio-wrapper radio-wrapper__payment form-group'}>
    //                 <RadioButton
    //                     value={value.value}
    //                     text={value.text}
    //                     id={value.id}
    //                     name={'checkboxPaymentType'}
    //                 />
    //             </div>
    //         </div>;
    // }
    
    return (
        <div className={'widget__payment-type w-100'}>
            <h4 className={'user-form__widget-title'}>Odaberite način plaćanja:</h4>
            <div className={'row'}>
                <div className={'col-9 col-lg-10'}>
                    <div className={'radio-wrapper radio-wrapper--payment form-group'}>
                        <RadioButton
                            value={'check'}
                            text={(props.client === 'legalEntity') ? 'Profaktura' : 'Uplatnica'}
                            id={props.client + 'checkboxInvoice'}
                            name={props.client + 'checkboxPaymentType'}
                            changed={paymentTypeHandler}
                            checked={props.checked === 'check'}
                        />
                    </div>
                </div>
                <div className={'col-3 col-lg-2'}>
                    <div className={'payment-type__info'}>
                        <InfoTooltip
                            message={(props.client === 'legalEntity') ? 'Odabirom ovog načina plaćanja generišete profakturu.' : 'Odabirom ovog načina plaćanja generišete uplatnicu.'}
                        />
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-9 col-lg-10'}>
                    <div className={'radio-wrapper radio-wrapper--payment form-group'}>
                        <RadioButton
                            value={'card'}
                            text={'Kartica'}
                            id={props.client + 'checkboxCard'}
                            name={props.client + 'checkboxPaymentType'}
                            changed={paymentTypeHandler}
                            checked={props.checked === 'card'}
                        />
                    </div>
                </div>
                <div className={'col-3 col-lg-2'}>
                    <div className={'payment-type__info'}>
                        <InfoTooltip
                            message={'Odabirom ovog načina plaćanja bićete u mogućnosti da platite kurs na do 12 rata, u zavisnosti od toga kojom karticom plaćate.'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentType;